/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unused-vars */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
// src/components/Sidebar.js
import {
  Menu,
  MenuItem,
  Radio,
  TextField,
  Tooltip,
  useMediaQuery,
} from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import jwt_decode from "jwt-decode";
import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  getAccountsList,
  getOranizationIcon,
  getUserRoleStatus,
  removeAccount,
  switchAccount,
} from "../../apis/organization";
import orgIcon from "../../assets/orgIcon.svg";
import logoicon from "../../assets/strived-logo-icon.svg";
import logoname from "../../assets/strived-logo-name.svg";
import { APP_URL, URL_POSTFIX } from "../../constants/constant";
import { darkTheme, lightTheme } from "../../theme";
import { getUserNames } from "../../utils";
import { checkAndRefreshToken, clearTokens } from "../../utils/authUtils";
import { getCookie, removeCookie, setCookie } from "../../utils/cookieHelper";
import Button from "../button";
import SioIcon from "../icons/IconLibrary";
import SubSidebar from "./subSidebar";
import { DataContext } from "../layout/layout";
import ZendeskButton from "../ZendeskButton";

const Sidebar = ({
  isSidebarOpen,
  recId,
  sendDataToParent,
  toggleSidebar,
  showSidebar,
  hideSidebar,
  sendUploadedFileData,
  deleteQuestions,
  selectedQuestions,
  homePageData,
  highlightField,
  isPromptResponseGenerated,
  setIsPromptResponseGenerated,
}) => {
  const navigate = useNavigate();
  const settings = [
    {
      name: "Admin Panel",
      icon: "admin",
    },
    {
      name: "Data Topics",
      icon: "savedDataTopics",
      link: "/saved-data-topics",
    },
    {
      name: "Profile Settings",
      icon: "profile",
      link: "/profile-settings",
    },
    {
      name: "Logout",
      icon: "logout",
    },
  ];
  const Mobilesettings = [...settings];

  const [anchorElUser, setAnchorElUser] = useState(null);
  const [organizations, setOrganizations] = useState([]);

  const [sidebarSubContent, setSidebarSubContent] = useState(highlightField);
  const orgName = getCookie("org");
  const [orgLogo, setOrgLogo] = useState(null);
  const [questionSavedStatus, setQuestionSavedStatus] = useState(null);
  const location = useLocation();
  const [searchText, setSearchText] = useState("");
  const [isUserSuperAdmin, setIsUserSuperAdmin] = useState(false);

  const {
    savedQuestionPrompt,
    selectedQuestionPrompt,
    setSelectedQuestionPrompt,
  } = useContext(DataContext);

  const fetchOrgIcon = async () => {
    try {
      const token = await checkAndRefreshToken();

      const getuserRoleStatusResp = await getUserRoleStatus(token);
      if (getuserRoleStatusResp?.status === 200) {
        setIsUserSuperAdmin(getuserRoleStatusResp?.data?.isSuperuser || false);
      } else {
        setIsUserSuperAdmin(false);
      }

      const orgIconResp = await getOranizationIcon(token);

      if (orgIconResp.status === 200) {
        setOrgLogo(orgIconResp?.data?.url);
      } else {
        setOrgLogo(null);
      }
    } catch (error) {
      console.error("Error fetching organization icon:", error);
      setOrgLogo(null);
    }
  };

  useEffect(() => {
    fetchOrgIcon();
  }, []);

  const [menuAnchor, setMenuAnchor] = useState(null);
  const [selectedOrg, setSelectedOrg] = React.useState(orgName);
  const [oldOrg, setOldOrg] = useState(orgName);
  const handleSelectOrg = (org) => {
    setOldOrg(selectedOrg);
    setSelectedOrg(org.org_name);
  };

  const handleMenuOpen = (event) => {
    setMenuAnchor(event.currentTarget);
  };

  const getSidebarsubcontent = (value) => {
    switch (value) {
      case "Ask Anything":
        setSidebarSubContent("Ask Anything");
        break;
      case "Suggestions":
        setSidebarSubContent("Suggestions");
        break;
      case "History":
        setSidebarSubContent("History");
        break;
      case "DataSource":
        setSidebarSubContent("DataSource");
        break;
      default:
        break;
    }
  };

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const checkTokenExist = async () => {
    const token = await checkAndRefreshToken();
    if (!token) navigate("/login");
  };

  const getOrganizationList = async () => {
    const token = await checkAndRefreshToken();
    const userAccounts = await getAccountsList(token);
    if (userAccounts.status === 200) {
      let account = userAccounts?.data?.accounts;
      setOrganizations(account);
    } else {
      setOrganizations([]);
    }
  };

  const handleMenuClose = () => {
    setMenuAnchor(null);
  };

  useEffect(() => {
    if (highlightField) {
      checkTokenExist();
      showSidebar();
      getSidebarsubcontent(highlightField);
    }
    getUserDetails();
    getOrganizationList();
    if (highlightField !== "Ask Anything") hideSidebar();
  }, [highlightField]);

  const [userName, setUserName] = useState("");

  const getUserDetails = async () => {
    const user = await getUserNames();
    setUserName(user.data.userdetails.user_data);
  };

  let adminPanelWindow = null;
  const handleAdminDashboard = async () => {
    const token = await checkAndRefreshToken();
    const refresh_Token = getCookie("refresh_token");

    if (token) {
      const newUrl = `strived.trypadlock.com?token=${token}&refresh_token=${refresh_Token}`;

      const existingAdminTabId = localStorage.getItem("adminTabId");
      if (existingAdminTabId) {
        const existingAdminTab = window.open("", existingAdminTabId);
        if (existingAdminTab && !existingAdminTab.closed) {
          existingAdminTab.location.href = `https://${newUrl}`;
          existingAdminTab.focus();
          return;
        }
      }

      const newAdminTab = window.open(`https://${newUrl}`, "_blank");
      if (newAdminTab) {
        const newTabId = `adminPanel_${Date.now()}`;
        newAdminTab.name = newTabId;
        localStorage.setItem("adminTabId", newTabId);
      }
    } else {
      navigate("/login");
    }
  };

  const handleOrganizationSwitch = async (org) => {
    const token = await checkAndRefreshToken();

    if (org) {
      const switchPayload = {
        id: org?.id,
        device_uuid: org?.device_uuid,
      };
      const swithcresp = await switchAccount(switchPayload, token);
      if (swithcresp.status === 200) {
        clearTokens();
        handleMenuClose();
        const existingAdminTabId = localStorage.getItem("adminTabId");
        if (existingAdminTabId) {
          const existingAdminTab = window.open("", existingAdminTabId);
          if (existingAdminTab && !existingAdminTab.closed) {
            existingAdminTab.close();
          }
          localStorage.removeItem("adminTabId");
        }

        const decodedToken = jwt_decode(swithcresp?.data?.token);
        if (decodedToken !== undefined) {
          const user = decodedToken.data;
          let userDetails = {
            org_uuid: user.org_uuid,
            user_uuid: user.user.user_uuid,
            email: user.user.email,
          };

          setCookie("device_id", org?.id);

          setCookie("token", swithcresp?.data?.token);

          setCookie("refresh_token", swithcresp?.data?.refresh_token);

          setCookie("user_email", user.user.email);

          setCookie("org", org.org_name);

          setSelectedOrg(org.org_name);

          setCookie("user", JSON.stringify(userDetails));

          setCookie("userName", user?.userdetails?.user_data?.first_name);

          setCookie("org_uuid", userDetails?.org_uuid);

          const userRole = user?.roles ? user?.roles : user?.userdetails?.roles;
          setCookie("user_role", userRole);

          if (URL_POSTFIX === "localhost") {
            navigate("/");
            window.location.reload();
          } else {
            window.location.href = `https://${org.org_name}${URL_POSTFIX}`;
          }
        }
      } else if (swithcresp.status === 404) {
        toast.error(swithcresp?.data?.message, {
          icon: <SioIcon name="error" size={16} color="red" />,
        });
        toast.error("Removing the org from the list", {
          icon: <SioIcon name="error" size={16} color="red" />,
        });
        setSelectedOrg(oldOrg);
        handleMenuClose();
        getOrganizationList();
      } else {
        handleMenuClose();
        toast.error(swithcresp?.data?.message, {
          icon: <SioIcon name="error" size={16} color="red" />,
        });
      }
    }
  };

  const handleClearCookies = () => {
    removeCookie("token");
    removeCookie("refresh_token");
    removeCookie("user_email");
    removeCookie("org");
    removeCookie("org_uuid");
    removeCookie("user");
    removeCookie("userName");
    removeCookie("user_role");
    sessionStorage.removeItem("s3_keys");
  };

  const handleLogout = async () => {
    const token = await checkAndRefreshToken();
    const payloadId = getCookie("device_id");
    const device_uuid = getCookie("device_uuid");
    const payload = {
      id: payloadId,
      device_uuid: device_uuid,
    };
    toast.success(`you've been logged out of ${orgName}`, {
      icon: <SioIcon name="check" size={16} color="blue" />,
    });

    const logoutResp = await removeAccount(payload, token);
    if (logoutResp.status === 200) {
      clearTokens();
      handleClearCookies();
      if (logoutResp?.data?.isLogout) {
        removeCookie("device_id");
        removeCookie("device_uuid");
        if (URL_POSTFIX === "localhost") {
          navigate("/login");
        } else {
          window.location.href = `https://${APP_URL}/login`;
        }
        return;
      } else {
        const decodedToken = jwt_decode(logoutResp?.data?.token);
        if (decodedToken !== undefined) {
          const user = decodedToken.data;
          let userDetails = {
            org_uuid: user.org_uuid,
            user_uuid: user.user.user_uuid,
            email: user.user.email,
          };

          setCookie("device_id", logoutResp?.data?.id);

          setCookie("token", logoutResp?.data?.token);

          setCookie("refresh_token", logoutResp?.data?.refresh_token);

          setCookie("user_email", user.user.email);

          setCookie("org", user.org_name);

          setCookie("user", JSON.stringify(userDetails));

          setCookie("userName", user?.userdetails?.user_data?.first_name);

          setCookie("org_uuid", user?.org_uuid);
          const userRole = user?.roles ? user?.roles : user?.userdetails?.roles;

          setCookie("user_role", userRole);
          if (URL_POSTFIX === "localhost") {
            navigate("/");
            window.location.reload();
          } else {
            window.location.href = `https://${user.org_name}${URL_POSTFIX}`;
          }
        }
      }
    } else {
      clearTokens();
      handleClearCookies();
      removeCookie("device_id");
      removeCookie("device_uuid");
      if (URL_POSTFIX === "localhost") {
        navigate("/login");
      } else {
        window.location.href = `https://${APP_URL}/login`;
      }
    }
  };
  const selectedTopics = JSON.parse(sessionStorage.getItem("Topics")) || [];
  const selectSubTopics = JSON.parse(sessionStorage.getItem("subTopics")) || [];

  const isMobileView = useMediaQuery("(min-width:767px)");

  return (
    <ThemeProvider theme={darkTheme}>
      <div
        className={`h-full bg-dark sio-sidebar-contener ${
          isSidebarOpen ? "sio-sidebar-open" : ""
        }`}
      >
        <div className="fixed w-full flex md:hidden items-center pt-1 px-2">
          <Link to="/" className="flex items-center">
            <img
              className={`hover:cursor-pointer h-6`}
              src={logoicon}
              alt="strived.io-logo"
              loading="lazy"
            />
            <img
              className="h-4 ml-2"
              src={logoname}
              alt="strived.io-logo"
              loading="lazy"
            />
          </Link>
          <Link
            className={`sio-avatar ml-auto me-2 justify-center sio-avatar-dark no-underline size-8 md:size-10 decoration-transparent ${
              Boolean(menuAnchor) ? "sio-avatar-active" : undefined
            }`}
            onClick={handleMenuOpen}
            aria-controls={Boolean(menuAnchor) ? "basic-menu" : undefined}
          >
            <img src={orgIcon} alt="Org" className="size-5" />
          </Link>
          {userName?.first_name && (
            <Link
              className="sio-avatar flex md:hidden sio-avatar-dark no-underline size-8 md:size-10 decoration-transparent"
              onClick={handleOpenUserMenu}
            >
              {userName?.first_name[0] + userName?.last_name[0]}
            </Link>
          )}
        </div>

        <div
          className={`sio-vertical-navbar transition-all ${
            isSidebarOpen ? "sio-sidebar-open" : ""
          }`}
        >
          <div className="bg-gradient-to-t from-dark rounded-2xl">
            <div className="relative">
              <Link to="/" className="sio-brand-icon-logo flex-col">
                <img
                  className={`hover:cursor-pointer mb-2`}
                  src={logoicon}
                  alt="strived.io-logo"
                  loading="lazy"
                />
                <img
                  className={`${
                    isSidebarOpen
                      ? "-mt-3 relative transition-all opacity-0"
                      : "mt-0 relative transition-all"
                  }`}
                  src={logoname}
                  alt="strived.io-logo"
                  loading="lazy"
                />
              </Link>
              <div className="flex justify-center sio-sidebar-toggle">
                {sidebarSubContent === "Ask Anything" && (
                  <Link
                    onClick={() => {
                      toggleSidebar();
                      // ("Toggle sidebar");
                      // navigate(``, {
                      //   replace: true,
                      //   state: {
                      //     ...location.state,
                      //     status: questionSavedStatus,
                      //     message_id: savedQuestionPrompt,
                      //   },
                      // });
                    }}
                    className={`p-1 text-2xl text-muted-light rounded-full hover:cursor-pointer ${
                      isSidebarOpen ? "hidden" : ""
                    }`}
                  >
                    <SioIcon name="rightArrowCircle" size="20" />
                  </Link>
                )}
              </div>
            </div>
            <div className="hidden md:flex justify-center py-5 border-t border-light">
              {/* <Link
                className="sio-avatar justify-center hidden md:flex sio-avatar-dark no-underline size-8 md:size-10 decoration-transparent"
                onClick={handleMenuOpen}
              >
                <img src={orgIcon} alt="Org" />
              </Link> */}
              <div className="flex flex-col justify-center items-center gap-2 w-full">
                <div
                  className={`cursor-pointer sio-avatar justify-center sio-avatar-dark no-underline size-8 md:size-10 decoration-transparent ${
                    Boolean(menuAnchor) ? "sio-avatar-active" : undefined
                  }`}
                  onClick={handleMenuOpen}
                  aria-controls={Boolean(menuAnchor) ? "basic-menu" : undefined}
                >
                  <img src={orgLogo ? orgLogo : orgIcon} alt="Org" />
                </div>
                <Tooltip title={getCookie("org")}>
                  <div className="w-full max-w-[80px] md:max-w-[72px] opacity-55 text-primary-light text-[10px] font-semibold truncate text-center">
                    {getCookie("org")}
                  </div>
                </Tooltip>
              </div>

              <ThemeProvider theme={lightTheme}>
                <Menu
                  id="menu-appbar"
                  anchorEl={menuAnchor}
                  anchorOrigin={
                    isMobileView
                      ? { vertical: "top", horizontal: "right" }
                      : { vertical: "bottom", horizontal: "left" }
                  }
                  MenuListProps={{
                    className: "sio-menu-check-list",
                  }}
                  keepMounted
                  open={Boolean(menuAnchor)}
                  onClose={handleMenuClose}
                  sx={isMobileView ? { ml: 6 } : { ml: 4, mt: 1 }}
                  className="sio-org-menu"
                >
                  <div className="sio-menu-header">Switch Organization</div>

                  {organizations.length > 4 && isUserSuperAdmin && (
                    <div className="px-3 my-2">
                      <TextField
                        placeholder="Search Organisation..."
                        fullWidth
                        autoFocus
                        onChange={(e) => setSearchText(e.target.value)}
                        onKeyDown={(e) => e.stopPropagation()}
                        // style={{ height: "10px" }}
                        InputProps={{
                          sx: {
                            height: "45px",
                            fontSize: "14px",
                            padding: "8px",
                          },
                        }}
                      />
                    </div>
                  )}

                  {organizations
                    .filter((org) =>
                      org.org_name
                        .toLowerCase()
                        .includes(searchText.toLowerCase())
                    )
                    .map((org, ind) => (
                      <MenuItem
                        key={ind}
                        autoFocus={false}
                        className={`sio-org-menu-item ${
                          selectedOrg === org.org_name ? "sio-org-selected" : ""
                        }`}
                        onClick={() => {
                          handleSelectOrg(org);
                          handleOrganizationSwitch(org);
                          sessionStorage.setItem(
                            "updatedOnboardingData",
                            JSON.stringify(true)
                          );
                          sessionStorage.setItem(
                            "filesData",
                            JSON.stringify(true)
                          );
                          sessionStorage.setItem(
                            "isOrgSwitch",
                            JSON.stringify(true)
                          );
                          sessionStorage.setItem(
                            "subTopics",
                            JSON.stringify([])
                          );
                          sessionStorage.setItem(
                            "selectedQuestionPrompt",
                            JSON.stringify(null)
                          );
                          sessionStorage.setItem("s3_keys", JSON.stringify([]));
                        }}
                      >
                        <span className="sio-avatar shrink-0 text-xs text-muted-dark -my-1 justify-center bg-dark/10 size-8">
                          {org?.org_name[0] ? org.org_name[0] : "SR"}
                        </span>
                        <h6 className="sio-menu-item-title !leading-tight mx-3">
                          {org.org_name}
                        </h6>
                        <Radio
                          checked={selectedOrg === org.org_name}
                          className="!mr-0 !ml-auto !mb-0 !mt-[3px] self-start"
                        />
                      </MenuItem>
                    ))}

                  <div className="sio-select-footer-action -mb-2 sticky bottom-0">
                    <Button
                      variant="secondary"
                      onClick={() => {
                        if (URL_POSTFIX === "localhost") {
                          navigate("/login");
                        } else {
                          window.location.href = `https://${APP_URL}/login`;
                        }
                        sessionStorage.setItem(
                          "isOrgSwitch",
                          JSON.stringify(true)
                        );
                      }}
                      className="sio-btn-icon w-full uppercase rounded-t-none font-semibold text-xs"
                    >
                      <SioIcon name="add" size={16} /> Add Organization
                    </Button>
                  </div>
                </Menu>
              </ThemeProvider>
            </div>
          </div>

          <ul className="sio-vertical-nav sio-nav-icon">
            <li className="sio-nav-item">
              <Link
                className={`sio-nav-link  ${
                  sidebarSubContent === "Ask Anything" ? "active" : ""
                }`}
                onClick={() => {
                  getSidebarsubcontent("Ask Anything");
                  showSidebar();
                  setSelectedQuestionPrompt(null);
                  sessionStorage.setItem(
                    "selectedQuestionPrompt",
                    JSON.stringify(null)
                  );
                }}
                to="/"
              >
                <i className="sio-nav-link-icon">
                  <SioIcon name="askAnything" size={40} />
                </i>
                Ask Anything
              </Link>
            </li>
            <li className="sio-nav-item">
              <Link
                to="/suggestions"
                className={`sio-nav-link ${
                  sidebarSubContent === "Suggestions" ? "active" : ""
                }`}
                onClick={() => {
                  hideSidebar();
                  getSidebarsubcontent("Suggestions");
                  setSelectedQuestionPrompt(null);
                  sessionStorage.setItem(
                    "selectedQuestionPrompt",
                    JSON.stringify(null)
                  );
                }}
              >
                <i className="sio-nav-link-icon">
                  <SioIcon name="suggestion" size={24} />
                </i>
                Suggestions
              </Link>
            </li>
            <li className="sio-nav-item">
              <Link
                to="/savedQuestions"
                className={`sio-nav-link ${
                  sidebarSubContent === "History" ? "active" : ""
                }`}
                onClick={() => {
                  hideSidebar();
                  getSidebarsubcontent("History");
                  setSelectedQuestionPrompt(null);
                  sessionStorage.setItem(
                    "selectedQuestionPrompt",
                    JSON.stringify(null)
                  );
                }}
              >
                <i className="sio-nav-link-icon">
                  <SioIcon name="save" size={40} />
                </i>
                Saved Questions
              </Link>
            </li>
            <li className="sio-nav-item">
              <Link
                to="/data-source"
                className={`sio-nav-link ${
                  sidebarSubContent === "DataSource" ? "active" : ""
                }`}
                onClick={() => {
                  hideSidebar();
                  getSidebarsubcontent("DataSource");
                  setSelectedQuestionPrompt(null);
                  sessionStorage.setItem(
                    "selectedQuestionPrompt",
                    JSON.stringify(null)
                  );
                }}
              >
                <i className="sio-nav-link-icon">
                  <SioIcon name="dataSource" size={40} />
                </i>
                Data Source
              </Link>
            </li>
            {/* <li className="sio-nav-item">
              <Link className="sio-nav-link"></Link>
            </li> */}
          </ul>
          <ZendeskButton label="Start Chat" />
          <div className="flex justify-center md:mb-4">
            {userName?.first_name && (
              <div
                className="cursor-pointer sio-avatar justify-center hidden md:flex sio-avatar-dark no-underline size-8 md:size-10 decoration-transparent"
                onClick={handleOpenUserMenu}
              >
                {userName?.first_name[0] + userName?.last_name[0]}
              </div>
            )}
            <ThemeProvider theme={lightTheme}>
              <Menu
                sx={isMobileView ? { ml: 5.5 } : { mt: 3, ml: 4 }}
                id="menu-appbar"
                className=""
                anchorEl={anchorElUser}
                anchorOrigin={
                  isMobileView
                    ? { vertical: "bottom", horizontal: "left" }
                    : { vertical: "bottom", horizontal: "left" }
                }
                keepMounted
                // transformOrigin={{
                //   vertical: "bottom",
                //   horizontal: "right",
                // }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                {Mobilesettings.map((setting) => (
                  <MenuItem
                    component={Link}
                    to={setting.link}
                    key={setting.name}
                    onClick={() => {
                      handleCloseUserMenu();
                      if (setting.name === "Admin Panel") {
                        handleAdminDashboard();
                      }
                      if (setting.name === "Logout") {
                        handleLogout();
                      }
                      hideSidebar();
                    }}
                  >
                    <i className="soi-icon">
                      <SioIcon name={setting.icon} size={20} />
                    </i>
                    {setting.name}
                  </MenuItem>
                ))}
              </Menu>
            </ThemeProvider>
          </div>
        </div>
        <SubSidebar
          isSidebarOpen={isSidebarOpen}
          toggleSidebar={toggleSidebar}
          sidebarSubContent={sidebarSubContent}
          sendDataToParent={sendDataToParent}
          sendUploadedFileData={sendUploadedFileData}
          selectedQuestions={selectedQuestions}
          deleteQuestions={deleteQuestions}
          homePageData={homePageData}
          recId={recId}
          // questionSavedStatus={questionSavedStatus}
          setQuestionSavedStatus={setQuestionSavedStatus}
        />
      </div>
    </ThemeProvider>
  );
};

export default Sidebar;
