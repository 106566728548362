import { CircularProgress, Tooltip } from "@mui/material";
import React, { useRef } from "react";
import logoicon from "../../../assets/logo-black.png";
import Button from "../../button";
import SioIcon from "../../icons/IconLibrary";
import { generatePDF } from "../pdfUtils";
import MarkdownPdfComponent from "../ui/MarkdownPdfComponent";

const PDFGenerator = ({
  markDownContainerData,
  currentFileName,
  isRenderingDownLoadButton,
  setIsRenderingDownLoadButton,
  isFromAskMe,
}) => {
  const pdfRef = useRef(null);

  return (
    <div id="my-id">
      <div
        ref={pdfRef}
        style={{
          display: !isRenderingDownLoadButton ? "none" : "block",
          position: "absolute",
          top: "-9999px",
          left: "-9999px",
          width: "794px",
          // padding: "40px",
          backgroundColor: "#ffffff",
          fontFamily: "'Roboto', Arial, sans-serif",
          fontSize: "14px",
          lineHeight: "1.8",
          letterSpacing: "0.9px",
          wordSpacing: "0.5px",
          color: "#000000",
        }}
      >
        <div className="flex items-center justify-center">
          <img className="h-12 mb-10" src={logoicon} alt="strived.io-logo" />
        </div>

        {isFromAskMe && (
          <div className="w-full flex justify-end mb-1">
            <div className="relative inline-block max-w-[60%] p-4 bg-[rgb(202,243,231)] rounded-lg hover:bg-[rgb(182,233,221)]">
              <p className="text-sm text-[rgba(17,17,17,0.74)]">
                {currentFileName}
              </p>
            </div>
          </div>
        )}

        <MarkdownPdfComponent
          markDownContainerData={markDownContainerData}
          filename={currentFileName}
        />
      </div>

      {!isRenderingDownLoadButton ? (
        <Tooltip title="Download Response">
          <Button
            disabled={isRenderingDownLoadButton}
            fab
            outline
            variant="dark"
            className={` ${isFromAskMe ? "size-10" : ""}`}
            onClick={() => {
              generatePDF(
                pdfRef,
                currentFileName,
                setIsRenderingDownLoadButton
              );
            }}
          >
            <SioIcon name="download" size={16} />
          </Button>
        </Tooltip>
      ) : (
        <CircularProgress size="32px" color="success" />
      )}
    </div>
  );
};

export default PDFGenerator;
