/* eslint-disable react-hooks/exhaustive-deps */
import { CircularProgress } from "@mui/material";
import { MathJax, MathJaxContext } from "better-react-mathjax";
import React, { memo, useContext, useEffect, useState } from "react";
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";
import icon from "../../../../src/assets/response_icon.png";
import { bookmarkQuestions } from "../../../apis/recommendation/recommendation";
import Charts from "../../Charts";
import SioIcon from "../../icons/IconLibrary";
import { DataContext } from "../../layout/layout";
import PdfTableContainer from "../PdfTableContainer";

const MarkdownPdfComponent = ({ markDownContainerData, filename }) => {
  const [savedStatusList, setSavedStatusList] = useState([]);
  const { setPromptData } = useContext(DataContext);

  const Dataloading = () => {
    return (
      <div
        className={`flex shrink grow basis-0 w-[1%] items-center justify-center py-4`}
      >
        <svg width={0} height={0}>
          <defs>
            <linearGradient id="my_gradient" x1="0%" y1="0%" x2="0%" y2="100%">
              <stop offset="0%" stopColor="#e01cd5" />
              <stop offset="100%" stopColor="#1CB5E0" />
            </linearGradient>
          </defs>
        </svg>
        <CircularProgress
          size="32px"
          sx={{ "svg circle": { stroke: "url(#my_gradient)" } }}
        />
      </div>
    );
  };

  useEffect(() => {
    markDownContainerData &&
      markDownContainerData.forEach(async (rec) => {
        if (rec?.input?.isSaved || rec?.input?.id) {
          setSavedStatusList((prevData) => [
            ...prevData,
            rec?.saved_status && rec?.id,
          ]);
          if (rec?.input?.isSaved) await bookmarkQuestions(rec?.input?.id);
        }
      });
  }, [markDownContainerData]);

  const getChartValues = (jsonString) => {
    const chartData = jsonString;

    return <Charts chartData={chartData} />;
  };

  const chartStatistics = (children, item) => {
    let formatedResponse = (data) => {
      if (typeof data === "string" && data.includes("\\")) {
        try {
          return <MathJax inline={false}>{`\\[${data}\\]`}</MathJax>;
        } catch (error) {
          console.error("MathJax rendering error:", error);
          return data;
        }
      } else {
        return item?.input ? data : data;
      }
    };
    const jsonRegex = /```json\s*({[\s\S]*?})\s*```/g;
    ///```json\s*([\s\S]*?)\s*```/g
    let chartDataArray = [];

    if (item?.response) {
      let match;
      while ((match = jsonRegex.exec(item.response)) !== null) {
        try {
          const parsedData = JSON.parse(match[1]);
          chartDataArray.push(parsedData);
        } catch (error) {
          console.error("Invalid JSON:", match[1], error);
        }
      }
    }

    return Array.isArray(children) ? (
      children.map((value) =>
        value?.type === "img" || value.type === "a" ? (
          chartDataArray.length > 0 &&
          value.props.href.includes("placeholder") ? (
            chartDataArray.map((chartData, ind) => (
              <div key={ind} className="sio-card-border !mt-3">
                {getChartValues(chartData)}
              </div>
            ))
          ) : (
            <div className="flex flex-col gap-3">
              To improve performance, only a limited number of records are
              shown.
              <span>
                <button
                  size="xs"
                  className="text-link-secondary font-semibold underline"
                  onClick={async () => {
                    const link = document.createElement("a");
                    link.href = value?.props?.href;

                    link.click();
                  }}
                >
                  {"\n"}
                  Please download to view the full data
                </button>
              </span>
            </div>
          )
        ) : !value?.props &&
          value.includes("Chart Data") ? null : typeof value === "string" &&
          value
            .toLowerCase()
            .includes("[Download Data Breakdown]".toLowerCase()) ? (
          value.replace(/\[download data breakdown\]/gi, "")
        ) : (
          value
        )
      )
    ) : children?.type === "img" || children.type === "a" ? (
      chartDataArray.length > 0 &&
      children.props.href.includes("placeholder") ? (
        chartDataArray.map((chartData, ind) => (
          <div key={ind} className="sio-card-border !mt-3">
            {getChartValues(chartData)}
          </div>
        ))
      ) : (
        <div className="flex flex-col gap-3">
          To improve performance, only a limited number of records are shown.
          <span>
            <button
              size="xs"
              className="text-link-secondary font-semibold underline"
              onClick={async () => {
                const link = document.createElement("a");
                link.href = children?.props?.href;

                link.click();
              }}
            >
              {"\n"}
              Please download to view the full data
            </button>
          </span>
        </div>
      )
    ) : !children?.props && children.includes("Chart Data") ? null : (
      formatedResponse(children)
    );
  };

  const mathJaxConfig = {
    loader: { load: ["input/tex", "output/chtml"] },
  };

  const Format = (markDownData) => {
    return (
      markDownData &&
      markDownData.length > 0 &&
      markDownData?.map((item, ind) => (
        <div
          id={item?.input ? `inp${ind}` : `res${ind}`}
          className={`question-container flex ${
            item?.input ? "items-end justify-end sio-salf" : "items-start"
          } relative`}
          key={ind}
        >
          {item?.LoadingData && <Dataloading />}
          {item?.response && (
            <img
              src={icon}
              alt="response_strived_icon"
              className={`md:p-2 md:py-3 bg-black rounded-full me-3 md:me-4 size-7 
                flex-shrink-0 md:size-10 ${ind === 0 && "invisible"}`}
            />
          )}
          <div className={`flex flex-col self-center overflow-hidden`}>
            <MathJaxContext config={mathJaxConfig} onLoad={() => null}>
              <Markdown
                remarkPlugins={[remarkGfm]}
                className={`prose prose-sm sio-editor-content lg:prose-lg xl:prose-xl max-w-none
                      sm:text-[13px] md:text-[13px] 2xl:text-[16px] 3xl:text-[19px] flex-col`}
                components={{
                  ul: ({ node, children, ...props }) => (
                    <ul className="sio-list-disc sio-list-secondary" {...props}>
                      {children}
                    </ul>
                  ),
                  li: ({ node, children, ...props }) => (
                    <li {...props}>{chartStatistics(children, item)}</li>
                  ),
                  table: ({ node, children, ...props }) => (
                    <div>
                      <PdfTableContainer>{children}</PdfTableContainer>
                    </div>
                  ),
                  p: ({ node, children, ...props }) => (
                    <p
                      {...props}
                      style={{
                        whiteSpace: "pre-wrap",
                      }}
                    >
                      {Array.isArray(children) && children
                        ? children.map((child) =>
                            child?.props && child?.props?.children && null
                              ? child?.props?.children.includes("Chart Data") &&
                                null
                              : child?.children &&
                                child?.children.includes("Chart Data") &&
                                null
                          )
                        : children?.children &&
                          children?.children.includes("Chart Data") &&
                          null}
                      {chartStatistics(children, item)}
                      {item?.input && (
                        <button
                          className="ms-2 relative"
                          onClick={async () => {
                            const { saved_status } = await bookmarkQuestions(
                              item?.input?.id
                            );
                            if (!item?.input?.id) return;
                            saved_status
                              ? setSavedStatusList((prevList) => [
                                  ...new Set([...prevList, item?.input?.id]),
                                ])
                              : setSavedStatusList((prevList) => {
                                  return [
                                    ...new Set(
                                      prevList.filter(
                                        (id) => id !== item?.input?.id
                                      )
                                    ),
                                  ];
                                });
                            setPromptData([saved_status]);
                          }}
                        >
                          {item?.input?.isSaved ||
                          savedStatusList.includes(item?.input?.id) ? (
                            <SioIcon name="saved" size={20} />
                          ) : (
                            <SioIcon name="save" size={20} />
                          )}
                        </button>
                      )}
                    </p>
                  ),
                  code: ({ node, children, ...props }) => null,
                }}
              >
                {item?.response ||
                  (item?.input?.content &&
                    item?.input?.content?.replace(/\n\n/g, "\n"))}
              </Markdown>
            </MathJaxContext>
          </div>
        </div>
      ))
    );
  };

  return Format(markDownContainerData);
};

export default memo(MarkdownPdfComponent);
