import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { URL_POSTFIX } from "../constants/constant";
import { getCookie } from "../utils/cookieHelper";

const OrgValidator = ({ children }) => {
  const location = useLocation();
  const hostname = window.location.hostname;
  const isLocal =
    hostname.includes("localhost") || hostname.includes("127.0.0.1");
  useEffect(() => {
    if (isLocal) return;

    const orgName = getCookie("org")?.toLowerCase();
    if (!orgName) return;

    const expectedHost = `${orgName}${URL_POSTFIX}`;
    if (hostname !== expectedHost) {
      const newUrl = `https://${expectedHost}${location.pathname}${location.search}`;
      window.location.replace(newUrl);
    }
    // eslint-disable-next-line
  }, [location]);

  return <>{children}</>;
};

export default OrgValidator;
