/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from "react";
import signupLogo from "../../assets/logo.svg";
import jwt_decode from "jwt-decode";
import { Link, useNavigate } from "react-router-dom";
import {
  FormControl,
  InputLabel,
  TextField,
  Select,
  MenuItem,
  InputAdornment,
  IconButton,
  OutlinedInput,
  Tooltip,
  Box,
  Collapse,
  FormHelperText,
} from "@mui/material";
import Button from "../../components/button";
import {
  getOrganizationList,
  loginUser,
  responseGoogle,
  googleVerification,
  getUserRoleFlag,
} from "../../apis/login/login";
import { addAccount } from "../../apis/organization/index";
import { clearTokens } from "../../utils/authUtils";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { GoogleLogin } from "@react-oauth/google";
import { ThemeProvider, useTheme } from "@mui/material/styles";
import { lightTheme, darkTheme } from "../../theme";
import Otp from "../otp/otp";
import { URL_POSTFIX } from "../../constants/constant";
import SioIcon from "../../components/icons/IconLibrary";
import { setCookie, getCookie } from "../../utils/cookieHelper";
import { getOnboardingStatus } from "../../apis/onboarding/index";

const Login = (props) => {
  const [email, setEmail] = useState("");
  const navigate = useNavigate();
  const [emailError, setEmailError] = useState("");
  const [step, setStep] = useState(1);
  const [orgList, setOrgList] = useState([]);
  const [selectedOrgUuid, setSelectedOrgUuid] = useState("");
  const [orgUuidList, setOrganisationUuidList] = useState([]);
  const [selectedOrg, setSelectedOrg] = useState("");
  const [isGoogleLogin, setIsGoogleLogin] = useState(false);
  const [googleLoginToken, setGoogleLoginToken] = useState("");

  const [searchText, setSearchText] = useState("");
  const inputRef = useRef(null);
  const [menuOpen, setMenuOpen] = useState(false);
  const [isUserSuperAdmin, setIsUserSuperAdmin] = useState(false);

  // Filter the organization list based on search input
  const filteredOrgs = orgList.filter((org) =>
    org.toLowerCase().includes(searchText.toLowerCase())
  );

  const getUserRoleDetails = async () => {
    const getuserRoleStatusResp = await getUserRoleFlag(email);
    if (getuserRoleStatusResp?.status === 200) {
      setIsUserSuperAdmin(getuserRoleStatusResp?.data?.isSuperuser || false);
    } else {
      setIsUserSuperAdmin(false);
    }
  };

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const [showHelper, setShowHelper] = useState(false);
  const handleEmailContinue = async () => {
    if (email) {
      if (!emailRegex.test(email)) {
        toast.error("Please enter a valid email address", {
          theme: "light",
          icon: <SioIcon name="close" size={16} color="blue" />,
        });
        setEmailError("Please enter a valid email address");
        return;
      }
      setEmailError("");
      try {
        await getUserRoleDetails();
        const getOrganizationListResp = await getOrganizationList(email);
        if (getOrganizationListResp?.status === 200) {
          let organization_list = getOrganizationListResp?.data?.list;
          const orgDetail = getOrganizationListResp?.data?.details;
          setOrgList(organization_list);
          setOrganisationUuidList(orgDetail);
          if (organization_list.length === 1) {
            setSelectedOrg(organization_list[0]);
            setSelectedOrgUuid(orgDetail[0]?.org_uuid);
            const loginResp = await loginUser({
              email: email,
              org_name: organization_list[0],
              org_uuid: orgDetail[0]?.org_uuid,
            });
            if (loginResp?.status === 200) {
              toast.success(loginResp?.data?.Status, {
                theme: "light",
                icon: <SioIcon name="check" size={16} color="blue" />,
              });
              setStep(3);
            } else {
              toast.error(loginResp?.data?.message, {
                theme: "light",
                icon: <SioIcon name="close" size={16} color="blue" />,
              });
            }
          } else {
            setStep(2);
          }
        } else {
          toast.error(getOrganizationListResp?.data?.message, {
            theme: "light",
            icon: <SioIcon name="close" size={16} color="blue" />,
          });
        }
      } catch (e) {
        toast.error(e?.message, {
          theme: "light",
          icon: <SioIcon name="close" size={16} color="blue" />,
        });
      }
    } else {
      toast.error("Email is required", {
        theme: "light",
        icon: <SioIcon name="close" size={16} color="blue" />,
      });
      setEmailError("Email is required", {
        theme: "light",
        icon: <SioIcon name="close" size={16} color="blue" />,
      });
    }
  };

  const handleGoogleVerification = async (
    verificationResponse,
    organizationName,
    organizationUuid
  ) => {
    toast.success("Google login successful", {
      theme: "light",
      icon: <SioIcon name="check" size={16} color="blue" />,
    });
    setIsGoogleLogin(false);
    const decodedToken = jwt_decode(verificationResponse?.data?.token);
    if (decodedToken !== undefined) {
      const user = decodedToken.data;
      let userDetails = {
        org_uuid: user.org_uuid,
        user_uuid: user.user.user_uuid,
        email: user.user.email,
      };

      const addPayload = {
        email: user.user.email,
        org_name: organizationName,
        org_uuid: organizationUuid,
        user_uuid: user.user.user_uuid,
      };
      const deviceUuid = getCookie("device_uuid");
      if (deviceUuid) {
        addPayload.device_uuid = deviceUuid;
      }

      const addNewacc = await addAccount(addPayload);
      if (addNewacc.status === 200) {
        if (addNewacc?.data?.device_uuid) {
          setCookie("device_uuid", addNewacc?.data?.device_uuid);
          setCookie("device_id", addNewacc?.data?.id);
        }
      }

      clearTokens();

      setCookie("token", verificationResponse?.data?.token);
      setCookie("refresh_token", verificationResponse?.data?.refresh_token);
      setCookie("user_email", user.user.email);
      setCookie("org", organizationName);
      setCookie("user", JSON.stringify(userDetails));
      setCookie("userName", user?.userdetails?.user_data?.first_name);
      setCookie("org_uuid", userDetails?.org_uuid);

      const userRole = user?.roles ? user?.roles : user?.userdetails?.roles;
      setCookie("user_role", userRole);

      const onboardingresp = await getOnboardingStatus({
        org_uuid: organizationUuid,
        user_uuid: user.user.user_uuid,
      });
      if (onboardingresp?.status === 200) {
        const onboardingStatus = onboardingresp?.data?.status;
        if (URL_POSTFIX === "localhost") {
          console.log(onboardingStatus);
          onboardingStatus ? navigate("/") : navigate("/onboarding");
        } else {
          console.log(onboardingStatus);

          window.location.href = onboardingStatus
            ? `https://${organizationName}${URL_POSTFIX}`
            : `https://${organizationName}${URL_POSTFIX}/onboarding`;
        }
      } else {
        if (URL_POSTFIX === "localhost") {
          navigate("/");
        } else {
          window.location.href = `https://${organizationName}${URL_POSTFIX}`;
        }
      }
    }
  };

  const handleOrgContinue = async () => {
    if (isGoogleLogin) {
      const googleVerificationResp = await googleVerification({
        appName: "strived",
        appId: 50,
        token: googleLoginToken,
        org_name: selectedOrg,
        org_uuid: selectedOrgUuid,
      });
      if (googleVerificationResp?.status === 200) {
        await handleGoogleVerification(
          googleVerificationResp,
          selectedOrg,
          selectedOrgUuid
        );
      } else {
        toast.error(googleVerificationResp?.data?.message, {
          theme: "light",
          icon: <SioIcon name="close" size={16} color="blue" />,
        });
      }
    } else {
      if (selectedOrg) {
        console.log(selectedOrgUuid);
        const loginResp = await loginUser({
          email: email,
          org_name: selectedOrg,
          org_uuid: selectedOrgUuid,
        });
        if (loginResp?.status === 200) {
          setStep(3);
        } else {
          toast.error(loginResp?.data?.message, {
            theme: "light",
            icon: <SioIcon name="close" size={16} color="blue" />,
          });
        }
      }
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleEmailContinue();
    }
  };

  const handleGoogleLoginSuccess = async (response) => {
    const payload = {
      token: response.credential,
      appName: "strived",
      appId: 50,
    };
    setGoogleLoginToken(response.credential);
    const googleLoginResp = await responseGoogle(payload);
    if (googleLoginResp.status === 200) {
      setIsGoogleLogin(true);
      let organization_list = googleLoginResp?.data?.list;
      const orgDetail = googleLoginResp?.data?.details;
      setIsUserSuperAdmin(googleLoginResp?.data?.isSuperuser);
      setOrgList(organization_list);
      setOrganisationUuidList(orgDetail);
      if (organization_list.length === 1) {
        setSelectedOrg(organization_list[0]);
        setSelectedOrgUuid(orgDetail[0]?.org_uuid);

        const googleVerificationResp = await googleVerification({
          appName: "strived",
          appId: 50,
          token: response.credential,
          org_name: organization_list[0],
          org_uuid: orgDetail[0]?.org_uuid,
        });
        if (googleVerificationResp?.status === 200) {
          await handleGoogleVerification(
            googleVerificationResp,
            organization_list[0],
            orgDetail[0]?.org_uuid
          );
        } else {
          toast.error(googleVerificationResp?.data?.message, {
            theme: "light",
            icon: <SioIcon name="close" size={16} color="blue" />,
          });
        }
      } else {
        setStep(2);
      }
    } else {
      toast.error(googleLoginResp?.data?.message, {
        theme: "light",
        icon: <SioIcon name="close" size={16} color="blue" />,
      });
    }
  };

  const handleGoogleLoginError = (error) => {
    console.error("Google Login Error: ", error);
    toast.error("Google login failed. Please try again.");
  };

  const textFieldStyle = (value) => {
    return {};
  };

  if (step === 3)
    return (
      <Otp
        email={email}
        selectedOrg={selectedOrg}
        selectedOrgUuid={selectedOrgUuid}
      />
    );

  return (
    <ThemeProvider theme={darkTheme}>
      <div className="bg-dark min-h-screen flex flex-col xs:p-2">
        <div className="sio-col-460 mb-auto">
          <div className="text-center px-12 pb-8 flex flex-col justify-center">
            <img
              src={signupLogo}
              alt="signup-page-logo"
              className="mt-12 mb-3 xs:h-9"
            />
            <p className="text-secondary-light text-xs sm:text-sm">
              Strived combines student data with district goals to deliver
              classroom insights
            </p>
          </div>
          <div className="sio-card bg-white/5 rounded-xl">
            <h2 className="text-xl font-normal text-primary-light font-manrope">
              Sign In with your organization account.
            </h2>
            <div>
              {step === 1 && (
                <>
                  <TextField
                    label="Organization Email Address"
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                      setEmailError("");
                    }}
                    onKeyDown={handleKeyDown}
                    fullWidth
                    error={!!emailError}
                    onFocus={() => {
                      setShowHelper(true);
                      setEmailError("");
                    }}
                    onBlur={() => {
                      setShowHelper(false);
                    }}
                    sx={textFieldStyle(email)}
                  />
                  {/* Collapse and Helper Text */}
                  <Collapse
                    in={showHelper || !!emailError}
                    timeout={100}
                    unmountOnExit>
                    <FormHelperText
                      className="text-[13px] font-normal !font-manrope"
                      component="div"
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: 0.5,
                        mt: 0.5,
                        color: emailError ? "#f44336" : "#fff",
                      }}>
                      {emailError ? (
                        <>{emailError}</>
                      ) : showHelper ? (
                        <>
                          <SioIcon name="info" size={16} />
                          Enter the email address associated with your
                          organization.
                        </>
                      ) : null}
                    </FormHelperText>
                  </Collapse>
                </>
              )}
              {step === 2 && (
                <FormControl fullWidth sx={{ minWidth: 200 }}>
                  <InputLabel>Select Organization</InputLabel>
                  <Select
                    value={selectedOrg}
                    label="Select Organization"
                    open={menuOpen}
                    onOpen={() => setMenuOpen(true)}
                    onClose={() => setMenuOpen(false)}
                    onChange={(e) => {
                      const uuid = orgUuidList.find(
                        (org) => org.name === e.target.value
                      );
                      setSelectedOrgUuid(uuid?.org_uuid);
                      setSelectedOrg(e.target.value);
                      setMenuOpen(false);
                    }}
                    renderValue={(value) => value || "Select Organization"}
                    MenuProps={{
                      disableAutoFocusItem: true,
                      PaperProps: {
                        onMouseDown: (e) => e.stopPropagation(),
                      },
                    }}>
                    {/* Search Input Field */}
                    {isUserSuperAdmin && orgList.length > 4 && (
                      <MenuItem disableRipple>
                        <TextField
                          placeholder="Search organization..."
                          fullWidth
                          value={searchText}
                          inputRef={inputRef}
                          onChange={(e) => {
                            setSearchText(e.target.value);
                            setTimeout(() => inputRef.current?.focus(), 0);
                            setSelectedOrg(e.target.value);
                          }}
                          autoFocus
                          onClick={(e) => e.stopPropagation()}
                          onKeyDown={(e) => e.stopPropagation()}
                          InputProps={{
                            style: {
                              color: "#000",
                              border: "2px solid #e5e7eb",
                            },
                          }}
                          sx={{
                            "& .MuiInputBase-input::placeholder": {
                              color: "#666",
                              opacity: 1,
                            },
                            "& .MuiInputBase-input": {
                              color: "#000",
                            },
                            backgroundColor: "#fff",
                          }}
                        />
                      </MenuItem>
                    )}

                    {/* Filtered Organization List */}
                    {filteredOrgs.length > 0 ? (
                      filteredOrgs.map((org, index) => (
                        <MenuItem
                          key={index}
                          value={org}
                          onClick={() => {
                            setSelectedOrg(org);
                            setMenuOpen(false);
                          }}>
                          {org}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem disabled>No organisation found</MenuItem>
                    )}
                  </Select>
                </FormControl>

                // <FormControl fullWidth sx={{ minWidth: 200 }}>
                //   <InputLabel>Select Organization</InputLabel>
                //   <Select
                //     value={selectedOrg}
                //     label="Select Organization"
                //     open // ✅ Keeps the dropdown open even when typing
                //     onClose={(e) => e.stopPropagation()} // ✅ Prevents auto-closing on interactions
                //     onChange={(e) => {
                //       setSelectedOrg(e.target.value);
                //       const uuid = orgUuidList.find(
                //         (org) => org.name === e.target.value
                //       );
                //       setSelectedOrgUuid(uuid?.org_uuid);
                //     }}
                //     renderValue={(value) => value || "Select Organization"}
                //     MenuProps={{
                //       disableAutoFocusItem: true, // ✅ Prevents auto-selection on focus loss
                //       PaperProps: {
                //         onMouseDown: (e) => e.stopPropagation(), // ✅ Prevents closing on click
                //       },
                //     }}
                //   >
                //     {/* Search Input Field */}
                //     <MenuItem disableRipple>
                //       <TextField
                //         placeholder="Search organization..."
                //         fullWidth
                //         value={searchText}
                //         inputRef={inputRef} // ✅ Keeps focus on TextField
                //         onChange={(e) => {
                //           setSearchText(e.target.value);
                //           setTimeout(() => inputRef.current?.focus(), 0); // ✅ Re-focus after state update
                //         }}
                //         autoFocus
                //         onClick={(e) => e.stopPropagation()} // ✅ Stops dropdown from closing
                //         InputProps={{
                //           style: { color: "#000", border: "2px solid #e5e7eb" }, // Ensures visible text
                //         }}
                //         sx={{
                //           "& .MuiInputBase-input::placeholder": {
                //             color: "#666", // Placeholder visible
                //             opacity: 1, // Ensure it is not faded
                //           },
                //           "& .MuiInputBase-input": {
                //             color: "#000", // Input text color
                //           },
                //           backgroundColor: "#fff", // Background color
                //         }}
                //       />
                //     </MenuItem>

                //     {/* Filtered Organization List */}
                //     {filteredOrgs.length > 0 ? (
                //       filteredOrgs.map((org, index) => (
                //         <MenuItem key={index} value={org}>
                //           {org}
                //         </MenuItem>
                //       ))
                //     ) : (
                //       <MenuItem disabled>No results found</MenuItem>
                //     )}
                //   </Select>
                // </FormControl>
              )}
            </div>
            <div>
              {step === 1 && (
                <Button
                  variant="secondary"
                  size="full"
                  onClick={handleEmailContinue}>
                  Continue
                </Button>
              )}
              {step === 2 && (
                <Button
                  variant="secondary"
                  size="full"
                  onClick={handleOrgContinue}>
                  Proceed
                </Button>
              )}
            </div>
            {step === 1 && (
              <>
                <div className="sio-divider">OR</div>

                <div className="sio-google-col">
                  <p className="text-xs mb-2 text-secondary-light">
                    Connect with your Organization ID
                  </p>
                  <div className="sio-google-btn">
                    <GoogleLogin
                      theme="outline"
                      logo_alignment="center"
                      width={400}
                      size="large"
                      onSuccess={handleGoogleLoginSuccess}
                      onError={handleGoogleLoginError}
                      context="signin"
                      allowed_parent_origin={["https://dev.strived.io"]}
                    />
                  </div>
                </div>
              </>
            )}
          </div>
          <div className="sio-card bg-secondary-gradient xs:-mt-5 sm:-mt-10 xs:pt-10 sm:pt-20 gap-3 flex flex-row items-center">
            <p className="grow basis-0 text-secondary-light font-medium xs:text-xs">
              If your organization isn't registered, we're here to help you get
              started.{" "}
              <strong>Reach out to our team for a quick and easy setup.</strong>
            </p>
            <Button
              variant="primary"
              size="small"
              onClick={() => {
                navigate("/contact");
              }}>
              Contact Us
            </Button>
          </div>
        </div>

        <footer className="flex justify-center p-3">
          <ul className="flex divide-x text-sm divide-light font-medium leading-none flex-wrap justify-center">
            <li className="px-2 xs:my-1 sm:my-0">
              <a
                className="text-muted-light hover:text-[color:#cf037e]"
                href="#">
                {" "}
                Terms of Use
              </a>
            </li>
            <li className="px-2 xs:my-1 sm:my-0">
              <a
                className="text-muted-light hover:text-[color:#cf037e]"
                href="#">
                Privacy Policy
              </a>
            </li>
            <li className="px-2 xs:my-1 sm:my-0 text-muted-light xs:border-0 md:border-1">
              ©{new Date().getFullYear()} Strived.io, All Rights Reserved
            </li>
          </ul>
        </footer>
      </div>
    </ThemeProvider>
  );
};

export default Login;
