/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useRef, useState } from "react";
import Button from "../../../components/button";
import {
  Checkbox,
  CircularProgress,
  Collapse,
  Dialog,
  Drawer,
  FormControl,
  ListItemText,
  ListSubheader,
  Menu,
  MenuItem,
  OutlinedInput,
  Radio,
  Select,
  Skeleton,
  TextField,
  useMediaQuery,
} from "@mui/material";
import { Link } from "react-router-dom";
import InputField from "../../../components/InputField";
import { FiSearch } from "react-icons/fi";
import {
  archiveTheFile,
  deleteFile,
  downloadFile,
  saveUserPreferences,
  updateTopic,
  viewFile,
} from "../../../apis/recommendation/recommendation";
import { computeSignature, getUserNames } from "../../../utils";
import { devApis } from "../../../constants/constant";
import { toast } from "react-toastify";
import { StrivedContext } from "../../../App";
// import { useGoogleLogin } from "@react-oauth/google";
import {
  getFeatureStatus,
  getUserRoleStatus,
} from "../../../apis/organization/index";
import { checkAndRefreshToken } from "../../../utils/authUtils";
import { DataContext } from "../../../components/layout/layout";
import SioIcon from "../../../components/icons/IconLibrary";
import Papa from "papaparse";
import "handsontable/dist/handsontable.full.css";
import MultiSelect from "../../../components/MultiSelect";
import ContextFiles from "./context-files";
import { getCookie } from "../../../utils/cookieHelper";
import { customFetch } from "../../../utils/RefreshTokenInterceptor";
import { filter } from "lodash";

const FilesUploaded = ({ dataSource, filesBasedSubTopics }) => {
  const [filteredFileData, setFilteredFileData] = useState(dataSource);
  const [isLabelChanged, setIsLabelChanged] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isDownloadBtnEnabled, setIsDownloadBtnEnabled] = useState(false);
  const [isDeleteBtnEnabled, setIsDeleteBtnEnabled] = useState(false);
  // const [subTopicsList, setSubTopicsList] = useState([]);
  const [selectedValues, setSelectedValues] = useState({});
  const [contextFilesList, setContextFilesList] = useState([]);
  const [topicsList, setTopicsList] = useState([]);

  const [filteredTopicsList, setFilteredTopicsList] = useState([]);
  const [openFIlterDropDown, setOpenFIlterDropDown] = useState(false);
  const [selectedTopicsFilesData, setSelectedTopicsFilesData] =
    useState(dataSource);
  const [inputValue, setInputValue] = useState("");

  const handleClearAll = () => {
    setFilteredTopicsList([]);
    setFilteredFileData([]);
    // setInputValue("");
  };

  const { setSuggestions, setSelectSubTopics } = useContext(StrivedContext);
  const {
    setHighlightField,
    setS3Key,
    s3Key,
    setIsArchivedDataSources,
    loadingViewFileData,
    setLoadingViewFileData,
    setFilesBasedSubTopics,
    setDataSource,
    setFilesData,
  } = useContext(DataContext);

  const updatedDataSource =
    dataSource && dataSource.filter((file) => !file.archive_status);

  const [isUploadButtonVisible, setIsUploadButtonVisible] = useState(false);
  const fetchAdminPrivileges = async () => {
    const token = await checkAndRefreshToken();
    const getuserRoleStatusResp = await getUserRoleStatus(token);
    if (getuserRoleStatusResp?.status === 200) {
      setIsUploadButtonVisible(
        getuserRoleStatusResp?.data?.isSuperuser || false
      );
    } else {
      setIsUploadButtonVisible(false);
    }
  };

  useEffect(() => {
    fetchAdminPrivileges();
  }, []);

  useEffect(() => {
    if (dataSource) {
      setFilteredFileData(updatedDataSource);

      setTopicsList([...new Set(updatedDataSource.map((file) => file?.topic))]);
      setFilteredTopicsList(() => {
        const topics = [
          ...new Set(updatedDataSource.map((file) => file?.topic)),
        ];
        setSelectedTopicsFilesData(
          dataSource
            .filter((item) => topics.includes(item.topic))
            .filter((file) => !file?.archive_status)
        );
        return topics;
      });
      setSelectedValues({
        filter: filesBasedSubTopics.flatMap((item) => item.group).flat(),
      });
    }
  }, [dataSource, isLabelChanged]);

  const computeDateTime = (updated) => {
    let hours = updated.getHours();
    let month = updated.toLocaleString("default", { month: "short" });
    let date = updated.getDate();
    let year = updated.getFullYear();

    const minutes = updated.getMinutes();
    const ampm = hours >= 12 ? "PM" : "AM";

    hours = hours % 12;
    hours = hours ? hours : 12;

    const formattedMinutes = minutes < 10 ? "0" + minutes : minutes;

    const time = `${date} ${month} ${year}, ${hours}:${formattedMinutes} ${ampm}`;
    return time;
  };

  const calculateTime = (date) => {
    if (!date) return null;
    const today = new Date();
    const updated = new Date(date);
    const updatedMonth = updated.toLocaleString("default", { month: "short" });
    const updatedDate = updated.getDate();
    const todayMonth = today.toLocaleString("default", { month: "short" });
    const todayDate = today.getDate();

    let recommendationDate;
    if (todayMonth === updatedMonth) {
      if (todayDate - updatedDate >= 1) {
        // ${updatedDate} ${updatedMonth},
        recommendationDate = `${computeDateTime(updated)}`;
      } else if (todayDate - updatedDate === 0) {
        recommendationDate = computeDateTime(updated);
      }
    } else {
      recommendationDate = computeDateTime(updated);
    }
    return recommendationDate;
  };

  // File Description
  const [openFileDescriptionDrawer, setFileDescriptionDrawerOpen] =
    React.useState(false);
  const toggleFileDescriptionDrawer = (newOpen, file) => () => {
    if (newOpen) {
      getDownloadFeatureStatus();
    }
    setFileDescriptionDrawerOpen(newOpen);
    setSelectedFileData(file);
  };

  // Upload Data File
  const [openUploadDataFileDrawer, setUploadDataFileDrawerOpen] =
    React.useState(false);
  const toggleUploadDataFileDrawer = (newOpen) => () => {
    setUploadDataFileDrawerOpen(newOpen);
    if (!newOpen) {
      setErrorMessage("");
      setSelectedFile(null);
    }
  };

  // Upload Data File
  const [selectedFile, setSelectedFile] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [isDragOver, setIsDragOver] = useState(false);

  const fileInputRef = useRef(); // Reference to the file input

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    validateFile(file);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    setIsDragOver(true);
  };

  const handleDragLeave = () => {
    setIsDragOver(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const file = Array.from(e.dataTransfer.files);

    validateFile(file);

    setIsDragOver(false);
  };

  const handleClick = () => {
    fileInputRef.current.click();
  };

  const handleRemoveFile = (file) => {
    // (file);
    setSelectedFile((prevFiles) => {
      const modifiedData = prevFiles.filter(
        (fileData) => fileData.name !== file.name
      );
      // (modifiedData);
      return modifiedData;
    });
    setErrorMessage("");
    fileInputRef.current.value = "";
  };

  const validateFile = (files) => {
    if (files) {
      files.forEach((file) => {
        const allowedExtensions = ["xls", "xlsx", "csv", "pdf", "filepart"];
        const maxSize = 30 * 1024 * 1024;
        const fileName = file.name.toLowerCase();
        const fileSize = file.size;

        const fileExtension = fileName.split(".").pop();

        if (!allowedExtensions.includes(fileExtension)) {
          setErrorMessage(
            "Invalid file type. Only xls, xlsx, csv, pdf and filepart files are allowed."
          );
          setSelectedFile(null);
          return;
        }

        if (fileSize > maxSize) {
          setErrorMessage("File size exceeds the 30MB limit.");
          setSelectedFile(null);
          return;
        }

        setSelectedFile((prevFile) => [...prevFile, file]);
        setErrorMessage("");
      });
    }
  };

  const [openRemoveModal, setRemoveModalOpen] = React.useState(false);
  const handleRemoveModalClickOpen = (file) => {
    setRemoveModalOpen(true);
    setSelectedFileData(file);
  };
  const handleRemoveModalClose = () => {
    setRemoveModalOpen(false);
    setOpenFileDownloadModal(false);
  };

  const [openFileDownloadModal, setOpenFileDownloadModal] = useState(false);

  const handleFileDownloadModalClose = (file) => {
    setOpenFileDownloadModal(false);
    setSelectedFileData(file);
  };
  // const handleRemoveModalClose = () => {
  //   setRemoveModalOpen(false);
  // };

  // Add New Data Label Modal
  const [openAddDataLabelModal, setAddDataLabelModalOpen] =
    React.useState(false);
  // const handleAddDataLabelModalClickOpen = () => {
  //   setAddDataLabelModalOpen(true);
  // };
  const handleAddDataLabelModalClose = () => {
    setAddDataLabelModalOpen(false);
  };

  const handleInput = (event) => {
    const { value } = event.target;
    const filteredData = dataSource.filter((data) =>
      data.original_file_name.toLowerCase().includes(value.toLowerCase())
    );
    if (isLabelChanged) setIsLabelChanged(false);
    setFilteredFileData(filteredData);
  };

  function debounce(func, delay) {
    let timer;
    return function (...args) {
      const context = this;
      clearTimeout(timer);
      timer = setTimeout(() => func.apply(context, args), delay);
    };
  }

  const [selectedFileData, setSelectedFileData] = useState(null);

  const handleFileUpload = async () => {
    if (!selectedFile.length) {
      toast(`Please select files to upload`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
        progress: false,
        className: "custom-toast",
      });
      return;
    }

    setIsLoading(true);
    const getUUID = await getUserNames();
    const orgUuid = getUUID.data.org_uuid;
    const secretKey = "wBLm_uRNsyuxHWZrIufExXRBI-uE0aDkjT7xsKLbn70=";

    try {
      if (selectedFile.length > 10)
        setErrorMessage("You can upload only 10 files at a time");

      // Create a single FormData for all files
      const formData = new FormData();
      formData.append("org_uuid", orgUuid);

      // Add all files to FormData
      selectedFile.forEach((file, index) => {
        formData.append(`files`, file);
      });

      // Read content of all files for signature
      const allFilesContent = await Promise.all(
        selectedFile.map(
          (file) =>
            new Promise((resolve, reject) => {
              const reader = new FileReader();
              reader.onload = () => resolve(reader.result);
              reader.onerror = reject;
              reader.readAsBinaryString(file);
            })
        )
      );

      // Combine all file contents for signature
      const combinedContent = allFilesContent.join("");

      const fileUploadsignature = computeSignature(
        secretKey,
        "POST",
        "/upload_file",
        {},
        { org_uuid: orgUuid },
        "",
        combinedContent
      );

      // Upload all files at once
      // const token = await checkAndRefreshToken();
      const fileUploadResponse = await customFetch(
        `${devApis.PYTHON_BASE_URL}/upload_file`,
        {
          method: "POST",
          headers: {
            "x-signature": fileUploadsignature,
            // Authorization: `Bearer ${token}`,
          },
          body: formData,
        }
      );

      const { results } = await fileUploadResponse.json();

      const getS3KeysList = JSON.parse(sessionStorage.getItem("s3_keys"));
      const resultsS3Keys = results.map((file) => file?.s3_key);
      // ([...resultsS3Keys, ...getS3KeysList]);
      const updatedS3KeysList = [...resultsS3Keys, ...getS3KeysList].filter(
        (file) => file.endsWith(".csv")
      );

      // (updatedS3KeysList);
      sessionStorage.setItem("s3_keys", JSON.stringify(updatedS3KeysList));

      setS3Key(updatedS3KeysList);

      setFilteredFileData((prevFiles) => [
        ...results.filter(
          (file) => file?.original_file_name.endsWith(".csv")
          //|| file?.original_file_name.endsWith(".xlsx")
        ),
        ...prevFiles,
      ]);
      setContextFilesList((prevFiles) => [
        ...results.filter(
          (file) =>
            !file?.original_file_name.endsWith(".csv") &&
            !file?.original_file_name.endsWith(".xlsx")
        ),
        ...prevFiles,
      ]);

      // Process CSV files only for table conversion

      const csvFiles = selectedFile.filter((file) =>
        file.name.toLowerCase().endsWith(".csv")
      );

      // (csvFiles);
      if (csvFiles.length > 0) {
        await Promise.all(
          csvFiles.map(async (csvFile) => {
            const csvFormData = new FormData();
            csvFormData.append("file", csvFile);
            csvFormData.append("org_uuid", orgUuid);
            // const token = await checkAndRefreshToken();

            await customFetch(
              `${devApis.PYTHON_BASE_URL}/convert_csv_to_table`,
              {
                method: "POST",
                headers: {
                  // Authorization: `Bearer ${token}`,
                },
                body: csvFormData,
              }
            );

            toast.success(`Converted ${csvFile.name} to table`, {
              position: "top-right",
              autoClose: 3000,
            });
          })
        );
      }

      toast.success(`Successfully uploaded ${selectedFile.length} file(s)`, {
        position: "top-right",
        autoClose: 3000,
      });
    } catch (error) {
      console.error(error);
      toast.error(`Error: ${error.message}`, {
        icon: <SioIcon name="close" size={16} color="blue" />,
      });
    } finally {
      setIsLoading(false);
      setSelectedFile([]);
    }
  };

  const handleFileSelect = (event) => {
    const files = event.target.files;

    if (files) {
      setSelectedFile(Array.from(files));
    }
  };

  const onboardingData = [
    {
      topic: "Student Information System (SIS)",
      subTopics: [
        "Attendance",
        "Demographics",
        "Class Grades",
        "Discipline",
        "Enrollment",
        "Class Scheduling",
        "Staff Info",
        "School Info",
      ],
    },
    {
      topic: "Assessments",
      subTopics: ["Formative Assessments", "Summative Assessments"],
    },
    {
      topic: "Special Education",
      subTopics: ["IEP Goals"],
    },
    {
      topic: "Behavior Systems",
      subTopics: ["Referrals", "Detentions", "Incentives", "Incident Reports"],
    },
    {
      topic: "Learning Management Systems (LMS)",
      subTopics: ["Assignments", "Quizzes", "Unit Tests"],
    },
    {
      topic: "Progress Monitoring and Edtech Tools",
      subTopics: ["Progress Metrics", "Fidelity", "Skills assessment"],
    },
  ];

  const createAllOptions = (data) => {
    const result = [];
    data.forEach((item) => {
      const { topic, id } = item;

      if (!topic) return;
      const modifiedTopic = topic.replace(/\*/g, "");

      if (!modifiedTopic) return;
      if (!result.find((data) => data.group === modifiedTopic)) {
        const getIndex =
          onboardingData[
            onboardingData.findIndex((data) => data.topic === modifiedTopic)
          ];

        const object = {
          group: getIndex.topic,
          options: getIndex.subTopics,
        };

        result.push(object);
      }
    });
    return result;
  };

  const handleSelectAllChange = async (isChecked) => {
    if (!isChecked) {
      const getFilteredS3Keys = filteredFileData.map((key) => key.s3_key);
      setS3Key((prevKeys) => {
        const unselectFilteredFile = prevKeys.filter(
          (key) => !getFilteredS3Keys.includes(key)
        );
        return unselectFilteredFile;
      });
      setSuggestions([]);
    } else {
      const allS3Keys = filteredFileData.map((item) => item.s3_key);
      setS3Key((prevkeys) => {
        const selectedKeys = [...new Set([...prevkeys, ...allS3Keys])];
        return selectedKeys;
      });
      const allSubTopicsObject = createAllOptions(filteredFileData);

      setFilesBasedSubTopics(allSubTopicsObject);
      setFilesData(filteredFileData);
      await saveUserPreferences([], []);
    }
    sessionStorage.setItem("subTopics", JSON.stringify([]));
    sessionStorage.setItem("updatedOnboardingData", JSON.stringify(false));
    setSelectSubTopics([]);
  };

  const handleCheckboxChange = async (file) => {
    if (s3Key.includes(file.s3_key)) {
      setS3Key(() => {
        const filteredS3Keys = s3Key.filter((key) => key !== file.s3_key);
        // getRecByS3Keys(filteredS3Keys, filteredFileData, setSuggestions);
        return filteredS3Keys;
      });
    } else {
      setS3Key(() => {
        const filteredS3Keys = [...s3Key, file.s3_key];
        // getRecByS3Keys(filteredS3Keys, filteredFileData, setSuggestions);
        return filteredS3Keys;
      });
    }
    await saveUserPreferences([], []);
    const allSubTopicsObject = createAllOptions(filteredFileData);

    setFilesBasedSubTopics(allSubTopicsObject);
    setFilesData(filteredFileData);

    sessionStorage.setItem("updatedOnboardingData", JSON.stringify(false));
    sessionStorage.setItem("subTopics", JSON.stringify([]));
    setSelectSubTopics([]);
  };

  const getDownloadFeatureStatus = async () => {
    const token = await checkAndRefreshToken();
    const payload = {
      appName: "strived",
      featureName: "Data Sources download",
      org_uuid: getCookie("org_uuid"),
    };
    const getDownloadFeatureStatusResp = await getFeatureStatus(payload, token);
    if (getDownloadFeatureStatusResp?.status === 200) {
      const features = getDownloadFeatureStatusResp?.data?.features;

      if (Array.isArray(features) && features.length > 0) {
        const status = features[0]?.status ?? false;
        setIsDownloadBtnEnabled(status);
      } else {
        setIsDownloadBtnEnabled(false);
      }
    } else {
      setIsDownloadBtnEnabled(false);
    }
  };

  const getDeleteFeatureStatus = async () => {
    const token = await checkAndRefreshToken();
    const payload = {
      appName: "strived",
      featureName: "Data Sources Delete",
      org_uuid: getCookie("org_uuid"),
    };
    const getDeleteFeatureStatusResp = await getFeatureStatus(payload, token);
    if (getDeleteFeatureStatusResp?.status === 200) {
      const features = getDeleteFeatureStatusResp?.data?.features;

      if (Array.isArray(features) && features.length > 0) {
        const status = features[0]?.status ?? false;
        setIsDeleteBtnEnabled(status);
      } else {
        setIsDeleteBtnEnabled(false);
      }
    } else {
      setIsDeleteBtnEnabled(false);
    }
  };
  useEffect(() => {
    getDeleteFeatureStatus();

    const isRefreshed = sessionStorage.getItem("isRefresh");
    if (isRefreshed === "true") {
      sessionStorage.removeItem("isRefresh");
      setHighlightField("DataSource");
    }

    const handleBeforeUnload = () => {
      sessionStorage.setItem("isRefresh", "true");
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  const isDesktop = useMediaQuery("(min-width:1023px)");

  useEffect(() => {
    setOpen(isDesktop);
  }, [isDesktop]);

  const [open, setOpen] = useState(isDesktop);
  const handleToggle = () => {
    setOpen(!open);
  };

  const [dropdownOpen, setDropdownOpen] = useState({});

  const [selectedTopics, setSelectedTopics] = React.useState(
    () =>
      filteredFileData &&
      filteredFileData.reduce((acc, file) => {
        if (file.id) {
          acc[file.id] = Array.isArray(file.topic)
            ? file.topic
            : file.topic
            ? [file.topic]
            : [];
        }
        return acc;
      }, {})
  );

  React.useEffect(() => {
    if (filteredFileData && filteredFileData.length > 0) {
      const topics = filteredFileData.reduce((acc, file) => {
        acc[file.id] = Array.isArray(file.topic)
          ? file.topic
          : file.topic
          ? [file.topic]
          : [];
        return acc;
      }, {});
      setSelectedTopics(topics);
    }
  }, [filteredFileData]);

  const handleTopicChange = (fileId, topic) => {
    setSelectedTopics((prevData) => {
      const updatedData = { ...prevData };
      updatedData[fileId] = [topic.group];
      // (updatedData);
      return updatedData;
    });
  };

  if (!filesBasedSubTopics) return;

  const allOptions = [
    {
      group: "Student Information System (SIS)",
      options: [
        "Attendance",
        "Demographics",
        "Class Grades",
        "Discipline",
        "Enrollment",
        "Class Scheduling",
        "Staff Info",
        "School Info",
      ],
    },
    {
      group: "Assessments",
      options: ["Formative Assessments"],
    },
    {
      group: "Special Education",
      options: ["IEP Goals"],
    },
    {
      group: "Behavior Systems",
      options: ["Referrals", "Detentions", "Incentives", "Incident Reports"],
    },
    {
      group: "Learning Management Systems (LMS)",
      options: ["Assignments", "Quizzes", "Unit Tests"],
    },
    {
      group: "Progress Monitoring and Edtech Tools",
      options: ["Progress Metrics", "Fidelity", "Skills assessment"],
    },
  ];

  const debouncedInputHandler = (value) => {
    setInputValue(value);
    const filterData = (data) =>
      data
        .filter((item) =>
          item?.original_file_name.toLowerCase().includes(value.toLowerCase())
        )
        .filter((file) => !file?.archive_status);

    const selectedTypesFilesData = dataSource.filter((fileData) =>
      filteredTopicsList.includes(fileData.topic)
    );

    setFilteredFileData(() => {
      const data = filterData(selectedTypesFilesData);
      return data;
    });
  };

  const handlePromptInputChange = (event) => {
    const value = event.target.value;
    debouncedInputHandler(value);
  };
  // (filteredFileData);

  const handleSelectAllToggle = (isChecked) => {
    const filterDataByTopics = (topics) =>
      dataSource
        .filter(
          (item) =>
            topics.includes(item.topic) &&
            (!inputValue ||
              item.original_file_name
                .toLowerCase()
                .includes(inputValue.toLowerCase()))
        )
        .filter((file) => !file?.archive_status);

    if (isChecked) {
      setFilteredTopicsList([...topicsList]);
      const filteredData = filterDataByTopics(topicsList);
      setFilteredFileData(filteredData);
      setSelectedTopicsFilesData(filteredData);
    } else {
      setFilteredTopicsList([]);
      setFilteredFileData([]);
      setSelectedTopicsFilesData([]);
    }
  };

  // const handleSelectAllToggle = (isChecked) => {
  //   if (!isChecked) {
  //     setFilteredTopicsList([]);
  //     setFilteredFileData([]);
  //     setSelectedTopicsFilesData([]);
  //   } else {
  //     setFilteredTopicsList([...topicsList]);
  //     console.log(inputValue);
  //     if (inputValue.length > 0) {
  //       console.log(
  //         dataSource
  //           .filter(
  //             (item) =>
  //               topicsList.includes(item.topic) &&
  //               item.original_file_name
  //                 .toLowerCase()
  //                 .includes(inputValue.toLowerCase())
  //           )
  //           .filter((file) => !file?.archive_status)
  //       );
  //       setFilteredFileData(() =>
  //         dataSource
  //           .filter(
  //             (item) =>
  //               topicsList.includes(item.topic) &&
  //               item.original_file_name
  //                 .toLowerCase()
  //                 .includes(inputValue.toLowerCase())
  //           )
  //           .filter((file) => !file?.archive_status)
  //       );
  //       setSelectedTopicsFilesData(() =>
  //         dataSource
  //           .filter(
  //             (item) =>
  //               topicsList.includes(item.topic) &&
  //               item.original_file_name
  //                 .toLowerCase()
  //                 .includes(inputValue.toLowerCase())
  //           )
  //           .filter((file) => !file?.archive_status)
  //       );
  //     } else {
  //       setFilteredFileData(() =>
  //         dataSource
  //           .filter((item) => topicsList.includes(item.topic))
  //           .filter((file) => !file?.archive_status)
  //       );
  //       setSelectedTopicsFilesData(() =>
  //         dataSource
  //           .filter((item) => topicsList.includes(item.topic))
  //           .filter((file) => !file?.archive_status)
  //       );
  //     }
  //   }
  //   // setInputValue("");
  // };

  const handleFilterCheckboxChange = (topic) => {
    setFilteredTopicsList((prev) => {
      const updatedTopics = prev.includes(topic)
        ? prev.filter((item) => item !== topic)
        : [...prev, topic];

      const filterData = (data) =>
        data
          .filter(
            (item) =>
              updatedTopics.includes(item.topic) &&
              (!inputValue ||
                item.original_file_name
                  .toLowerCase()
                  .includes(inputValue.toLowerCase()))
          )
          .filter((file) => !file?.archive_status);

      const filteredData = filterData(dataSource);

      setFilteredFileData(filteredData);
      setSelectedTopicsFilesData(filteredData);

      return updatedTopics;
    });
  };
  // const handleFilterCheckboxChange = (topic) => {
  //   setFilteredTopicsList((prev) => {
  //     const topics = prev.includes(topic)
  //       ? prev.filter((item) => item !== topic)
  //       : [...prev, topic];

  //     if (inputValue.length > 0) {
  //       setFilteredFileData(() =>
  //         dataSource
  //           .filter(
  //             (item) =>
  //               topics.includes(item.topic) &&
  //               item.original_file_name
  //                 .toLowerCase()
  //                 .includes(inputValue.toLowerCase())
  //           )
  //           .filter((file) => !file?.archive_status)
  //       );
  //       setSelectedTopicsFilesData(
  //         dataSource
  //           .filter(
  //             (item) =>
  //               topics.includes(item.topic) &&
  //               item.original_file_name
  //                 .toLowerCase()
  //                 .includes(inputValue.toLowerCase())
  //           )
  //           .filter((file) => !file?.archive_status)
  //       );
  //     } else {
  //       setFilteredFileData(() =>
  //         dataSource
  //           .filter((item) => topics.includes(item.topic))
  //           .filter((file) => !file?.archive_status)
  //       );
  //       setSelectedTopicsFilesData(
  //         dataSource
  //           .filter((item) => topics.includes(item.topic))
  //           .filter((file) => !file?.archive_status)
  //       );
  //     }
  //     return topics;
  //   });
  //   // setInputValue("");
  // };

  const fetchWithTimeout = async (url, timeout = 30000) => {
    return new Promise((resolve, reject) => {
      const timeoutId = setTimeout(() => {
        reject(
          new Error(
            "The file is large or your internet connection is slow. Please try again later."
          )
        );
      }, timeout);

      fetch(url)
        .then((response) => {
          if (!response.ok)
            throw new Error(
              "There was an issue loading the file. Please check your connection."
            );
          return response.text();
        })
        .then((data) => {
          clearTimeout(timeoutId);
          resolve(data);
        })
        .catch((error) => {
          clearTimeout(timeoutId);
          reject(error);
        });
    });
  };

  return (
    <>
      {loadingViewFileData && (
        <div className="fixed inset-0 z-50 bg-black bg-opacity-70 flex items-center justify-center">
          <svg width={0} height={0}>
            <defs>
              <linearGradient
                id="my_gradient"
                x1="0%"
                y1="0%"
                x2="0%"
                y2="100%"
              >
                <stop offset="0%" stopColor="#e01cd5" />
                <stop offset="100%" stopColor="#1CB5E0" />
              </linearGradient>
            </defs>
          </svg>
          <CircularProgress
            size="64px"
            sx={{ "svg circle": { stroke: "url(#my_gradient)" } }}
          />
        </div>
      )}
      <>
        <div className="p-3 md:p-6 flex flex-wrap gap-0 lg:gap-5">
          <div className="items-center ms-auto flex flex-grow lg:flex-grow-0 gap-2 md:gap-3 order-0 lg:order-1">
            <Button
              outline
              fab
              variant="dark"
              className="size-8 flex lg:hidden me-auto"
              size={"xs"}
              onClick={handleToggle}
            >
              <SioIcon name="filter" size={18} />
            </Button>
            <Link
              to="archived-data-sources"
              onClick={() => setIsArchivedDataSources(true)}
            >
              <Button
                variant="dark"
                className="sio-btn-link !text-xs md:text-sm p-2 text-primary-dark"
                size={"sm"}
              >
                View Archived Data
              </Button>
            </Link>
            {isUploadButtonVisible && (
              <Button
                variant="secondary"
                className="!text-xs md:text-sm"
                onClick={toggleUploadDataFileDrawer(true)}
                size={"sm"}
              >
                {" "}
                Upload New Data
              </Button>
            )}
          </div>
          <Collapse in={open} className="max-lg:w-full">
            <div className="flex flex-wrap gap-3 max-lg:grid max-md:grid-cols-1 max-lg:grid-cols-2 md:gap-5 mt-3 md:mt-5 lg:mt-0">
              <div className="max-lg:w-full lg:min-w-64 xl:min-w-96">
                <FormControl fullWidth size="small">
                  <InputField
                    value={inputValue}
                    placeholder={`Search...`}
                    css="flex-grow px-3 py-2 text-sm focus:outline-none focus:border-none"
                    icon={<FiSearch />}
                    sx={{
                      "&.Mui-focusVisible": {
                        boxShadow: "none",
                      },
                    }}
                    onChange={handlePromptInputChange}
                  />
                </FormControl>
              </div>

              <div className="max-lg:w-full lg:min-w-56 xl:min-w-80">
                <FormControl fullWidth size="small">
                  <Select
                    multiple
                    value={filteredTopicsList || []}
                    limitTags={2}
                    displayEmpty
                    open={openFIlterDropDown}
                    onClose={() => setOpenFIlterDropDown(false)}
                    onOpen={() => setOpenFIlterDropDown(true)}
                    input={
                      <OutlinedInput
                        id="select-multiple-chip-label"
                        notched={false}
                        className="sio-select"
                        placeholder="Topics"
                      />
                    }
                    renderValue={(selected = []) => (
                      <>
                        <div
                          className="flex items-center text-sm text-secondary-dark"
                          dangerouslySetInnerHTML={{
                            __html:
                              selected.length > 0
                                ? `Topics<span class="bg-warning/55 ml-1 rounded-full size-4 inline-flex text-xs font-extrabold text-secondary-dark items-center justify-center">${
                                    [...new Set(selected)].length
                                  }</span>`
                                : `Topics`,
                          }}
                        />
                      </>
                    )}
                    MenuProps={{
                      PaperProps: { style: { width: "auto" } },
                      MenuListProps: {
                        className: "sio-menu-check-list check-list-group",
                      },
                    }}
                    inputProps={{ "aria-label": "Without label" }}
                  >
                    {topicsList.length > 0 && (
                      <div className="sio-select-header">
                        <div className="me-auto">
                          <Checkbox
                            checked={
                              filteredTopicsList.length === topicsList.length
                            }
                            indeterminate={
                              filteredTopicsList.length > 0 &&
                              filteredTopicsList.length < topicsList.length
                            }
                            onChange={(e) =>
                              handleSelectAllToggle(e.target.checked)
                            }
                          />
                          <span className="!text-primary-dark text-sm font-semibold">
                            Select All
                          </span>
                        </div>
                        <button
                          onClick={handleClearAll}
                          className="sio-link text-sm font-normal normal-case"
                        >
                          Clear All
                        </button>
                      </div>
                    )}

                    {/* Dynamic Rendering of Groups */}
                    {topicsList.length > 0 ? (
                      topicsList.map((group) => {
                        return (
                          <div className="menu-item-group" key={group}>
                            <ListSubheader
                              component="div"
                              className="flex items-center"
                            >
                              <Checkbox
                                checked={
                                  filteredTopicsList &&
                                  filteredTopicsList.includes(group)
                                }
                                onChange={(e) => {
                                  handleFilterCheckboxChange(group);
                                }}
                              />
                              <span className="!text-primary-dark text-sm font-semibold">
                                {group}
                              </span>
                            </ListSubheader>
                          </div>
                        );
                      })
                    ) : (
                      <h3 className="py-10 text-center text-warmGray-700 font-semibold">
                        No Topics
                      </h3>
                    )}
                  </Select>
                </FormControl>
              </div>
            </div>
          </Collapse>
        </div>
        <div className="overflow-scroll scrollbar-none">
          <div className="p-3 md:p-6 !pt-0">
            <div className="flex flex-col sio-card-shadow !p-0 sio-table-card-md sio-card-sm">
              {filteredFileData && filteredFileData.length === 0 ? (
                <h2 className="flex items-center justify-center py-10">
                  No Uploaded files to show
                </h2>
              ) : (
                <table className="table-auto w-full sio-table sio-table-border text-left border-t">
                  <thead className="sio-table-thead sticky -top-[1px]">
                    <tr>
                      <th>
                        <Checkbox
                          checked={
                            filteredFileData &&
                            filteredFileData.every((file) =>
                              s3Key.includes(file.s3_key)
                            )
                          }
                          onChange={(e) => {
                            try {
                              handleSelectAllChange(
                                e.target.checked,
                                e.target.value
                              );
                            } catch (err) {
                              toast.error(
                                "Failed to select all recommendations",
                                {
                                  icon: (
                                    <SioIcon
                                      name="close"
                                      size={16}
                                      color="blue"
                                    />
                                  ),
                                }
                              );
                            }
                          }}
                        />
                      </th>
                      <th>Name & Uploaded Date</th>
                      <th>File Type</th>
                      <th>File Description</th>
                    </tr>
                  </thead>

                  <tbody className="sio-table-tbody">
                    {!filteredFileData ? (
                      <>
                        {Array.from({ length: 7 }).map((_, index) => (
                          <tr key={index}>
                            {Array.from({ length: 4 }).map((_, ind) => (
                              <td key={ind}>
                                {ind === 0 ? (
                                  <Skeleton
                                    variant="text"
                                    sx={{ fontSize: "2rem", width: "50%" }}
                                  />
                                ) : (
                                  <Skeleton
                                    variant="text"
                                    sx={{ fontSize: "2rem" }}
                                  />
                                )}
                              </td>
                            ))}
                          </tr>
                        ))}
                      </>
                    ) : (
                      filteredFileData.map((file, fileIndex) => (
                        <tr key={file.id} className="relative">
                          <td className="absolute top-0 right-0 lg:relative">
                            <Checkbox
                              checked={s3Key.includes(file.s3_key) || false}
                              onChange={() => handleCheckboxChange(file)}
                            />
                          </td>
                          <td className="max-sm:w-full md:w-1/2 lg:w-1/4 me-8 md:me-0">
                            <p className="text-primary-dark text-sm font-bold break-all">
                              {file.original_file_name}
                              {file.new === true && (
                                <span className="sio-badge sio-badge-warning text-[10px] rounded ms-2">
                                  NEW
                                </span>
                              )}
                            </p>
                            <p className="text-secondary-dark text-[13px] font-normal">
                              Uploaded on {calculateTime(file.created_at)}
                            </p>
                            <ul className="sio-list-divide text-[10px] mt-3 lg:mt-4">
                              <li
                                className="sio-list-item"
                                onClick={async () => {
                                  try {
                                    setLoadingViewFileData(true);
                                    const data = await viewFile(
                                      file?.s3_key,
                                      file?.original_file_name
                                    );
                                    // const res = await fetch(data);
                                    // const csvTextData = await res.text();
                                    const csvTextData = await fetchWithTimeout(
                                      data,
                                      30000
                                    );

                                    // Open the new tab
                                    const url =
                                      window.location.origin + "/csv-viewer";
                                    const newTab = window.open(url, "_blank");
                                    if (!newTab) {
                                      throw new Error("Failed to open new tab");
                                    }

                                    Papa.parse(csvTextData, {
                                      header: true,
                                      complete: async (result) => {
                                        const parsedData = result.data;
                                        const columnHeaders =
                                          result.meta.fields;
                                        const newTabData = {
                                          data: parsedData.map(Object.values),
                                          columns: columnHeaders,
                                          fileName: file?.original_file_name,
                                        };

                                        // Function to send data to the new tab
                                        const sendData = () => {
                                          return new Promise(
                                            (resolve, reject) => {
                                              try {
                                                newTab.postMessage(
                                                  newTabData,
                                                  window.location.origin
                                                );
                                                resolve();
                                              } catch (error) {
                                                console.error(
                                                  "Error sending data:",
                                                  error
                                                );
                                                reject(error);
                                              }
                                            }
                                          );
                                        };

                                        // Retry mechanism with exponential backoff
                                        const maxAttempts = 10;
                                        let attempts = 0;
                                        const attemptSend = async () => {
                                          if (
                                            attempts >= maxAttempts ||
                                            newTab.closed
                                          ) {
                                            console.error(
                                              "Failed to send data after maximum attempts or tab was closed"
                                            );
                                            setLoadingViewFileData(false);
                                            return;
                                          }

                                          try {
                                            await sendData();
                                            attempts++;
                                            setTimeout(
                                              attemptSend,
                                              Math.min(
                                                500 * Math.pow(2, attempts),
                                                5000
                                              )
                                            ); // Exponential backoff
                                          } catch (error) {
                                            attempts++;
                                            setTimeout(
                                              attemptSend,
                                              Math.min(
                                                500 * Math.pow(2, attempts),
                                                5000
                                              )
                                            ); // Retry on failure
                                          }
                                        };

                                        // Start sending attempts
                                        setTimeout(attemptSend, 500); // Initial delay to let the new tab load
                                      },
                                      error: (error) => {
                                        console.error(
                                          "Error parsing file:",
                                          error
                                        );
                                        setLoadingViewFileData(false);
                                      },
                                    });
                                  } catch (error) {
                                    console.error(
                                      "Error processing file:",
                                      error
                                    );

                                    setOpenFileDownloadModal(true);
                                    setSelectedFileData(file);
                                    toast.error(`Error: ${error.message}`, {
                                      icon: (
                                        <SioIcon
                                          name="close"
                                          size={16}
                                          color="blue"
                                        />
                                      ),
                                    });
                                    setLoadingViewFileData(false);
                                  } finally {
                                    setLoadingViewFileData(false);
                                  }
                                }}
                              >
                                <Link className="sio-link-icon">
                                  <SioIcon name="view" size={14} />
                                  View
                                </Link>
                              </li>

                              <li className="sio-list-item">
                                <Link
                                  className="sio-link-icon"
                                  onClick={async () => {
                                    try {
                                      await archiveTheFile(file?.s3_key);
                                      toast.success(
                                        "File was successfully archived",
                                        {
                                          icon: (
                                            <SioIcon
                                              name="check"
                                              size={16}
                                              color="blue"
                                            />
                                          ),
                                        }
                                      );

                                      setFilteredFileData((prevFile) => {
                                        const filterArchiveData =
                                          prevFile.filter(
                                            (item) =>
                                              item.s3_key !== file.s3_key
                                          );
                                        setDataSource(filterArchiveData);

                                        // const s3KeysData =
                                        //   filterArchiveData.map(
                                        //     (file) => file.s3_key
                                        //   );
                                        const getS3KeysList = JSON.parse(
                                          sessionStorage.getItem("s3_keys")
                                        );

                                        const archiveds3KeysData = JSON.parse(
                                          sessionStorage.getItem(
                                            "archivedS3Keys"
                                          ) || "[]"
                                        );

                                        const archiveds3Keys = [
                                          ...archiveds3KeysData,
                                        ];

                                        archiveds3Keys.push(
                                          ...getS3KeysList.filter(
                                            (s3key) => s3key === file.s3_key
                                          )
                                        );

                                        sessionStorage.setItem(
                                          "archivedS3Keys",
                                          JSON.stringify(archiveds3Keys)
                                        );

                                        const removedArchiveds3Key =
                                          getS3KeysList.filter(
                                            (s3key) => s3key !== file.s3_key
                                          );

                                        // setS3Key(s3KeysData);
                                        sessionStorage.setItem(
                                          "s3_keys",
                                          JSON.stringify(removedArchiveds3Key)
                                        );
                                        return filterArchiveData;
                                      });
                                    } catch (e) {
                                      toast.error(e.message);
                                    }
                                  }}
                                >
                                  <SioIcon name="archive" size={13} /> Archive
                                </Link>
                              </li>
                              {isDeleteBtnEnabled && (
                                <li className="sio-list-item">
                                  <Link
                                    className="sio-link-icon text-red-800"
                                    onClick={() => {
                                      handleRemoveModalClickOpen(file);
                                    }}
                                  >
                                    <SioIcon name="delete" size={13} />
                                    Remove
                                  </Link>
                                </li>
                              )}
                            </ul>
                          </td>
                          <td
                            className="max-sm:w-full md:w-1/2 md:!pe-10 lg:!pe-0 lg:w-1/4"
                            data-title="Topics"
                          >
                            <FormControl
                              fullWidth
                              size="small"
                              className="flex"
                            >
                              <Select
                                key={file.id}
                                multiple
                                value={
                                  (selectedTopics && selectedTopics[file.id]) ||
                                  []
                                }
                                open={dropdownOpen === file.id}
                                onClose={() => setDropdownOpen(null)}
                                onOpen={() => setDropdownOpen(file.id)}
                                input={
                                  <OutlinedInput
                                    id={`select-${file.id}`}
                                    className="sio-input-border-none sio-input-multiple-group sio-input-wrap sio-input-xs"
                                  />
                                }
                                renderValue={(selected = []) =>
                                  selected.length > 0
                                    ? selected.map((val) => `${val}`).join(", ")
                                    : "Select Options"
                                }
                                sx={{
                                  "& .MuiSelect-icon": {
                                    color: "#5F6368",
                                  },
                                }}
                                MenuProps={{
                                  PaperProps: { style: { width: "auto" } },
                                  MenuListProps: {
                                    className:
                                      "sio-menu-check-list check-list-group",
                                  },
                                  anchorOrigin: {
                                    vertical: "bottom",
                                    horizontal: "left",
                                  },
                                  transformOrigin: {
                                    vertical: "top",
                                    horizontal: "left",
                                  },
                                }}
                                inputProps={{ "aria-label": "Without label" }}
                              >
                                {allOptions.map((group) => (
                                  <div
                                    className="menu-item-group"
                                    key={group.group}
                                  >
                                    <ListSubheader
                                      component="div"
                                      className="flex items-center"
                                    >
                                      <Radio
                                        checked={
                                          (selectedTopics &&
                                            selectedTopics[file.id]?.includes(
                                              group.group
                                            )) ||
                                          false
                                        }
                                        onChange={async () => {
                                          setDropdownOpen(null);
                                          setLoadingViewFileData(true);
                                          handleTopicChange(file.id, group);
                                          const data = await updateTopic(
                                            file.s3_key,
                                            group.group
                                          );

                                          setFilesBasedSubTopics((prevData) => {
                                            const updatedData = prevData
                                              .map((item) => {
                                                if (item.group === file.topic) {
                                                  const filteredOptions =
                                                    item.options.filter(
                                                      (option) =>
                                                        option !==
                                                        file.sub_topic
                                                    );
                                                  if (
                                                    filteredOptions.length > 0
                                                  ) {
                                                    return {
                                                      ...item,
                                                      options: filteredOptions,
                                                    };
                                                  }
                                                  return null;
                                                }
                                                return item;
                                              })
                                              .filter((item) => item !== null);

                                            const existingGroup = prevData.find(
                                              (item) =>
                                                item.group === data.new_topic
                                            );

                                            if (existingGroup) {
                                              if (
                                                !existingGroup.options.includes(
                                                  data.new_sub_topic
                                                )
                                              ) {
                                                existingGroup.options.push(
                                                  data.new_sub_topic
                                                );
                                              }
                                              return [...updatedData];
                                            } else {
                                              return [
                                                ...updatedData,
                                                {
                                                  group: data.new_topic,
                                                  options: [data.new_sub_topic],
                                                },
                                              ];
                                            }
                                          });

                                          setLoadingViewFileData(false);
                                        }}
                                      />
                                      <span className="sio-check-label flex pl-5 text-secondary-dark">
                                        {group.group}
                                      </span>
                                    </ListSubheader>
                                  </div>
                                ))}
                              </Select>
                            </FormControl>
                          </td>
                          <td data-title="File Description">
                            <p className="text-xs text-secondary-dark line-clamp-3 break-all mb-2">
                              {file.file_type?.description}
                            </p>
                            <Link
                              className="sio-link text-[10px] text-primary-900"
                              onClick={toggleFileDescriptionDrawer(true, file)}
                            >
                              View More
                            </Link>
                          </td>
                        </tr>
                      ))
                    )}
                  </tbody>
                </table>
              )}

              {/* File Description Sidebar */}
              <Drawer
                anchor={"right"}
                open={openFileDescriptionDrawer}
                onClose={toggleFileDescriptionDrawer(false)}
              >
                {selectedFileData && (
                  <div className="sio-sidebar-right w-[100%] md:w-[500px]">
                    <div className="sio-sidebar-header flex items-start">
                      <div className="flex items-start me-4">
                        <svg
                          className="size-10 me-5 mt-1"
                          viewBox="0 0 17 17"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M5.59836 0H11.3551L16.4375 5.0824V14.844C16.4375 16.0303 15.4678 17 14.2815 17H5.59836C4.41205 17 3.44238 16.0303 3.44238 14.844V2.15598C3.44238 0.969631 4.41201 0 5.59836 0Z"
                            fill="#00733B"
                          />
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M11.355 0L16.4374 5.0824H11.903C11.6008 5.0824 11.355 4.8366 11.355 4.53442V0Z"
                            fill="#005C2F"
                          />
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M1.03568 7.23828H12.7444C13.0047 7.23828 13.2175 7.45105 13.2175 7.71146V12.0069C13.2175 12.2673 13.0048 12.481 12.7444 12.481H1.03568C0.775299 12.481 0.5625 12.2673 0.5625 12.0069V7.71146C0.5625 7.45105 0.775266 7.23828 1.03568 7.23828Z"
                            fill="#005C2F"
                          />
                          <path
                            d="M3.43106 9.27296V10.4466C3.43106 10.7822 3.70412 11.0553 4.03977 11.0553C4.37539 11.0553 4.64845 10.7822 4.64845 10.4466C4.64845 10.295 4.77134 10.1721 4.92291 10.1721C5.07452 10.1721 5.19737 10.295 5.19737 10.4466C5.19737 11.085 4.67807 11.6043 4.03974 11.6043C3.40141 11.6043 2.88208 11.0849 2.88208 10.4466V9.27296C2.88208 8.63463 3.40141 8.11533 4.03974 8.11533C4.67807 8.11533 5.19737 8.63466 5.19737 9.27296C5.19737 9.42457 5.07448 9.54745 4.92291 9.54745C4.7713 9.54745 4.64845 9.42457 4.64845 9.27296C4.64845 8.93734 4.37539 8.66428 4.03977 8.66428C3.70412 8.66428 3.43106 8.93734 3.43106 9.27296ZM6.68058 8.66428H7.1178C7.29434 8.66428 7.43795 8.80788 7.43795 8.98443C7.43795 9.13603 7.56083 9.25888 7.7124 9.25888C7.86401 9.25888 7.98686 9.136 7.98686 8.98443C7.98686 8.5052 7.59699 8.11533 7.11777 8.11533H6.68055C6.12367 8.11533 5.67058 8.56799 5.67058 9.12431C5.67058 9.61675 5.92359 9.85694 6.13582 9.97175C6.40513 10.1174 6.7448 10.1342 6.97686 10.1342C7.23106 10.1342 7.43788 10.3406 7.43788 10.5943C7.43788 10.8485 7.23106 11.0553 6.97686 11.0553H6.53964C6.36313 11.0553 6.21949 10.9117 6.21949 10.7352C6.21949 10.5836 6.09661 10.4607 5.94504 10.4607C5.79343 10.4607 5.67058 10.5836 5.67058 10.7352C5.67058 11.2144 6.06045 11.6043 6.53967 11.6043H6.97689C7.53377 11.6043 7.98686 11.1512 7.98686 10.5943C7.98686 10.0379 7.53377 9.5853 6.97689 9.5853C6.27654 9.5853 6.21956 9.37649 6.21956 9.12428C6.21956 8.87067 6.42635 8.66428 6.68058 8.66428ZM10.7098 8.12931C10.566 8.0815 10.4106 8.15943 10.3628 8.30326L9.64685 10.459L8.93089 8.30326C8.88311 8.15943 8.72782 8.08157 8.58389 8.12931C8.44002 8.17709 8.36212 8.33245 8.4099 8.47632L9.38637 11.4163C9.42366 11.5285 9.52858 11.6043 9.64685 11.6043C9.76512 11.6043 9.87008 11.5285 9.90733 11.4163L10.8838 8.47632C10.9315 8.33245 10.8537 8.17709 10.7098 8.12931Z"
                            fill="white"
                          />
                        </svg>
                        <div>
                          <h2 className="text-base md:text-xl break-all">
                            {selectedFileData?.original_file_name}
                          </h2>
                          <span className="text-secondary-dark text-sm">
                            Uploaded on{" "}
                            <strong>
                              {calculateTime(selectedFileData?.created_at)}
                            </strong>
                          </span>
                        </div>
                      </div>
                      <Button
                        className="soi-btn-fab soi-btn-fab soi-btn-flat btn-lg ms-auto"
                        onClick={toggleFileDescriptionDrawer(false)}
                      >
                        <SioIcon name="closeLight" size={24} />
                      </Button>
                    </div>
                    <div className="sio-sidebar-body h-auto p-4 md:p-6 py-4 md:!py-7">
                      <p className="text-secondary-dark font-medium text-sm md:text-[15px]">
                        {selectedFileData?.file_type &&
                          selectedFileData?.file_type?.description}
                      </p>
                    </div>
                    {isDownloadBtnEnabled && (
                      <div className="sio-sidebar-footer gap-4 flex pt-0">
                        <Button
                          variant="secondary"
                          size={"sm"}
                          onClick={() =>
                            downloadFile(
                              selectedFileData?.s3_key,
                              selectedFileData?.original_file_name
                            )
                          }
                        >
                          Download File
                        </Button>
                      </div>
                    )}
                  </div>
                )}
              </Drawer>

              {/* Upload Data File Sidebar */}
              <Drawer
                anchor={"right"}
                open={openUploadDataFileDrawer}
                onClose={toggleUploadDataFileDrawer(false)}
              >
                <div className="sio-sidebar-right w-[100%] md:w-[500px]">
                  <div className="sio-sidebar-header flex items-center">
                    <h2 className="text-base md:text-xl">Upload Data File</h2>
                    <Button
                      className="soi-btn-fab sio-sidebar-close soi-btn-fab soi-btn-flat btn-lg ms-auto"
                      onClick={toggleUploadDataFileDrawer(false)}
                    >
                      <SioIcon name="closeLight" size={24} />
                    </Button>
                  </div>
                  {isLoading ? (
                    <div className="flex justify-center items-center text-md font-semibold w-full h-full pt-10">
                      <svg width={0} height={0}>
                        <defs>
                          <linearGradient
                            id="my_gradient"
                            x1="0%"
                            y1="0%"
                            x2="0%"
                            y2="100%"
                          >
                            <stop offset="0%" stopColor="#e01cd5" />
                            <stop offset="100%" stopColor="#1CB5E0" />
                          </linearGradient>
                        </defs>
                      </svg>
                      <CircularProgress
                        sx={{
                          "svg circle": { stroke: "url(#my_gradient)" },
                          size: "20px",
                        }}
                      />
                    </div>
                  ) : (
                    <>
                      <div className="sio-sidebar-body h-auto p-4 md:p-6 py-4 md:!py-7">
                        <div
                          className={`sio-upload-container ${
                            isDragOver ? "drag-over" : ""
                          }`}
                          onDragOver={handleDragOver}
                          onDragLeave={handleDragLeave}
                          onDrop={handleDrop}
                          onClick={handleClick}
                        >
                          <input
                            type="file"
                            ref={fileInputRef}
                            multiple
                            onChange={handleFileSelect}
                            accept=".xls,.xlsx,.csv,.pdf,.filepart"
                            style={{ display: "none" }}
                            aria-label="Upload file"
                          />
                          <div className="sio-upload-info">
                            <SioIcon name="upload" className="mb-3" size={34} />
                            <p className="text-secondary-dark text-sm md:text-base">
                              Drag & Drop or Choose File
                            </p>
                            <p className="text-xs md:text-xs text-muted-dark">
                              Max. file size: 30 MB
                            </p>
                          </div>
                        </div>
                        <p className="text-xs text-muted-dark mt-2">
                          Supported Format: xls, xlsx, csv, pdf
                        </p>

                        {errorMessage && (
                          <div className="error-message text-red-500">
                            {errorMessage}
                          </div>
                        )}
                        <div className="max-h-80 overflow-y-auto custom-scroll">
                          {selectedFile &&
                            selectedFile.map((file) => (
                              <div className="flex rounded-lg border p-3 mt-6">
                                <div className="flex items-start me-4">
                                  <svg
                                    className="size-8 me-4 mt-1"
                                    viewBox="0 0 17 17"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      fillRule="evenodd"
                                      clipRule="evenodd"
                                      d="M5.59836 0H11.3551L16.4375 5.0824V14.844C16.4375 16.0303 15.4678 17 14.2815 17H5.59836C4.41205 17 3.44238 16.0303 3.44238 14.844V2.15598C3.44238 0.969631 4.41201 0 5.59836 0Z"
                                      fill="#00733B"
                                    />
                                    <path
                                      fillRule="evenodd"
                                      clipRule="evenodd"
                                      d="M11.355 0L16.4374 5.0824H11.903C11.6008 5.0824 11.355 4.8366 11.355 4.53442V0Z"
                                      fill="#005C2F"
                                    />
                                    <path
                                      fillRule="evenodd"
                                      clipRule="evenodd"
                                      d="M1.03568 7.23828H12.7444C13.0047 7.23828 13.2175 7.45105 13.2175 7.71146V12.0069C13.2175 12.2673 13.0048 12.481 12.7444 12.481H1.03568C0.775299 12.481 0.5625 12.2673 0.5625 12.0069V7.71146C0.5625 7.45105 0.775266 7.23828 1.03568 7.23828Z"
                                      fill="#005C2F"
                                    />
                                    <path
                                      d="M3.43106 9.27296V10.4466C3.43106 10.7822 3.70412 11.0553 4.03977 11.0553C4.37539 11.0553 4.64845 10.7822 4.64845 10.4466C4.64845 10.295 4.77134 10.1721 4.92291 10.1721C5.07452 10.1721 5.19737 10.295 5.19737 10.4466C5.19737 11.085 4.67807 11.6043 4.03974 11.6043C3.40141 11.6043 2.88208 11.0849 2.88208 10.4466V9.27296C2.88208 8.63463 3.40141 8.11533 4.03974 8.11533C4.67807 8.11533 5.19737 8.63466 5.19737 9.27296C5.19737 9.42457 5.07448 9.54745 4.92291 9.54745C4.7713 9.54745 4.64845 9.42457 4.64845 9.27296C4.64845 8.93734 4.37539 8.66428 4.03977 8.66428C3.70412 8.66428 3.43106 8.93734 3.43106 9.27296ZM6.68058 8.66428H7.1178C7.29434 8.66428 7.43795 8.80788 7.43795 8.98443C7.43795 9.13603 7.56083 9.25888 7.7124 9.25888C7.86401 9.25888 7.98686 9.136 7.98686 8.98443C7.98686 8.5052 7.59699 8.11533 7.11777 8.11533H6.68055C6.12367 8.11533 5.67058 8.56799 5.67058 9.12431C5.67058 9.61675 5.92359 9.85694 6.13582 9.97175C6.40513 10.1174 6.7448 10.1342 6.97686 10.1342C7.23106 10.1342 7.43788 10.3406 7.43788 10.5943C7.43788 10.8485 7.23106 11.0553 6.97686 11.0553H6.53964C6.36313 11.0553 6.21949 10.9117 6.21949 10.7352C6.21949 10.5836 6.09661 10.4607 5.94504 10.4607C5.79343 10.4607 5.67058 10.5836 5.67058 10.7352C5.67058 11.2144 6.06045 11.6043 6.53967 11.6043H6.97689C7.53377 11.6043 7.98686 11.1512 7.98686 10.5943C7.98686 10.0379 7.53377 9.5853 6.97689 9.5853C6.27654 9.5853 6.21956 9.37649 6.21956 9.12428C6.21956 8.87067 6.42635 8.66428 6.68058 8.66428ZM10.7098 8.12931C10.566 8.0815 10.4106 8.15943 10.3628 8.30326L9.64685 10.459L8.93089 8.30326C8.88311 8.15943 8.72782 8.08157 8.58389 8.12931C8.44002 8.17709 8.36212 8.33245 8.4099 8.47632L9.38637 11.4163C9.42366 11.5285 9.52858 11.6043 9.64685 11.6043C9.76512 11.6043 9.87008 11.5285 9.90733 11.4163L10.8838 8.47632C10.9315 8.33245 10.8537 8.17709 10.7098 8.12931Z"
                                      fill="white"
                                    />
                                  </svg>
                                  <div className="overflow-hidden">
                                    {/* <h6 className="font-manrope text-sm text-primary-dark mb-0">
                                    {file.name}
                                  </h6>
                                  <p className="text-muted-dark text-xs">
                                    {(file.size / 1024 / 1024).toFixed(2)} MB
                                  </p> */}
                                    <h6 className="flex-col font-manrope text-sm text-primary-dark mb-0 flex justify-start w-80">
                                      <div>
                                        <span className="truncate">
                                          {file?.name.split(".")[0]}
                                        </span>{" "}
                                        <span className="shrink-0">
                                          .{file?.name.split(".")[1]}
                                        </span>
                                      </div>
                                      <p className="text-muted-dark text-xs">
                                        {file.size >= 1000 * 1000
                                          ? (file.size / 1000 / 1000).toFixed(
                                              2
                                            ) + "MB"
                                          : (file.size / 1000).toFixed(2) +
                                            "KB"}
                                      </p>
                                    </h6>
                                  </div>
                                </div>
                                <Button
                                  className="soi-btn-fab soi-btn-flat btn-lg ms-auto -me-2 !size-8 md:!size-10"
                                  onClick={() => handleRemoveFile(file)}
                                  aria-label="Remove file"
                                >
                                  <SioIcon name="closeLight" size={24} />
                                </Button>
                              </div>
                            ))}
                        </div>
                      </div>
                      <div className="sio-sidebar-footer gap-4 flex pt-0">
                        <Button
                          variant="secondary"
                          size={"sm"}
                          onClick={() => {
                            handleFileUpload();
                          }}
                        >
                          Upload
                        </Button>
                        <Button
                          variant="default"
                          size={"sm"}
                          onClick={toggleUploadDataFileDrawer(false)}
                        >
                          Cancel
                        </Button>
                      </div>
                    </>
                  )}
                </div>
              </Drawer>

              {/* Add New Data Label Modal */}
              <Dialog
                open={openAddDataLabelModal}
                onClose={handleAddDataLabelModalClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <div className="sio-modal">
                  <div className="sio-modal-header">
                    <h1 className="text-primary-dark text-lg md:text-xl flex items-center">
                      <SioIcon
                        name="add"
                        className="text-secondary-800"
                        size={20}
                      />
                      <span className="ms-2">Add New Data Label</span>
                    </h1>
                  </div>
                  <div className="sio-modal-body">
                    <TextField
                      placeholder="For eg. “Student Performance Data”"
                      fullWidth
                    />
                  </div>
                  <div className="sio-modal-footer">
                    <Button
                      variant="secondary"
                      onClick={handleAddDataLabelModalClose}
                      size={"sm"}
                    >
                      Save
                    </Button>
                    <Button
                      variant="default"
                      size={"sm"}
                      onClick={handleAddDataLabelModalClose}
                    >
                      {" "}
                      Cancel{" "}
                    </Button>
                  </div>
                </div>
              </Dialog>
              {/* Add New Data Label Modal */}
              <Dialog
                open={openRemoveModal}
                onClose={handleRemoveModalClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <div className="sio-modal">
                  <div className="sio-modal-body">
                    <div className="flex justify-center items-center text-center flex-col mb-3">
                      <SioIcon
                        name="deleteLight"
                        className="mb-5 size-14 md:size-20"
                        size={80}
                      />
                      <h1 className="text-primary-dark text-lg md:text-xl mb-0">
                        Are you sure you want to remove this file?
                      </h1>
                    </div>
                    <div className="rounded-lg border p-3 mb-6">
                      <div className="flex items-center">
                        <span className="shrink-0">
                          <svg
                            className="size-8 me-3"
                            viewBox="0 0 17 17"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M5.59836 0H11.3551L16.4375 5.0824V14.844C16.4375 16.0303 15.4678 17 14.2815 17H5.59836C4.41205 17 3.44238 16.0303 3.44238 14.844V2.15598C3.44238 0.969631 4.41201 0 5.59836 0Z"
                              fill="#00733B"
                            />
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M11.355 0L16.4374 5.0824H11.903C11.6008 5.0824 11.355 4.8366 11.355 4.53442V0Z"
                              fill="#005C2F"
                            />
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M1.03568 7.23828H12.7444C13.0047 7.23828 13.2175 7.45105 13.2175 7.71146V12.0069C13.2175 12.2673 13.0048 12.481 12.7444 12.481H1.03568C0.775299 12.481 0.5625 12.2673 0.5625 12.0069V7.71146C0.5625 7.45105 0.775266 7.23828 1.03568 7.23828Z"
                              fill="#005C2F"
                            />
                            <path
                              d="M3.43106 9.27296V10.4466C3.43106 10.7822 3.70412 11.0553 4.03977 11.0553C4.37539 11.0553 4.64845 10.7822 4.64845 10.4466C4.64845 10.295 4.77134 10.1721 4.92291 10.1721C5.07452 10.1721 5.19737 10.295 5.19737 10.4466C5.19737 11.085 4.67807 11.6043 4.03974 11.6043C3.40141 11.6043 2.88208 11.0849 2.88208 10.4466V9.27296C2.88208 8.63463 3.40141 8.11533 4.03974 8.11533C4.67807 8.11533 5.19737 8.63466 5.19737 9.27296C5.19737 9.42457 5.07448 9.54745 4.92291 9.54745C4.7713 9.54745 4.64845 9.42457 4.64845 9.27296C4.64845 8.93734 4.37539 8.66428 4.03977 8.66428C3.70412 8.66428 3.43106 8.93734 3.43106 9.27296ZM6.68058 8.66428H7.1178C7.29434 8.66428 7.43795 8.80788 7.43795 8.98443C7.43795 9.13603 7.56083 9.25888 7.7124 9.25888C7.86401 9.25888 7.98686 9.136 7.98686 8.98443C7.98686 8.5052 7.59699 8.11533 7.11777 8.11533H6.68055C6.12367 8.11533 5.67058 8.56799 5.67058 9.12431C5.67058 9.61675 5.92359 9.85694 6.13582 9.97175C6.40513 10.1174 6.7448 10.1342 6.97686 10.1342C7.23106 10.1342 7.43788 10.3406 7.43788 10.5943C7.43788 10.8485 7.23106 11.0553 6.97686 11.0553H6.53964C6.36313 11.0553 6.21949 10.9117 6.21949 10.7352C6.21949 10.5836 6.09661 10.4607 5.94504 10.4607C5.79343 10.4607 5.67058 10.5836 5.67058 10.7352C5.67058 11.2144 6.06045 11.6043 6.53967 11.6043H6.97689C7.53377 11.6043 7.98686 11.1512 7.98686 10.5943C7.98686 10.0379 7.53377 9.5853 6.97689 9.5853C6.27654 9.5853 6.21956 9.37649 6.21956 9.12428C6.21956 8.87067 6.42635 8.66428 6.68058 8.66428ZM10.7098 8.12931C10.566 8.0815 10.4106 8.15943 10.3628 8.30326L9.64685 10.459L8.93089 8.30326C8.88311 8.15943 8.72782 8.08157 8.58389 8.12931C8.44002 8.17709 8.36212 8.33245 8.4099 8.47632L9.38637 11.4163C9.42366 11.5285 9.52858 11.6043 9.64685 11.6043C9.76512 11.6043 9.87008 11.5285 9.90733 11.4163L10.8838 8.47632C10.9315 8.33245 10.8537 8.17709 10.7098 8.12931Z"
                              fill="white"
                            />
                          </svg>
                        </span>
                        <div className="grow overflow-hidden">
                          <h6 className="font-manrope text-sm text-primary-dark mb-0 flex justify-start">
                            <span className="truncate">
                              {selectedFileData?.original_file_name &&
                                selectedFileData?.original_file_name.split(
                                  "."
                                )[0]}
                            </span>{" "}
                            <span className="shrink-0">
                              .
                              {selectedFileData?.original_file_name &&
                                selectedFileData?.original_file_name.split(
                                  "."
                                )[1]}
                            </span>
                          </h6>
                          <p className="text-muted-dark text-xs">
                            {/* {(selectedFileData.size / 1024 / 1024).toFixed(2)} MB */}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="sio-modal-footer justify-center">
                    <Button
                      variant="secondary"
                      onClick={async () => {
                        handleRemoveModalClose();
                        setFilteredFileData((prevArray) => {
                          return [
                            ...prevArray.filter(
                              (file) => file.s3_key !== selectedFileData.s3_key
                            ),
                          ];
                        });
                        await deleteFile([selectedFileData.s3_key]);
                        const getStoredS3keysList = JSON.parse(
                          sessionStorage.getItem("s3_keys")
                        ).filter((key) => key !== selectedFileData.s3_key);

                        sessionStorage.setItem(
                          "s3_keys",
                          JSON.stringify(getStoredS3keysList)
                        );
                        setS3Key(getStoredS3keysList);
                      }}
                      size={"sm"}
                    >
                      Yes, Remove
                    </Button>
                    <Button
                      variant="default"
                      size={"sm"}
                      onClick={handleRemoveModalClose}
                    >
                      Not Now
                    </Button>
                  </div>
                </div>
              </Dialog>

              <Dialog
                open={openFileDownloadModal}
                onClose={handleFileDownloadModalClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <div className="sio-modal">
                  <div className="sio-modal-body">
                    <div className="flex justify-center items-center text-center flex-col mb-3">
                      <SioIcon name="download" size={80} />
                      <h1 className="text-primary-dark text-lg md:text-xl mb-0 pt-5">
                        Unable to open, click below to download
                      </h1>
                    </div>
                    <div className="rounded-lg border p-3 mb-6">
                      <div className="flex items-center">
                        <span className="shrink-0">
                          <svg
                            className="size-8 me-3"
                            viewBox="0 0 17 17"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M5.59836 0H11.3551L16.4375 5.0824V14.844C16.4375 16.0303 15.4678 17 14.2815 17H5.59836C4.41205 17 3.44238 16.0303 3.44238 14.844V2.15598C3.44238 0.969631 4.41201 0 5.59836 0Z"
                              fill="#00733B"
                            />
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M11.355 0L16.4374 5.0824H11.903C11.6008 5.0824 11.355 4.8366 11.355 4.53442V0Z"
                              fill="#005C2F"
                            />
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M1.03568 7.23828H12.7444C13.0047 7.23828 13.2175 7.45105 13.2175 7.71146V12.0069C13.2175 12.2673 13.0048 12.481 12.7444 12.481H1.03568C0.775299 12.481 0.5625 12.2673 0.5625 12.0069V7.71146C0.5625 7.45105 0.775266 7.23828 1.03568 7.23828Z"
                              fill="#005C2F"
                            />
                            <path
                              d="M3.43106 9.27296V10.4466C3.43106 10.7822 3.70412 11.0553 4.03977 11.0553C4.37539 11.0553 4.64845 10.7822 4.64845 10.4466C4.64845 10.295 4.77134 10.1721 4.92291 10.1721C5.07452 10.1721 5.19737 10.295 5.19737 10.4466C5.19737 11.085 4.67807 11.6043 4.03974 11.6043C3.40141 11.6043 2.88208 11.0849 2.88208 10.4466V9.27296C2.88208 8.63463 3.40141 8.11533 4.03974 8.11533C4.67807 8.11533 5.19737 8.63466 5.19737 9.27296C5.19737 9.42457 5.07448 9.54745 4.92291 9.54745C4.7713 9.54745 4.64845 9.42457 4.64845 9.27296C4.64845 8.93734 4.37539 8.66428 4.03977 8.66428C3.70412 8.66428 3.43106 8.93734 3.43106 9.27296ZM6.68058 8.66428H7.1178C7.29434 8.66428 7.43795 8.80788 7.43795 8.98443C7.43795 9.13603 7.56083 9.25888 7.7124 9.25888C7.86401 9.25888 7.98686 9.136 7.98686 8.98443C7.98686 8.5052 7.59699 8.11533 7.11777 8.11533H6.68055C6.12367 8.11533 5.67058 8.56799 5.67058 9.12431C5.67058 9.61675 5.92359 9.85694 6.13582 9.97175C6.40513 10.1174 6.7448 10.1342 6.97686 10.1342C7.23106 10.1342 7.43788 10.3406 7.43788 10.5943C7.43788 10.8485 7.23106 11.0553 6.97686 11.0553H6.53964C6.36313 11.0553 6.21949 10.9117 6.21949 10.7352C6.21949 10.5836 6.09661 10.4607 5.94504 10.4607C5.79343 10.4607 5.67058 10.5836 5.67058 10.7352C5.67058 11.2144 6.06045 11.6043 6.53967 11.6043H6.97689C7.53377 11.6043 7.98686 11.1512 7.98686 10.5943C7.98686 10.0379 7.53377 9.5853 6.97689 9.5853C6.27654 9.5853 6.21956 9.37649 6.21956 9.12428C6.21956 8.87067 6.42635 8.66428 6.68058 8.66428ZM10.7098 8.12931C10.566 8.0815 10.4106 8.15943 10.3628 8.30326L9.64685 10.459L8.93089 8.30326C8.88311 8.15943 8.72782 8.08157 8.58389 8.12931C8.44002 8.17709 8.36212 8.33245 8.4099 8.47632L9.38637 11.4163C9.42366 11.5285 9.52858 11.6043 9.64685 11.6043C9.76512 11.6043 9.87008 11.5285 9.90733 11.4163L10.8838 8.47632C10.9315 8.33245 10.8537 8.17709 10.7098 8.12931Z"
                              fill="white"
                            />
                          </svg>
                        </span>
                        <div className="grow overflow-hidden">
                          <h6 className="font-manrope text-sm text-primary-dark mb-0 flex justify-start">
                            <span className="truncate">
                              {selectedFileData?.original_file_name &&
                                selectedFileData?.original_file_name.split(
                                  "."
                                )[0]}
                            </span>{" "}
                            <span className="shrink-0">
                              .
                              {selectedFileData?.original_file_name &&
                                selectedFileData?.original_file_name.split(
                                  "."
                                )[1]}
                            </span>
                          </h6>
                          <p className="text-muted-dark text-xs">
                            {/* {(selectedFileData.size / 1024 / 1024).toFixed(2)} MB */}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="sio-modal-footer justify-center">
                    <Button
                      variant="secondary"
                      onClick={async () => {
                        downloadFile(
                          selectedFileData?.s3_key,
                          selectedFileData?.original_file_name
                        );
                        setOpenFileDownloadModal(false);
                      }}
                      size={"sm"}
                    >
                      Download
                    </Button>
                    {/* <Button
                      variant="default"
                      size={"sm"}
                      onClick={handleRemoveModalClose}
                    >
                      Not Now
                    </Button> */}
                  </div>
                </div>
              </Dialog>
            </div>
            <ContextFiles dataSource={contextFilesList} />
          </div>
        </div>
      </>
    </>
  );
};

export default FilesUploaded;
