import { CircularProgress, Tooltip } from "@mui/material";
import React, { useRef } from "react";
import logoicon from "../../../assets/logo-black.png";
import SioIcon from "../../icons/IconLibrary";
import { generatePDF } from "../pdfUtils";
import MarkdownPdfComponent from "../ui/MarkdownPdfComponent";

const ResponsePDFGenerator = ({
  item,
  currentFileName,

  isRenderingDownLoadButton,
  setIsRenderingDownLoadButton,
  setIsLoadButton,
  isLoadingButton,
  previousData,
  isFromAskMePage,
  markDownData,
  category,
}) => {
  const pdfRef = useRef(null);

  // console.log(`Generating PDF ${isFromAskMePage}`);

  // console.log(`Generating PDF 2 ${JSON.stringify(previousData)}`);

  // console.log(`Generating PDF 3 ${previousData === undefined}`);

  return (
    <div id="my-id">
      <div
        ref={pdfRef}
        style={{
          display: !isRenderingDownLoadButton ? "none" : "block",
          position: "absolute",
          top: "-9999px",
          left: "-9999px",
          width: "794px",
          // padding: "40px",
          backgroundColor: "#ffffff",
          fontFamily: "'Roboto', Arial, sans-serif",
          fontSize: "14px",
          lineHeight: "1.8",
          letterSpacing: "0.9px",
          wordSpacing: "0.5px",
          color: "#000000",
        }}
      >
        <div className="flex items-center justify-center mb-10">
          <img className="h-12 mb-1" src={logoicon} alt="strived.io-logo" />
        </div>

        {isFromAskMePage && previousData === undefined ? (
          <div className="w-full flex justify-end mb-1">
            <div className="relative inline-block max-w-[60%] p-4 bg-[rgb(202,243,231)] rounded-lg hover:bg-[rgb(182,233,221)]">
              <p className="text-sm text-[rgba(17,17,17,0.74)]">
                {currentFileName}
              </p>
            </div>
          </div>
        ) : null}

        <MarkdownPdfComponent
          markDownContainerData={[
            previousData || { input: { content: currentFileName } },
            item,
          ]}
          filename={currentFileName}
        />
      </div>

      {!isRenderingDownLoadButton || isLoadingButton !== item.id ? (
        <Tooltip title="Download Response">
          <button
            className="sio-circle-icon bg-warning/20 size-8 shrink-0 text-secondary-dark
                     hover:bg-warning"
            onClick={() => {
              console.log(currentFileName, currentFileName.length);

              let fileName;
              const findIndex = markDownData.findIndex(
                (rec) => rec.id === item.id
              );
              if (findIndex >= 1) {
                fileName = markDownData[findIndex - 1].input?.content;
              } else {
                if (currentFileName.length === 0) {
                  fileName = category;
                } else {
                  fileName = currentFileName;
                }
              }
              setIsLoadButton(item.id);
              generatePDF(pdfRef, fileName, setIsRenderingDownLoadButton);
            }}
          >
            <SioIcon name="download" size={14} />
          </button>
        </Tooltip>
      ) : (
        <CircularProgress size="32px" color="success" />
      )}
    </div>
  );
};

export default ResponsePDFGenerator;
