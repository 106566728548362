import Cookies from "js-cookie";
import { URL_POSTFIX } from "../constants/constant.js";
import { ENV } from "../constants/constant.js";

export function setCookie(name, value) {
  const envName = getEnvVariableName(name);

  const expirationMap = {
    device_uuid: 365,
    refresh_token: 15,
    default: 7,
  };

  const expires = expirationMap[name] || expirationMap.default;

  Cookies.set(envName, value, {
    domain: URL_POSTFIX,
    expires,
  });
}

export function getCookie(name) {
  const envName = getEnvVariableName(name);
  return Cookies.get(envName);
}

export function removeCookie(name) {
  const envName = getEnvVariableName(name);
  Cookies.remove(envName, { domain: URL_POSTFIX });
}

function getEnvVariableName(variableName) {
  switch (ENV) {
    case "dev":
      return `dev_${variableName}`;
    case "prod":
      return `prod_${variableName}`;
    case "demo":
      return `demo_${variableName}`;
    default:
      return variableName;
  }
}
