import React, { memo } from "react";
import Chart from "react-apexcharts";

const Charts = memo(({ chartData }) => {
  if (
    chartData?.type === "Pie" ||
    chartData?.type === "pie" ||
    chartData?.type === "donut" ||
    chartData?.type === "doughnut"
  ) {
    let labelsData, seriesData;

    labelsData = chartData?.data?.labels;
    seriesData = chartData?.data?.datasets?.flatMap((item) => item?.data);

    const options = {
      plotOptions: {
        pie: {
          expandOnClick: false,
          customScale: 1,
        },
      },
      dataLabels: {
        dropShadow: {
          enabled: false,
        },
        style: {
          fontSize: "10px",
          fontFamily: "Manrope, sans-serif",
          fontWeight: "bold",
        },
      },
      chart: {
        width: "10px",
        toolbar: {
          show: false,
        },
      },

      legend: {
        show: false,
      },
      labels: labelsData,
      colors: [
        "#4DC39B",
        "#47A4EA",
        "#CF5768",
        "#E6B350",
        "#8670D3",
        "#065E62",
        "#436593",
        "#F77D7A",
        "#F6CDAD",
        "#8CD294",
        "#83A6DE",
        "#B5496B",
        "#F7BFDC",
        "#ADE8F0",
        "#5365AD",
        "#3A916E",
        "#FFBA93",
      ],
    };

    const colors = [
      "green",
      "blue",
      "red",
      "orange",
      "perpal",
      "065E62",
      "436593",
      "F77D7A",
      "F6CDAD",
      "8CD294",
      "83A6DE",
      "B5496B",
      "F7BFDC",
      "ADE8F0",
      "5365AD",
      "3A916E",
      "FFBA93",
    ];

    return (
      <>
        <h2 className="font-bold text-[14px] text-[#CF037E] pb-3">
          {chartData?.options?.title?.text}
        </h2>

        <div className="flex flex-col md:flex-row pb-1">
          <div className="chart-container -ml-5 -mr-2 -mb-1">
            <Chart options={options} series={seriesData} type="donut" />
          </div>
          <div className="grow flex items-center">
            <div className="grid w-full grid-cols-2 sio-statistics-list px-4 md:pe-4">
              {seriesData.map((value, ind) => (
                <div
                  className={`sio-statistics-list-item border-b ${
                    ind % 2 === 0 ? "border-r" : ""
                  } ${ind >= seriesData.length - 2 ? "border-b-0" : ""}`}
                >
                  <div className={`sio-status sio-status-${colors[ind]}`}>
                    <div className="flex items-center">
                      <p className="font-semibold text-secondary-dark">
                        {labelsData[ind]}
                      </p>
                      <span className="ml-auto font-semibold text-xs">
                        {value}
                      </span>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </>
    );
  }
  if (
    chartData?.bar_chart_data ||
    chartData?.type === "Bar" ||
    chartData?.type === "bar" ||
    chartData?.type === "histogram"
  ) {
    let categoryData, seriesData;

    if (chartData?.bar_chart_data) {
      categoryData = chartData?.bar_chart_data?.labels;
      seriesData = [
        {
          name: Array.isArray(chartData?.bar_chart_data?.datasets?.label)
            ? chartData?.bar_chart_data?.datasets?.label
            : [chartData?.bar_chart_data?.datasets?.label],
          data: chartData?.bar_chart_data?.datasets?.flatMap(
            (value) => value.data
          ),
        },
      ];
    }

    if (chartData?.barChart) {
      categoryData = chartData?.barChart?.categories;
      seriesData = chartData?.barChart?.data.map((series) => ({
        name: series.name,
        data: series.data,
      }));
    }

    if (chartData?.type) {
      categoryData = chartData?.data?.labels;
      seriesData = chartData?.data?.datasets.map((value) => ({
        name: value.label,
        data: value?.data,
      }));
    }

    const options = {
      chart: {
        id: "bar-chart",
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
      },
      tooltip: {
        enabled: true,
        shared: true,
        intersect: false,
      },
      markers: {
        size: 0,
      },
      grid: {
        show: true,
      },
      xaxis: {
        title: {
          text: chartData?.options?.scales?.x?.title?.text,
        },
        categories: categoryData,
        labels: {
          rotate: -90,
          hideOverlappingLabels: true,
          trim: true,
          minHeight: 40,
        },
      },
      yaxis: {
        title: {
          text: chartData?.options?.scales?.y?.title?.text,
        },
      },
    };

    const dynamicMinWidth = `${chartData?.data?.labels?.length * 75}px`;

    return (
      <div className="w-[100%] overflow-x-auto overflow-y-hidden whitespace-nowrap custom-scroll">
        <div className={`min-w-[${dynamicMinWidth}]`}>
          <h2 className="font-bold text-[14px] text-[#CF037E]">
            {chartData?.options?.title?.text}
          </h2>
          <Chart
            options={options}
            series={seriesData}
            type="bar"
            height="300px"
          />
        </div>
      </div>
    );
  }
  if (chartData?.type === "line") {
    let categoryData, seriesData;

    categoryData = chartData?.data?.labels;
    seriesData = chartData?.data?.datasets.map((value) => ({
      name: value.label,
      data: value?.data,
    }));

    const options = {
      chart: {
        id: "line-chart",

        toolbar: {
          show: false,
        },
        type: "area",
        stacked: false,
        width: "100%",
        zoom: {
          type: "x",
          enabled: false,
          autoScaleYaxis: true,
        },
      },
      dataLabels: {
        enabled: false,
      },
      markers: {
        size: 0,
      },
      fill: {
        type: "gradient",
        gradient: {
          shade: "dark",
          type: "horizontal",
          shadeIntensity: 0.5,
          gradientToColors: undefined,
          inverseColors: false,
          opacityFrom: 1,
          opacityTo: 1,
          stops: [100, 100, 100],
          colorStops: [],
        },
      },
      stroke: {
        curve: "smooth",
        width: 4,
        // colors: ["#47A4EA"],
      },
      yaxis: {
        title: {
          text: chartData?.options?.scales?.y?.title?.text,
        },
        labels: {
          style: {
            colors: "#373D3F",
            fontFamily: "Manrope, sans-serif",
            fontWeight: 400,
          },
        },
      },
      xaxis: {
        title: {
          text: chartData?.options?.scales?.x?.title?.text,
        },
        type: "string",
        categories: categoryData,
        labels: {
          rotate: -90,
          style: {
            fontSize: "12px",
            fontFamily: "Arial, sans-serif",
            overflow: "",
          },
          formatter: function (value) {
            const maxLength = 10;
            if (!value) return;
            return value.length > maxLength
              ? value.substring(0, maxLength) + "..."
              : value;
          },
        },
      },
      tooltip: {
        enabled: true,
        y: {
          formatter: function (value) {
            return value;
          },
        },
        x: {
          formatter: function (value) {
            return value;
          },
        },
      },
    };
    const series = seriesData;

    return (
      <div>
        <h2 className="font-bold text-[14px] text-[#CF037E]">
          {chartData?.options?.title?.text}
        </h2>
        <Chart options={options} series={series} height={300} />
      </div>
    );
  }

  if (chartData?.type === "scatter") {
    let categoryData, seriesData;

    categoryData = chartData?.data?.labels;
    seriesData = chartData?.data?.datasets.map((value) => {
      const modifiedDataList = value?.data.map((item) => [item.x, item.y]);

      return {
        name: value.label,
        data: modifiedDataList,
      };
    });

    const options = {
      chart: {
        toolbar: {
          show: false,
        },
        type: "scatter",
        stacked: false,
        width: "100%",
        zoom: {
          type: "x",
          enabled: false,
          autoScaleYaxis: true,
        },
      },
      // dataLabels: {
      //   enabled: false,
      // },
      // markers: {
      //   size: 0,
      // },

      yaxis: {
        title: {
          text: chartData?.options?.scales?.y?.title?.text,
        },
        labels: {
          style: {
            colors: "#373D3F",
            fontFamily: "Manrope, sans-serif",
            fontWeight: 400,
          },
        },
      },
      xaxis: {
        title: {
          text: chartData?.options?.scales?.x?.title?.text,
        },
        type: "string",
        categories: categoryData,
        labels: {
          rotate: -90,
          style: {
            fontSize: "12px",
            fontFamily: "Arial, sans-serif",
            overflow: "",
          },
          formatter: function (value) {
            const maxLength = 10;
            if (!value) return;
            return value.length > maxLength
              ? value.substring(0, maxLength) + "..."
              : value;
          },
        },
      },
      tooltip: {
        enabled: true,
        y: {
          formatter: function (value) {
            return value;
          },
        },
        x: {
          formatter: function (value) {
            return value;
          },
        },
      },
    };
    const series = seriesData;

    return (
      <div>
        <h2 className="font-bold text-[14px] text-[#CF037E]">
          {chartData?.options?.title?.text}
        </h2>
        <Chart options={options} series={series} height={300} type="scatter" />
      </div>
    );
  }
});

export default Charts;
