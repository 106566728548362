import { getCookie } from "./cookieHelper.js";

import { refreshToken, checkAndRefreshToken } from "./authUtils.js";

export const customFetch = async (url, options = {}) => {
  if (!options.headers) {
    options.headers = {};
  }

  const token = await checkAndRefreshToken();
  const retries = 2;

  options.headers.Authorization = `Bearer ${token}`;
  let response;
  for (let attempt = 0; attempt < retries; attempt++) {
    response = await fetch(url, options);

    if (response.status === 401) {
      const newToken = await refreshToken(getCookie("refresh_token"));
      if (newToken) {
        options.headers.Authorization = `Bearer ${newToken}`;
        response = await fetch(url, options);
      }
    }

    if (response.ok) {
      return response;
    }

    if (attempt < retries) {
      console.warn(`Attempt ${attempt + 1} failed. Retrying...`);
      await new Promise((resolve) => setTimeout(resolve, 1000));
    }
  }
  return response;
};
