// theme.js
import { createTheme } from "@mui/material/styles";
import { outlinedInputClasses } from "@mui/material/OutlinedInput";
import SioIcon from "./components/icons/IconLibrary";

const CustomRadioIconChecked = () => <SioIcon name="radioChecked" size={18} />;

const CheckIconDark = () => <SioIcon name="checkboxDark" size={18} />;

const CheckedIconDark = () => <SioIcon name="checkboxDarkChecked" size={18} />;

const CustomCheckIcon = () => <SioIcon name="checkbox" size={26} />;

const CustomIndeterminateIcon = () => (
  <SioIcon name="indeterminate" size={18} />
);

const CustomCheckedIcon = () => <SioIcon name="checkboxChecked" size={26} />;

const CustomRadioIcon = () => <SioIcon name="radio" size={18} />;

// Light Theme
export const lightTheme = createTheme({
  typography: {
    fontFamily: "Manrope, sans-serif",
  },
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          borderColor: "rgba(17, 17, 17, 0.12)",
        },
        root: {
          fontSize: "15px", // Font size for the label
          fontWeight: 400,
          borderRadius: 8,
          fontFamily: "Manrope, sans-serif",
          color: "rgba(17, 17, 17, 1)",
          [`&:hover .${outlinedInputClasses.notchedOutline}`]: {
            borderColor: "rgba(17, 17, 17, 0.54)",
          },
          [`&.Mui-focused .${outlinedInputClasses.notchedOutline}`]: {
            borderColor: "#CF037E",
          },
          "& input:-webkit-autofill": {
            WebkitBoxShadow: "0 0 0 100px #ffffff inset",
            WebkitTextFillColor: "#111111",
            backgroundColor: "#ffffff",
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: "15px", // Font size for the label
          fontWeight: 400,
          fontFamily: "Manrope, sans-serif",
          color: "rgba(17, 17, 17, 0.54)", // default color
          [`&.${outlinedInputClasses.focused}`]: {
            color: "#CF037E", // color when focused
          },
          [`&:hover`]: {
            color: "rgba(17, 17, 17, 0.87)", // color on hover
          },
          "&.MuiInputLabel-shrink": {
            color: "#CF037E", // Change this color as needed
          },
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          borderRadius: "8px",
          boxShadow: "0 4px 16px -1px rgb(0 0 0 / 0.06)",
          border: "1px solid rgba(17, 17, 17, 0.12)",
          "&:first-of-type": {
            borderRadius: "8px",
          },
          "&:before": {
            display: "none",
          },
          "&.Mui-expanded": {
            margin: "0",
          },
        },
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          padding: 0,
          transition: "none",
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          "&:hover": {
            backgroundColor: "rgba(0, 0, 0, 0.1)",
          },
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          borderRadius: "16px 0 0 16px",
          backgroundColor: "#fff",
          boxShadow: "none",
          "@media (max-width:600px)": {
            // Styles for mobile
            width: "90%",
          },
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          borderRadius: "8px",
          marginTop: "0", // Added units to marginTop
          minWidth: 160,
          boxShadow:
            "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
          color: "rgba(17, 17, 17, 0.74)",
          // Override styles for the list
          "& .MuiMenu-list": {
            padding: "8px 0",
          },
          maxHeight: 250,
          // Override styles for individual menu items
          "& .MuiMenuItem-root": {
            fontFamily: "Manrope, sans-serif",
            color: "rgba(17, 17, 17, 0.74)",
            padding: "10px 12px",
            "& .MuiListItemText-root": {
              fontSize: "14px",
              fontWeight: "500",
              "& .MuiListItemText-primary": {
                fontSize: "14px",
                fontWeight: "500",
              },
            },
            "& .soi-icon": {
              fontSize: 18,
              color: "rgba(0, 0, 0, 0.54)",
              marginRight: 12,
              "& svg": {
                fill: "#111111",
              },
            },
            "&:hover": {
              color: "#111111",
              backgroundColor: "rgba(0, 0, 0, 0.08)",
            },
            "&:active": {
              backgroundColor: "rgba(0, 0, 0, 0.08)",
            },
          },
          "& .MuiListSubheader-root": {
            fontFamily: "Manrope, sans-serif",
            color: "rgba(17, 17, 17, 0.74)",
            padding: "10px 12px",
          }
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          // To ensure any global overrides for the Paper component work
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          padding: 8,
          color: "rgba(0, 0, 0, 0.20)", // Default unchecked color
          "&.Mui-checked": {
            color: "#CF037E", // Color when checked
          },
          "&:hover": {
            backgroundColor: "rgba(0, 0, 0, 0.05) !important", // Hover effect
          },
          "&.Mui-disabled": {
            color: "#757575", // Color when disabled
          },
        },
        // Additional states
        "&.Mui-checked": {
          color: "#353535", // Ensure this is set for checked
          "&.Mui-disabled": {
            color: "#555555", // Color for checked disabled state
          },
        },
        // You can also override the label if needed
        label: {
          color: "#ffffff", // Label color
        },
      },
      defaultProps: {
        icon: <CheckIconDark />, // Custom icon for the checkbox
        checkedIcon: <CheckedIconDark />, // Custom checked icon
        indeterminateIcon: <CustomIndeterminateIcon />,
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          padding: 8,
        },
      },
      defaultProps: {
        icon: <CustomRadioIcon />, // Custom icon for the checkbox
        checkedIcon: <CustomRadioIconChecked />, // Custom checked icon
      },
    },
    MuiBackdrop: {
      styleOverrides: {
        root: {
          // Customize the backdrop background color and opacity
          // backgroundColor: 'rgba(0, 0, 0, 0.5)',
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        root: {},
        paper: {
          borderRadius: "16px",
          backgroundColor: "#fff",
          boxShadow: "none",
          margin: "16px",
        },
      },
    },
    MuiPopover: {
      styleOverrides: {
        paper: {
          borderRadius: "8px",
          marginTop: "4px",
          minWidth: 16,
          maxWidth: 280,
          boxShadow:
            "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
          color: "rgba(17, 17, 17, 0.74)",
        },
      },
    },
  },
});

// Dark Theme
export const darkTheme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#353535", // Customize your primary color for dark mode
    },
    background: {
      default: "#121212", // Background color for dark mode
      paper: "#111111",
    },
  },
  components: {
    typography: {
      fontFamily: "Manrope, sans-serif", // Set your default font family
    },
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          borderColor: "rgba(255, 255, 255, 0.16)",
        },
        root: {
          fontSize: "15px", // Font size for the label
          fontWeight: 400,
          borderRadius: 8,
          fontFamily: "Manrope, sans-serif",
          color: "rgba(255, 255, 255, 1)",
          [`&:hover .${outlinedInputClasses.notchedOutline}`]: {
            borderColor: "rgba(255, 255, 255, 0.54)",
          },
          [`&.Mui-focused .${outlinedInputClasses.notchedOutline}`]: {
            borderColor: "#FF4CB7",
          },
          "& input:-webkit-autofill": {
            WebkitBoxShadow: "0 0 0 100px #111111 inset",
            "-webkit-text-fill-color": "white",
            backgroundColor: "#111111",
          },
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          maxWidth: "200px",
          backgroundColor: "#fff",
          color: "#111",
          fontSize: "11px",
          textAlign: "center",
          padding: "8px 12px",
          lineHeight:1.2,
          fontWeight:400,
          fontFamily:'Manrope, sans-serif'
        },
        arrow: {
          color: "#fff",
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: "15px", // Font size for the label
          fontWeight: 400,
          fontFamily: "Manrope, sans-serif",
          color: "rgba(255, 255, 255, 0.54)", // default color
          [`&.${outlinedInputClasses.focused}`]: {
            color: "#FF4CB7", // color when focused
          },
          [`&:hover`]: {
            color: "rgba(255, 255, 255, 0.87)", // color on hover
          },
          "&.MuiInputLabel-shrink": {
            color: "#FF4CB7", // Change this color as needed
          },
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          padding: 0,
          color: "rgba(255, 255, 255, 0.54)", // Default unchecked color
          "&.Mui-checked": {
            color: "#353535", // Color when checked
          },
          "&:hover": {
            backgroundColor: "rgba(0, 0, 0, 0.3)", // Hover effect
          },
          "&.Mui-disabled": {
            color: "#757575", // Color when disabled
          },
        },
        // Additional states
        "&.Mui-checked": {
          color: "#353535", // Ensure this is set for checked
          "&.Mui-disabled": {
            color: "#555555", // Color for checked disabled state
          },
        },
        // You can also override the label if needed
        label: {
          color: "#ffffff", // Label color
        },
      },
      defaultProps: {
        icon: <CustomCheckIcon />, // Custom icon for the checkbox
        checkedIcon: <CustomCheckedIcon />, // Custom checked icon
        indeterminateIcon: <CustomIndeterminateIcon />, // Custom checked icon
      },
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          borderRadius: "8px",
          backgroundColor: "#fff",
          marginTop: "0", // Added units to marginTop
          minWidth: 160,
          maxHeight: 200,
          boxShadow:
            "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
          color: "rgba(17, 17, 17, 0.74)",
          // Override styles for the list
          "& .MuiMenu-list": {
            padding: "8px 0",
          },
          // Override styles for individual menu items
          "& .MuiMenuItem-root": {
            fontSize: "15px",
            fontFamily: "Manrope, sans-serif",
            color: "rgba(17, 17, 17, 0.74)",
            padding: "8px 12px",
            "& .soi-icon": {
              fontSize: 18,
              color: "rgba(0, 0, 0, 0.54)",
              marginRight: 12,
              "& svg": {
                fill: "#111111",
              },
            },
            "&:hover": {
              color: "#111111",
              backgroundColor: "rgba(0, 0, 0, 0.08)",
            },
            "&:active": {
              backgroundColor: "rgba(0, 0, 0, 0.08)",
            },
          },
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          // Customize root accordion styles here
          boxShadow: "none", // Remove box-shadow
          backgroundImage: "none",
          "&:before": {
            display: "none", // Remove the default divider
          },
          "&.Mui-expanded": {
            margin: "0", // Disable margin change on expansion
          },
        },
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          padding: 0,
          transition: "none",
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          // Disable the transition on expand/collapse icon or summary
          transition: "none",
          minHeight: "32px",
          padding: 0,
          "&.Mui-expanded": {
            // Prevent height from changing
          },
          "& .MuiAccordionSummary-content": {
            margin: "0", // Disable margin changes
            transition: "none",
          },
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        paper: {
          borderRadius: "8px",
          backgroundColor: "#fff",
          boxShadow: "0 4px 12px rgba(0,0,0,0.1)",
        },
        listbox: {
          "& .MuiMenuItem-root": {
            fontFamily: "Manrope, sans-serif",
            color: "rgba(17, 17, 17, 0.74)",
            padding: "10px 12px",
            fontSize: "14px",
            fontWeight: "500",
            "& .MuiListItemText-root": {
              fontSize: "14px",
              fontWeight: "500",
              whiteSpace: "normal",
              wordBreak: "break-word",
              "& .MuiListItemText-primary": {
                fontSize: "14px",
                fontWeight: "500",
              },
            },
            "& .soi-icon": {
              fontSize: 18,
              color: "rgba(0, 0, 0, 0.54)",
              marginRight: 12,
              "& svg": {
                fill: "#111111",
              },
            },
            "&:hover": {
              color: "#111111",
              backgroundColor: "rgba(0, 0, 0, 0.08)",
            },
            "&:active": {
              color: "#111111",
              backgroundColor: "rgba(0, 0, 0, 0.08)",
            },
            "&.Mui-focusVisible": {
              backgroundColor: "rgba(0, 0, 0, 0.05)",
              outline: "none",
            },
            "&:mousedown": {
              backgroundColor: "rgba(0, 0, 0, 0.12)",
            },
            "&:mouseup": {
              backgroundColor: "rgba(0, 0, 0, 0.08)",
            },
          },
        },
      },
    },
    
  },
});
