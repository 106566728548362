import React, { useState, useRef, useEffect } from "react";
import Button from "./button";
import SioIcon from "./icons/IconLibrary";
import { toast } from "react-toastify";

const ProfilePhotoUploader = ({ onImageChange, img, userName }) => {
  // const [image, setImage] = useState(null);
  const [preview, setPreview] = useState(null);
  const fileInputRef = useRef(null);

  useEffect(() => {
    setPreview(img);
  }, [img]);

  const handleFileChange = (event) => {
    const file = event.target.files?.[0];

    if (file) {
      const validTypes = ["image/jpeg", "image/png"];

      if (!validTypes.includes(file.type)) {
        toast.success("Invalid file type. Please select an image.", {
          theme: "dark",
          icon: <SioIcon name="check" size={16} color="blue" />,
        });
        return;
      }
      // setImage(file);
      setPreview(URL.createObjectURL(file));
      onImageChange(file);
    }
  };

  // const handleRemoveImage = () => {
  //   setImage(null);
  //   setPreview(null);
  // };

  const getInitials = (name) => {
    if (!name || typeof name !== "string" || name.trim() === "") return "UN";

    const parts = name.trim().split(" ");
    const firstInitial = parts[0]?.[0]?.toUpperCase() || "U";
    const secondInitial = parts[1]?.[0]?.toUpperCase() || "N";

    return firstInitial + secondInitial;
  };

  return (
    <div className="flex flex-col items-center gap-4 rounded-full bg-dark/10 size-40">
      <div
        className="cursor-pointer flex items-center relative justify-center text-5xl text-dark/50 rounded-full size-40"
        onClick={() => fileInputRef.current?.click()}
      >
        {preview ? (
          <img
            src={preview}
            alt="Profile Preview"
            className="size-40 rounded-full object-cover"
          />
        ) : (
          getInitials(userName)
        )}
        <input
          type="file"
          accept="image/jpeg, image/png"
          onChange={handleFileChange}
          ref={fileInputRef}
          className="hidden"
        />
        <Button
          variant="primary"
          size="sm"
          fab
          className="absolute -bottom-4 text-white p-1 rounded-full"
        >
          {/* Custom SVG for Remove Icon */}
          <svg
            width="16"
            height="14"
            viewBox="0 0 16 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12.6667 4.66675V3.33341H11.3334V2.00008H12.6667V0.666748H14.0001V2.00008H15.3334V3.33341H14.0001V4.66675H12.6667ZM7.33342 11.6667C8.16675 11.6667 8.87508 11.3751 9.45842 10.7917C10.0417 10.2084 10.3334 9.50008 10.3334 8.66675C10.3334 7.83341 10.0417 7.12508 9.45842 6.54175C8.87508 5.95841 8.16675 5.66675 7.33342 5.66675C6.50008 5.66675 5.79175 5.95841 5.20842 6.54175C4.62508 7.12508 4.33341 7.83341 4.33341 8.66675C4.33341 9.50008 4.62508 10.2084 5.20842 10.7917C5.79175 11.3751 6.50008 11.6667 7.33342 11.6667ZM7.33342 10.3334C6.86675 10.3334 6.4723 10.1723 6.15008 9.85008C5.82786 9.52786 5.66675 9.13341 5.66675 8.66675C5.66675 8.20008 5.82786 7.80564 6.15008 7.48341C6.4723 7.16119 6.86675 7.00008 7.33342 7.00008C7.80008 7.00008 8.19453 7.16119 8.51675 7.48341C8.83897 7.80564 9.00008 8.20008 9.00008 8.66675C9.00008 9.13341 8.83897 9.52786 8.51675 9.85008C8.19453 10.1723 7.80008 10.3334 7.33342 10.3334ZM2.00008 14.0001C1.63341 14.0001 1.31953 13.8695 1.05841 13.6084C0.797304 13.3473 0.666748 13.0334 0.666748 12.6667V4.66675C0.666748 4.30008 0.797304 3.98619 1.05841 3.72508C1.31953 3.46397 1.63341 3.33341 2.00008 3.33341H4.10008L5.33342 2.00008H10.0001V4.66675H11.3334V6.00008H14.0001V12.6667C14.0001 13.0334 13.8695 13.3473 13.6084 13.6084C13.3473 13.8695 13.0334 14.0001 12.6667 14.0001H2.00008Z"
              fill="white"
            />
          </svg>
        </Button>
      </div>
    </div>
  );
};

export default ProfilePhotoUploader;
