/* eslint-disable react-hooks/exhaustive-deps */
import { FiSearch } from "react-icons/fi";
// import Button from "../../../components/button";
import InputField from "../../../components/InputField";
// import {
//   Checkbox,
//   Dialog,
//   Drawer,
//   FormControl,
//   InputLabel,
//   ListItemText,
//   MenuItem,
//   OutlinedInput,
//   Select,
//   TextField,
//   Tooltip,
// } from "@mui/material";

import React, { useContext, useEffect, useState } from "react";
import SioIcon from "../../../components/icons/IconLibrary";
import { DataContext } from "../../../components/layout/layout";
import { useLocation, useNavigate } from "react-router-dom";
import {
  // addNewFileType,
  // deleteFileType,
  // editFileType,
  fetchFileTypesData,
} from "../../../apis/recommendation/recommendation";
// import { toast } from "react-toastify";

const FileTypes = () => {
  const {
    setIsArchivedDataSources,
    isArchivedDataSources,
    setIsFilesType,
    isFilesType,
  } = useContext(DataContext);
  const [fileTypes, setFileTypes] = useState(null);
  const location = useLocation();
  location.pathname.includes("archived-data-sources");

  // const [selectAssociateFiles, setSelectAssociateFiles] = useState(null);
  const [filteredFileTypes, setFilteredFileTypes] = useState(null);
  // const [isSorted, setIsSorted] = useState(false);
  // const [defaultOrder, setDefaultOrder] = useState([]);
  // const [newFileName, setNewFileName] = useState(null);
  // const [newDescription, setNewDescription] = useState(null);
  // const [newAssociatedFile, setNewAssociatedFile] = useState(null);
  // const [selectedFileType, setSelectedFileType] = useState(null);
  // const [editFileName, setEditFileName] = useState(null);
  // const [editDescription, setEditDescription] = useState(null);
  // const [editAssociatedFile, setEditAssociatedFile] = useState(null);

  // Delet File Types
  // const [openRemoveModal, setRemoveModalOpen] = React.useState(false);

  // const handleRemoveModalClickOpen = (file) => {
  //   setRemoveModalOpen(true);
  //   setSelectedFileType(file);
  // };

  // Edit File Types Drawer
  // const [openEditFileTypesDrawer, setEditFileTypesDrawerOpen] =
  //   React.useState(false);

  // const toggleEditFileTypesDrawer = (newOpen, file) => {
  //   setEditFileTypesDrawerOpen(newOpen);
  //   setSelectedFileType(file);
  //   setEditFileName(file?.name);
  //   setEditDescription(file?.description);
  //   setEditAssociatedFile(file?.associated_files);
  // };

  // Add File Types Drawer
  // const [openAddFileTypesDrawer, setAddFileTypesDrawerOpen] =
  //   React.useState(false);
  // const toggleAddFileTypesDrawer = (newOpen) => () => {
  //   // setAddFileTypesDrawerOpen(newOpen);
  // };

  const fetchData = async () => {
    const data = await fetchFileTypesData();

    setFileTypes(data?.topics);
    setFilteredFileTypes(data?.topics);
    // setSelectAssociateFiles(data?.topics);
    // setDefaultOrder(data);
  };

  useEffect(() => {
    fetchData();
  }, []);

  // const [personName, setPersonName] = React.useState([]);

  // const handleChange = (event) => {
  //   const {
  //     target: { value },
  //   } = event;
  //   setNewAssociatedFile(value);
  //   setPersonName(
  //     // On autofill we get a stringified value.
  //     typeof value === "string" ? value.split(",") : value
  //   );
  // };

  // const handleRemoveModalClose = () => {
  //   setRemoveModalOpen(false);
  // };

  // const handleDeleteFileType = async () => {
  //   handleRemoveModalClose();
  //   try {
  //     const { message } = await deleteFileType(selectedFileType?.id);

  //     setFilteredFileTypes(() => {
  //       return filteredFileTypes.filter(
  //         (item) => item.id !== selectedFileType?.id
  //       );
  //     });
  //     toast.success(message, {
  //       icon: <SioIcon name="check" size={16} color="blue" />,
  //     });
  //   } catch (err) {
  //     toast.error(err);
  //   }
  // };

  const navigate = useNavigate();

  const handleBack = () => {
    navigate(-1);
    if (isArchivedDataSources && isFilesType) {
      setIsArchivedDataSources(true);
      setIsFilesType(true);
    }
  };

  // const handleChangEditQuaDataSourceFilter = (event, item) => {
  //   const associatedFiles = item.props.value;

  //   setEditAssociatedFile((prevSelectedFiles) => {
  //     const isSelected = prevSelectedFiles.some(
  //       (selectedFile) => selectedFile === associatedFiles
  //     );
  //     if (isSelected) {
  //       return prevSelectedFiles.filter(
  //         (selectedFile) => selectedFile !== associatedFiles
  //       );
  //     } else {
  //       return [...prevSelectedFiles, item.props.value];
  //     }
  //   });
  // };

  const handleInput = (event) => {
    const { value } = event.target;
    const filteredData = fileTypes.filter((data) =>
      data?.topic.toLowerCase().includes(value.toLowerCase().trim())
    );

    setFilteredFileTypes(filteredData);
  };

  function debounce(func, delay) {
    let timer;
    return function (...args) {
      const context = this;
      clearTimeout(timer);
      timer = setTimeout(() => func.apply(context, args), delay);
    };
  }

  const debouncedInputHandler = debounce(handleInput, 500);

  if (!fileTypes) return;

  return (
    <div className="flex flex-col h-full overflow-y-auto">
      <div className="shadow-md p-3 md:p-5 relative z-10">
        <div className="w-full flex items-center">
          <button
            className="soi-btn soi-btn-fab soi-btn-flat btn-dark size-9"
            onClick={() => {
              setIsArchivedDataSources(false);
              setIsFilesType(false);
              handleBack();
            }}
          >
            <SioIcon name="leftArrow" size={18} />
          </button>{" "}
          <h1 className="font-normal text-lg md:text-xl text-primary-dark">
            File Types
          </h1>
          <div className="items-center ms-auto flex">
            {/* <Button
              onClick={toggleAddFileTypesDrawer(true)}
              variant="secondary"
              className=""
              size={"sm"}>
              {" "}
              Add New File Types
            </Button> */}
          </div>
        </div>
      </div>
      <div className="overflow-scroll scrollbar-none">
        <div className="p-3 md:p-6">
          <div className="w-full sio-file-types-table sio-card-shadow sio-table-card-sm sio-card-sm p-0 gap-0">
            <div className="sio-card-header">
              <div className="ms-auto me-2">
                <InputField
                  placeholder={`Search...`}
                  css="flex-grow px-3 py-2 text-sm focus:outline-none focus:border-none"
                  icon={<FiSearch />}
                  sx={{
                    "&.Mui-focusVisible": {
                      boxShadow: "none",
                    },
                  }}
                  onChange={debouncedInputHandler}
                />
              </div>
            </div>
            <table className="table-auto w-full sio-table sio-table-border text-left border-t">
              <thead className="sio-table-thead">
                <tr>
                  <th>Topic</th>
                  <th>Sub Topic</th>
                  <th>Description</th>
                  <th>Associate Files</th>
                </tr>
              </thead>
              <tbody className="sio-table-tbody">
                {filteredFileTypes.map((datatype, index) => (
                  <React.Fragment key={index}>
                    {datatype.files && datatype?.files?.length > 0 ? (
                      datatype?.files.map((sub, subIndex) => (
                        <tr
                          key={`${index}-${subIndex}`}
                          className="border-b border-gray-300"
                        >
                          {subIndex === 0 ? (
                            <td
                              rowSpan={datatype?.files?.length}
                              className="text-secondary-dark font-bold border-r p-2 "
                            >
                              {datatype?.topic}
                            </td>
                          ) : null}
                          <td className="p-2 text-secondary-dark border">
                            {sub?.sub_topic}
                          </td>

                          <td className="p-2 text-secondary-dark border">
                            {sub?.file_type?.description ||
                              "No description available"}
                          </td>
                          <td className="p-2 text-secondary-dark border">
                            {sub?.original_file_name}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr key={index} className="border-b border-gray-300">
                        <td className="text-secondary-dark font-bold border-r p-2">
                          {datatype?.topic}
                        </td>
                        <td className="p-2 text-secondary-dark" colSpan="3">
                          No Data available
                        </td>
                      </tr>
                    )}
                  </React.Fragment>
                ))}
              </tbody>
            </table>
          </div>

          {/* <Dialog
            open={openRemoveModal}
            onClose={() => handleRemoveModalClose()}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <div className="max-w-md flex justify-center items-center text-center p-8 flex-col">
              <SioIcon name="delete" className="mb-5 size-14 md:size-20" />
              <h1 className="text-primary-dark text-lg md:text-xl mb-7">
                Are you sure you want to remove File Types ?
              </h1>
              <div className="gap-4 flex">
                <Button
                  variant="secondary"
                  onClick={() => handleDeleteFileType()}
                  size={"sm"}>
                  Yes, Remove
                </Button>
                <Button
                  variant="default"
                  size={"sm"}
                  onClick={() => handleRemoveModalClose()}>
                  Not Now
                </Button>
              </div>
            </div>
          </Dialog> */}

          {/* <Drawer
            anchor={"right"}
            open={openEditFileTypesDrawer}
            onClose={() => toggleEditFileTypesDrawer(false)}>
            <div className="sio-sidebar-right w-[340px] md:w-[500px]">
              <div className="sio-sidebar-header flex items-center">
                <h2 className="grow-0">Edit File Type</h2>
                <Button
                  className="soi-btn-fab soi-btn-fab soi-btn-flat btn-lg ms-auto"
                  onClick={() => toggleEditFileTypesDrawer(false)}>
                  <SioIcon name="closeLight" size={24} />
                </Button>
              </div>
              <div className="sio-sidebar-body h-auto p-4 md:p-6 !py-7">
                <div className="grid grid-cols-1 gap-y-6">
                  <TextField
                    label="Name"
                    value={editFileName}
                    fullWidth
                    sx={""}
                    onChange={(e) => {
                      setEditFileName(e.target.value);
                    }}
                  />
                  <TextField
                    label="Description"
                    value={editDescription}
                    multiline
                    rows={4}
                    fullWidth
                    onChange={(e) => {
                      setEditDescription(e.target.value);
                    }}
                  />
                  <FormControl fullWidth>
                    <InputLabel id="demo-multiple-chip-label">
                      Associated files
                    </InputLabel>
                    <Select
                      labelId="demo-multiple-checkbox-label"
                      id="demo-multiple-checkbox"
                      multiple
                      value={editAssociatedFile}
                      onChange={handleChangEditQuaDataSourceFilter}
                      input={
                        <OutlinedInput
                          id="select-multiple-chip-label"
                          label="Associated files"
                        />
                      }
                      renderValue={(selected) => selected.join(", ")}>
                      {editAssociatedFile &&
                        selectAssociateFiles.map((name) => (
                          <MenuItem key={name} value={name}>
                            <Checkbox
                              checked={editAssociatedFile.includes(name)}
                            />
                            <ListItemText primary={name} />
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </div>
              </div>
              <div className="sio-sidebar-footer gap-4 flex pt-0">
                <Button
                  variant="secondary"
                  onClick={async () => {
                    const fileData = {
                      name: editFileName,
                      description: editDescription,
                      associated_files: editAssociatedFile,
                    };
                    try {
                      const data = await editFileType(
                        fileData,
                        selectedFileType?.id
                      );
                      setFilteredFileTypes((prevData) => {
                        const fileData = {
                          name: data.name,
                          description: data.description,
                          associated_files: data?.associated_files.map(
                            (file) => file?.original_file_name
                          ),
                          id: data?.id,
                        };
                        return prevData.map((item) =>
                          item.id === selectedFileType?.id ? fileData : item
                        );
                      });
                      toast.success("File Type has been edited successfully!", {
                        icon: <SioIcon name="check" size={16} color="blue" />,
                      });
                      setPersonName([]);
                    } catch (error) {
                      toast.error("Failed to edited the File Type", {
                        icon: <SioIcon name="close" size={16} color="red" />,
                      });
                      console.err(error);
                    }
                  }}
                  size={"sm"}>
                  {" "}
                  Save File Type{" "}
                </Button>
                <Button
                  variant="default"
                  onClick={() => toggleEditFileTypesDrawer(false)}
                  size={"sm"}
                  className="text-black">
                  {" "}
                  Cancel{" "}
                </Button>
              </div>
            </div>
          </Drawer> */}

          {/* <Drawer
            anchor={"right"}
            open={openAddFileTypesDrawer}
            onClose={toggleAddFileTypesDrawer(false)}>
            <div className="sio-sidebar-right w-[340px] md:w-[500px]">
              <div className="sio-sidebar-header flex items-center">
                <h2 className="grow-0">Add File Type</h2>
                <Button
                  className="soi-btn-fab soi-btn-fab soi-btn-flat btn-lg ms-auto"
                  onClick={toggleAddFileTypesDrawer(false)}>
                  <SioIcon name="closeLight" size={24} />
                </Button>
              </div>
              <div className="sio-sidebar-body h-auto p-4 md:p-6 !py-4 md:!py-7">
                <div className="grid grid-cols-1 gap-y-4 md:gap-y-6">
                  <TextField
                    label="Name"
                    fullWidth
                    sx={""}
                    onChange={(e) => {
                      setNewFileName(e.target.value);
                    }}
                  />
                  <TextField
                    label="Description"
                    multiline
                    rows={4}
                    fullWidth
                    onChange={(e) => {
                      setNewDescription(e.target.value);
                    }}
                  />
                  <FormControl fullWidth>
                    <InputLabel id="demo-multiple-chip-label">
                      Associated files
                    </InputLabel>
                    <Select
                      labelId="demo-multiple-checkbox-label"
                      id="demo-multiple-checkbox"
                      multiple
                      value={personName}
                      onChange={handleChange}
                      input={
                        <OutlinedInput
                          id="select-multiple-chip-label"
                          label="Associated files"
                        />
                      }
                      renderValue={(selected) => selected.join(", ")}>
                      {selectAssociateFiles.map((name) => (
                        <MenuItem key={name} value={name}>
                          <Checkbox checked={personName.includes(name)} />
                          <ListItemText primary={name} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              </div>
              <div className="sio-sidebar-footer gap-4 flex pt-0">
                <Button
                  variant="secondary"
                  size={"sm"}
                  onClick={async () => {
                    try {
                      const data = await addNewFileType(
                        newFileName,
                        newDescription,
                        newAssociatedFile
                      );

                      setFilteredFileTypes((prevData) => {
                        const fileData = {
                          name: data.name,
                          description: data.description,
                          associated_files: data?.associated_files.map(
                            (file) => file?.original_file_name
                          ),
                          id: data?.id,
                        };
                        return [fileData, ...prevData];
                      });
                      toast.success("File Type has been added successfully!", {
                        icon: <SioIcon name="check" size={16} color="blue" />,
                      });
                      setAddFileTypesDrawerOpen(false);
                    } catch (err) {
                      toast.success("Failed to add the File Type", {
                        icon: <SioIcon name="check" size={16} color="blue" />,
                      });
                    }
                  }}>
                  {" "}
                  Save File Type{" "}
                </Button>
                <Button
                  variant="default"
                  onClick={toggleAddFileTypesDrawer(false)}
                  size={"sm"}
                  className="text-black">
                  {" "}
                  Cancel{" "}
                </Button>
              </div>
            </div>
          </Drawer> */}
        </div>
      </div>
    </div>
  );
};

export default FileTypes;
