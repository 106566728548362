import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";

export const generatePDF = async (
  pdfRef,
  currentFileName,
  setIsRenderingDownLoadButton
) => {
  if (!pdfRef.current) {
    console.error("PDF reference not available");
    return;
  }
  const delay = (ms) => new Promise((res) => setTimeout(res, ms));

  setIsRenderingDownLoadButton(true);

  await delay(2000);

  try {
    const element = pdfRef.current;

    const styleSheet = document.createElement("style");
    styleSheet.textContent = `
          @media print {
            body {
              -webkit-print-color-adjust: exact;
            }
            p, div {
              page-break-inside: avoid;
            }
            table {
              page-break-inside: avoid;
            }
            .chart-container {
              page-break-inside: avoid;
              margin: 20px 0;
            }
          }
        `;
    element.appendChild(styleSheet);

    const charts = element.getElementsByClassName("recharts-wrapper");
    await Promise.all(
      Array.from(charts).map(
        (chart) =>
          new Promise((resolve) => {
            const observer = new MutationObserver((mutations, obs) => {
              if (chart.querySelector("svg")) {
                obs.disconnect();
                resolve();
              }
            });

            observer.observe(chart, {
              childList: true,
              subtree: true,
            });
          })
      )
    );

    const canvas = await html2canvas(element, {
      scale: 2,
      useCORS: true,
      logging: false,
      allowTaint: true,
      windowWidth: 1080,
      windowHeight: element.scrollHeight,
      onclone: (clonedDoc) => {
        return clonedDoc.fonts.ready.then(() => {
          // Apply list styles to cloned document
          const lists = clonedDoc.querySelectorAll("ol, ul");
          lists.forEach((list) => {
            if (list.tagName === "OL") {
              list.style.counterReset = "item";
              list.style.listStyle = "none"; // Remove default list styling

              Array.from(list.children).forEach((li, index) => {
                li.style.position = "relative";
                li.style.paddingLeft = "1.5em";

                const number = document.createElement("span");
                number.style.position = "absolute";
                number.style.left = "0";
                number.style.top = "0";
                number.textContent = index + 1 + " .";
                li.insertBefore(number, li.firstChild);
              });
            } else {
              list.style.counterReset = "item";
              list.style.listStyle = "none";

              Array.from(list.children).forEach((li, index) => {
                li.style.position = "relative";
                li.style.paddingLeft = "1.5em";

                li.style.display = "block";
                li.style.textIndent = "-1em";

                const number = document.createElement("span");
                number.style.position = "absolute";
                number.style.left = "0";
                number.style.top = "0";
                const bullet = document.createTextNode("• ");
                li.insertBefore(bullet, li.firstChild);
              });
            }
          });

          const elements = clonedDoc.querySelectorAll("[id^='inp']");

          if (elements.length > 0) {
            elements.forEach((questionContainer) => {
              questionContainer.style.marginLeft = "auto";
              questionContainer.style.marginRight = "0";
              questionContainer.style.display = "block";
            });
          }

          const clonedCharts =
            clonedDoc.getElementsByClassName("recharts-wrapper");
          Array.from(clonedCharts).forEach((chart) => {
            chart.style.backgroundColor = "#ffffff";
            chart.style.pageBreakInside = "always";
          });

          const tables = clonedDoc.getElementsByTagName("table");
          Array.from(tables).forEach((table) => {
            table.style.pageBreakInside = "avoid";
          });
        });
      },
    });

    const pdf = new jsPDF({
      orientation: "portrait",
      unit: "mm",
      format: "a4",
      compress: true,
    });

    pdf.addFont(
      "https://fonts.gstatic.com/s/roboto/v29/KFOmCnqEu92Fr1Mu4mxP.ttf",
      "Roboto",
      "normal"
    );
    pdf.setFont("Roboto");
    pdf.setFontSize(11);

    const pageWidth = pdf.internal.pageSize.getWidth();
    const pageHeight = pdf.internal.pageSize.getHeight();
    const imgWidth = pageWidth - 20;
    const imgHeight = (canvas.height * imgWidth) / canvas.width;
    const pageCount = Math.ceil(imgHeight / (pageHeight - 20));

    for (let i = 0; i < pageCount; i++) {
      if (i > 0) {
        pdf.addPage();
      }

      const sourceY = (i * canvas.height) / pageCount;
      const sourceHeight = canvas.height / pageCount;

      const pageCanvas = document.createElement("canvas");
      pageCanvas.width = canvas.width;
      pageCanvas.height = sourceHeight;

      const ctx = pageCanvas.getContext("2d");
      ctx.drawImage(
        canvas,
        0,
        sourceY,
        canvas.width,
        sourceHeight,
        0,
        0,
        canvas.width,
        sourceHeight
      );

      const imgData = pageCanvas.toDataURL("image/jpeg", 1.0);
      pdf.addImage(
        imgData,
        "JPEG",
        10,
        10,
        imgWidth,
        (sourceHeight * imgWidth) / canvas.width,
        "",
        "FAST"
      );
    }

    element.removeChild(styleSheet);

    pdf.save(`${currentFileName}.pdf`);
  } catch (error) {
    console.error("Error generating PDF:", error);
  } finally {
    setIsRenderingDownLoadButton(false);
  }
};
