import React, { useEffect, useState, useRef } from "react";
import { HotTable } from "@handsontable/react";

const CSVViewer = () => {
  const [parsedData, setParsedData] = useState([]);
  const [columnHeaders, setColumnHeaders] = useState([]);
  const [fileName, setFileName] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const retryRef = useRef(0); // Use ref instead of state to track attempts

  useEffect(() => {
    let messageInterval;
    let cleanup = false;

    const handleMessage = (event) => {
      if (event.origin !== window.location.origin) return;

      try {
        const { data, columns, fileName } = event.data;

        if (
          Array.isArray(data) &&
          Array.isArray(columns) &&
          typeof fileName === "string"
        ) {
          if (!cleanup) {
            setParsedData(data);
            setColumnHeaders(columns);
            setFileName(fileName);
            setIsLoading(false);
          }

          if (messageInterval) clearInterval(messageInterval);

          if (window.opener && !window.opener.closed) {
            window.opener.postMessage(
              { type: "DATA_RECEIVED", success: true },
              window.location.origin
            );
          }
        }
      } catch (error) {
        console.error("Error processing received data:", error);
        if (!cleanup) {
          setIsLoading(false);
        }
      }
    };

    const requestData = () => {
      if (window.opener && !window.opener.closed) {
        window.opener.postMessage(
          { type: "REQUEST_DATA" },
          window.location.origin
        );
        retryRef.current += 1; // Increment ref instead of state
      } else {
        if (messageInterval) clearInterval(messageInterval);
      }
    };

    window.addEventListener("message", handleMessage);

    messageInterval = setInterval(() => {
      if (retryRef.current < 10) {
        requestData();
      } else {
        clearInterval(messageInterval);
        if (!cleanup) setIsLoading(false);
      }
    }, 1000);

    requestData();

    return () => {
      cleanup = true;
      window.removeEventListener("message", handleMessage);
      if (messageInterval) clearInterval(messageInterval);
    };
  }, []);

  if (isLoading) {
    return (
      <div className="bg-white h-full rounded-lg flex flex-col items-center">
        <div className="flex items-center justify-center h-screen">
          <p className="text-lg">
            Loading data... (Attempt {retryRef.current + 1}/10)
          </p>
        </div>
      </div>
    );
  }

  return (
    <div className="bg-white h-full rounded-lg flex flex-col items-center">
      {fileName && (
        <h2 className="text-xl font-bold mb-4 text-center">
          Viewing File: <span className="text-primary-800">{fileName}</span>
        </h2>
      )}

      {parsedData.length > 0 && columnHeaders.length > 0 ? (
        <HotTable
          data={parsedData}
          colHeaders={columnHeaders}
          rowHeaders={true}
          licenseKey="non-commercial-and-evaluation"
          stretchH="all"
          height="100%"
          width="100%"
          colWidths={() => {
            const numColumns = columnHeaders.length;
            const baseWidth = 100;
            const maxWidth = 150;
            const totalWidth = window.innerWidth;
            const calculatedWidth = Math.min(totalWidth / numColumns, maxWidth);
            return Math.max(calculatedWidth, baseWidth);
          }}
          className="handsontable"
          afterGetColHeader={(col, TH) => {
            if (!TH.classList.contains("custom-header")) {
              TH.classList.add("custom-header");
            }
          }}
          cells={(row, col, prop) => ({
            className: row === -1 ? "header-highlight" : "",
            readOnly: true,
          })}
        />
      ) : (
        <div className="flex items-center justify-center h-screen">
          <p className="text-lg text-red-600 font-semibold">
            No data available
          </p>
        </div>
      )}
    </div>
  );
};

export default CSVViewer;
