/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  getFilesList,
  getMappedRecommendations,
  getRecByS3Keys,
  getRecommendationById,
} from "../../../apis/recommendation/recommendation";
import { StrivedContext } from "../../../App";
import Button from "../../../components/button";
import SioIcon from "../../../components/icons/IconLibrary";
import { DataContext } from "../../../components/layout/layout";
import SuggestionsContainer from "./suggestionsContainer";
import SuggestionsSidebar from "./suggestionsSidebar";

const Suggestions = () => {
  const [selectedSuggestion, setSelectedSuggestion] = useState(null);
  const [filteredRec, setFilteredRec] = useState([]);
  const [isModileSidebarActive, setModileSidebarIsActive] = useState(false);
  const [isDataLoading, setIsDataLoading] = useState(false);

  const [fileUUID, setFileUUID] = useState([]);

  const location = useLocation();

  const params = new URLSearchParams(window.location.search);
  const pathId = Number(params.get("recommendationId"));

  const { recommendationId } = location.state || {};

  const navigate = useNavigate();

  const { setHighlightField, filesBasedSubTopics, setS3Key, filesData } =
    useContext(DataContext);

  const {
    suggestions,
    isGetStartedClicked,
    setIsGetStartedClicked,
    selectSubTopics,
    selectedTopics,
    setSuggestions,
  } = useContext(StrivedContext);

  const getRecommendations = async () => {
    let mappedRecommendation = [];
    let recommendations = [];
    setSelectedSuggestion(null);
    setIsDataLoading(true);

    sessionStorage.setItem("subTopics", JSON.stringify(selectSubTopics));

    const subTopicsLength = JSON.parse(
      sessionStorage.getItem("subTopics")
    ).length;

    if (subTopicsLength > 0) {
      const modifiedData = selectSubTopics.flatMap((item) =>
        Object.values(item).flat()
      );

      const data = await getMappedRecommendations(modifiedData);

      data.forEach((item) => {
        if (item.roles.length > 0) {
          item.roles.forEach((role) => {
            if (role.recommendations) {
              mappedRecommendation.push(...role.recommendations);
            }
          });
        }
      });

      let s3_keys;
      const files = await getFilesList();
      const filteredFileData = files.filter(
        (file) =>
          (file.s3_key.includes(".csv") ||
            file.s3_key.includes(".json") ||
            file.s3_key.includes(".csv.filepart")) &&
          !file.archive_status
      );

      const subtopicsBasedRecommendations = filteredFileData
        .filter((file) => {
          const fileSubtopics = file.sub_topic
            .split(/,\s*/)
            .map((s) => s.trim());

          return fileSubtopics.some((subtopic) =>
            modifiedData.includes(subtopic)
          );
        })
        .map((item) => item.s3_key);

      s3_keys = subtopicsBasedRecommendations;

      if (s3_keys.length === 0 || mappedRecommendation.length === 0) {
        s3_keys = filteredFileData.map((item) => item.s3_key);
      }

      setFileUUID([
        ...new Set(filteredFileData.map((file) => file?.file_uuid)),
      ]);

      const data1 = await getRecByS3Keys(
        s3_keys,
        filteredFileData,
        setSuggestions
      );

      setS3Key(s3_keys);
      sessionStorage.setItem("s3_keys", JSON.stringify(s3_keys));

      let finalRecomendation = [];

      if (!data1) {
        finalRecomendation = [];
      } else if (data1.length > 1) {
        finalRecomendation = data1.filter((rec) => rec?.is_combined);
        if (finalRecomendation.length === 0) finalRecomendation = data1;
      } else finalRecomendation = data1;

      if (recommendationId) {
        const exists = finalRecomendation?.some(
          (item) => item.id === recommendationId
        );
        if (exists) {
          recommendations = [...finalRecomendation];
        } else {
          params.append("recommendation_id", recommendationId);
          const historyRecommendation = await await getRecommendationById(
            params
          );
          if (historyRecommendation && historyRecommendation?.length > 0) {
            recommendations = [...historyRecommendation, ...finalRecomendation];
          }
        }
      } else {
        recommendations = [...finalRecomendation, ...mappedRecommendation];
      }

      if (recommendationId) {
        const exists = mappedRecommendation?.some(
          (item) => item.id === recommendationId
        );
        if (exists) {
          recommendations = [...mappedRecommendation];
        } else {
          params.append("recommendation_id", recommendationId);
          const historyRecommendation = await await getRecommendationById(
            params
          );
          if (historyRecommendation && historyRecommendation?.length > 0) {
            recommendations = [
              ...historyRecommendation,
              ...mappedRecommendation,
            ];
          }
        }
      } else {
        recommendations = [...finalRecomendation, ...mappedRecommendation];
      }
    } else {
      const files = await getFilesList();
      const filteredFileData = files.filter(
        (file) =>
          (file.s3_key.includes(".csv") ||
            file.s3_key.includes(".json") ||
            file.s3_key.includes(".csv.filepart")) &&
          !file.archive_status
      );

      const s3_keys = filteredFileData.map((file) => file.s3_key);

      setFileUUID([
        ...new Set(filteredFileData.map((file) => file?.file_uuid)),
      ]);

      let data1;

      const s3KeysFromStorage = JSON.parse(sessionStorage.getItem("s3_keys"));

      if (s3KeysFromStorage.length === 0) {
        setS3Key(s3_keys);
        data1 = await getRecByS3Keys(s3_keys, filteredFileData, setSuggestions);
        sessionStorage.setItem("s3_keys", JSON.stringify(s3_keys));
      } else {
        setS3Key(s3KeysFromStorage);
        data1 = await getRecByS3Keys(
          s3KeysFromStorage,
          filteredFileData,
          setSuggestions
        );
        sessionStorage.setItem("s3_keys", JSON.stringify(s3KeysFromStorage));
      }

      let finalRecomendation = [];

      if (!data1) {
        finalRecomendation = [];
      } else if (data1.length > 1) {
        finalRecomendation = data1.filter((rec) => rec?.is_combined);
        if (finalRecomendation.length === 0) finalRecomendation = data1;
      } else {
        finalRecomendation = data1;
      }

      if (recommendationId) {
        const exists = finalRecomendation?.some(
          (item) => item.id === recommendationId
        );
        if (exists) {
          recommendations = [...finalRecomendation];
        } else {
          params.append("recommendation_id", recommendationId);
          const historyRecommendation = await await getRecommendationById(
            params
          );
          if (historyRecommendation && historyRecommendation?.length > 0) {
            recommendations = [...historyRecommendation, ...finalRecomendation];
          }
        }
      } else {
        recommendations = [...finalRecomendation];
      }
    }
    setIsDataLoading(false);
    const filterDupicateRecommendations = recommendations
      .sort((a, b) => {
        return new Date(b.last_updated) - new Date(a.last_updated);
      })
      .reduce(
        (acc, current) => {
          if (!acc.seen.has(current.category)) {
            acc.seen.add(current.category);
            acc.result.push(current);
          }
          return acc;
        },
        { seen: new Set(), result: [] }
      ).result;

    const updatedRecommendations = filterDupicateRecommendations.filter(
      (rec) => ![3449, 3151].includes(rec?.id)
    );

    const dateSortedFormat = updatedRecommendations.sort((a, b) => {
      return new Date(b.last_updated) - new Date(a.last_updated);
    });

    const modifiedSuggestionsList = [
      ...dateSortedFormat.filter(
        (item) => item.category === "Highlights From Your Data"
      ),
      ...dateSortedFormat.filter(
        (item) => item.category !== "Highlights From Your Data"
      ),
    ];

    const initialRecId = modifiedSuggestionsList[0]?.id;

    setFilteredRec(modifiedSuggestionsList);

    if (pathId || initialRecId) {
      setSelectedSuggestion(
        modifiedSuggestionsList.find(
          (item) => item.id === (pathId || initialRecId)
        )
      );
    } else {
      setSelectedSuggestion(
        modifiedSuggestionsList.sort((a, b) => {
          return new Date(b.created_at) - new Date(a.created_at);
        })[0]
      );
    }
  };

  useEffect(() => {
    const isRefreshed = sessionStorage.getItem("isRefresh");
    if (isRefreshed === "true") {
      sessionStorage.removeItem("isRefresh");
      setHighlightField("Suggestions");
    }

    const handleBeforeUnload = () => {
      sessionStorage.setItem("isRefresh", "true");
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    setHighlightField("Suggestions");
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    getRecommendations(suggestions);
  }, [sessionStorage.getItem("subTopics")]);

  const handleModileSidebarToggle = () => {
    setModileSidebarIsActive(!isModileSidebarActive);
  };

  const handleAskMeAnything = () => {
    setHighlightField("Ask Anything");
    navigate("/");
  };

  const playGroundTopBar = () => {
    return (
      <div className="flex md:items-center shadow-md p-3 md:p-5 flex-col md:flex-row relative z-10">
        <div className="flex items-center">
          <Button
            outline
            fab
            variant="dark"
            className="me-2 size-8 flex lg:hidden"
            size={"xs"}
            onClick={handleModileSidebarToggle}
          >
            <SioIcon name="filter" size={24} />
          </Button>
          <h1 className="font-normal text-lg md:text-xl text-primary-dark">
            Suggestions
          </h1>
        </div>
        <p className="text-xs leading-tight md:text-sm text-secondary-dark md:border-l ml-7 md:ml-3 pl-3">
          You are viewing suggestions from Strived, choose a topic to view
          customized suggestions.
        </p>
        <div className="items-center ms-auto flex">
          <Button
            variant="secondary"
            className="hidden lg:flex"
            size={"sm"}
            onClick={() => {
              handleAskMeAnything();
            }}
          >
            Ask me anything
          </Button>
        </div>
      </div>
    );
  };

  return (
    <div className="bg-white h-full rounded-lg flex justify-center">
      <div className={`h-full w-full flex flex-col overflow-hidden`}>
        {playGroundTopBar()}

        <div className={`flex flex-row overflow-hidden h-full`}>
          <SuggestionsSidebar
            isModileSidebarActive={isModileSidebarActive}
            recommendationData={filteredRec}
            setModileSidebarIsActive={setModileSidebarIsActive}
            setSelectedSuggestion={setSelectedSuggestion}
            recommendationId={recommendationId}
            selectedTopics={selectedTopics}
            selectSubTopics={selectSubTopics}
            isGetStartedClicked={isGetStartedClicked}
            setIsGetStartedClicked={setIsGetStartedClicked}
            isDataLoading={isDataLoading}
            setIsDataLoading={setIsDataLoading}
            filesBasedSubTopics={filesBasedSubTopics}
            filesData={filesData}
          />
          <SuggestionsContainer
            recommendationData={selectedSuggestion}
            setSelectedSuggestion={setSelectedSuggestion}
            // fileNamesList={fileNamesList}
            fileUUID={fileUUID}
            isDataLoading={isDataLoading}
            setFilteredSuggestions={setFilteredRec}
          />
        </div>
      </div>
    </div>
  );
};

export default Suggestions;
