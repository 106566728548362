import React, { useEffect, useRef, useState } from 'react';
import Button from './button';
import SioIcon from './icons/IconLibrary';

const ZendeskButton = () => {
  const [isChatOpen, setIsChatOpen] = useState(false);
  const buttonRef = useRef(null);

  useEffect(() => {
    // Check if Zendesk is loaded
    const checkZendesk = setInterval(() => {
      if (window.zE) {
        clearInterval(checkZendesk);

        // Add event listeners for Zendesk widget open and close
        window.zE('webWidget:on', 'open', () => setIsChatOpen(true));
        window.zE('webWidget:on', 'close', () => setIsChatOpen(false));
      }
    }, 500);

    return () => clearInterval(checkZendesk);
  }, []);

  const toggleZendeskChat = () => {
    if (window.zE) {
      if (isChatOpen) {
        window.zE('webWidget', 'close'); // Close the chat
      } else {
        window.zE('webWidget', 'open'); // Open the chat
      }
      setIsChatOpen(!isChatOpen); // Toggle state
    } else {
      console.error('Zendesk Chat not loaded yet!');
    }
  };

  return (
    <div className="relative hidden md:flex">
      <Button ref={buttonRef}
        onClick={toggleZendeskChat} fab className={`roi-zendesk-btn ${isChatOpen ? 'roi-zendesk-btn-active' : ''}`} variant="light">
        <SioIcon name={isChatOpen ? 'close' : 'zendesk'} size={16} />
      </Button>
    </div>
  );
};

export default ZendeskButton;
