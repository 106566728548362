import React from "react";

const RightSideShimmer = () => {
  return (
    <div>
      {/* Right side messages */}
      <div className="flex items-start gap-2.5 justify-end">
        <div className="flex flex-col gap-2.5 w-2/3">
          <div className="h-3 bg-gray-200 rounded-full dark:bg-gray-200 w-32 ml-auto" />
          <div className="space-y-2.5">
            <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-200 w-56 ml-auto" />
            <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-200 w-44 ml-auto" />
          </div>
        </div>
        <div className="w-8 h-8 bg-gray-200 rounded-full dark:bg-gray-200" />
      </div>
    </div>
  );
};

export default RightSideShimmer;
