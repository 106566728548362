const PADLOCK_URL = "https://api.trypadlock.com";
export const postSignup = async (payload) => {
  try {
    const resp = await fetch(`${PADLOCK_URL}/padlockAdmin/register`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-type": "application/json",
      },
      body: JSON.stringify(payload),
    });

    const updatedData = await resp.json();
    return {
      status: resp?.status,
      data: updatedData,
    };
  } catch (error) {
    console.error("error", error.message);
  }
};

export const postObserverSignup = async (payload) => {
  try {
    const resp = await fetch(`${PADLOCK_URL}/padlockAdmin/observer_register`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-type": "application/json",
      },
      body: JSON.stringify(payload),
    });

    const updatedData = await resp.json();
    return {
      status: resp?.status,
      data: updatedData,
    };
  } catch (error) {
    console.error("error", error.message);
  }
};

export const postEmployeeSignup = async (payload) => {
  try {
    const resp = await fetch(`${PADLOCK_URL}/padlockAdmin/employee_register`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-type": "application/json",
      },
      body: JSON.stringify(payload),
    });

    const updatedData = await resp.json();
    return {
      status: resp?.status,
      data: updatedData,
    };
  } catch (error) {
    console.error("error", error.message);
  }
};

export const postManagerSignup = async (payload) => {
  try {
    const resp = await fetch(`${PADLOCK_URL}/padlockAdmin/manager_register`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-type": "application/json",
      },
      body: JSON.stringify(payload),
    });

    const updatedData = await resp.json();
    return {
      status: resp?.status,
      data: updatedData,
    };
  } catch (error) {
    console.error("error", error.message);
  }
};

export const getTokenStatus = async (payload) => {
  try {
    const resp = await fetch(`${PADLOCK_URL}/padlockAdmin/invite_status`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-type": "application/json",
      },
      body: JSON.stringify(payload),
    });

    const updatedData = await resp.json();
    return {
      status: resp?.status,
      data: updatedData,
    };
  } catch (error) {
    console.error("error", error.message);
  }
};

export const updateTokenStatus = async (payload) => {
  try {
    const res = await fetch(`${PADLOCK_URL}/padlockAdmin/invite_status`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    });

    const updatedData = await res.json();
    return {
      status: res?.status,
      data: updatedData,
    };
  } catch (error) {
    console.error("Error updating data:", error.message);
  }
};

export const getIpAddress = async () => {
  const url = `${PADLOCK_URL}/padlockAdmin/get-ip`;
  try {
    const res = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    const updatedData = await res.json();

    return {
      status: res?.status,
      data: updatedData,
    };
  } catch (error) {
    console.error("Error", error.message);
  }
};
