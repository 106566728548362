import React, { useState } from "react";
import { Autocomplete, TextField, MenuItem } from "@mui/material";
import { useTimezoneSelect, allTimezones } from "react-timezone-select";

const TimezoneSelect1 = ({ selectedTimezone, handleTimezoneChange }) => {
  const [timezone, setTimezone] = useState(
    selectedTimezone || "America/Los_Angeles"
  );

  const { options } = useTimezoneSelect({
    labelStyle: "original",
    timezones: allTimezones,
  });

  const [inputValue, setInputValue] = useState("");

  const handleChange = (event, newValue) => {
    if (newValue) {
      setTimezone(newValue.value);
      handleTimezoneChange(newValue.value);
    }
  };

  const handleInputChange = (event, newInputValue) => {
    setInputValue(newInputValue);
  };

  const handleKeyDown = (event) => {
    if (
      (event.key === "Backspace" || event.key === "Delete") &&
      timezone &&
      !inputValue
    ) {
      event.preventDefault();
    }
  };

  return (
    <Autocomplete
      value={options.find((option) => option.value === timezone) || null}
      onChange={(event, newValue) => handleChange(event, newValue)}
      inputValue={inputValue}
      onInputChange={handleInputChange}
      onKeyDown={handleKeyDown}
      options={options}
      getOptionLabel={(option) => option.label}
      renderInput={(params) => (
        <TextField {...params} label="Time Zone" variant="outlined" />
      )}
      renderOption={(props, option) => (
        <MenuItem {...props} key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      )}
      fullWidth
      disableClearable
      clearOnEscape={false}
      disableCloseOnBlur
    />
  );
};

export default TimezoneSelect1;
