import React from "react";

const LeftSideShimmer = () => {
  return (
    <div>
      {/* Left side messages */}
      <div className="flex items-start gap-2.5">
        <div className="w-8 h-8 bg-gray-200 rounded-full dark:bg-gray-200" />
        <div className="flex flex-col gap-2.5 w-2/3">
          <div className="h-3 bg-gray-200 rounded-full dark:bg-gray-200 w-32" />
          <div className="space-y-2.5">
            <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-200 w-48" />
            <div className="h-2 bg-gray-200 rounded-full dark:bg-gray-200 w-40" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LeftSideShimmer;
