/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from "react";
import FilesUploaded from "./files-uploaded/files-uploaded";
import QuestionPrompts from "./question-prompts/question-prompts";
import DataSourceFiles from "./teacher/files/dataSourceFiles";
import {
  getFilesList,
  getQuestionPromptsData,
} from "../../apis/recommendation/recommendation";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import { DataContext } from "../../components/layout/layout";
import SioIcon from "../../components/icons/IconLibrary";
import Button from "../../components/button";
import { getCookie } from "../../utils/cookieHelper";

const DataSource = () => {
  const {
    isArchivedDataSources,
    setIsArchivedDataSources,
    setHighlightField,
    isFilesType,
    setIsFilesType,
    dataSource,
    setDataSource,
    filesBasedSubTopics,
  } = useContext(DataContext);

  const [questionPrompts, setQuestionPrompts] = useState(null);
  const adminRoles = ["site-admin", "district-admin", "admin", "school-admin"];
  const [isAdminView, setIsAdminView] = useState(false);
  const location = useLocation();
  const path = location.pathname;
  const [isTeacherView, setIsTeacherView] = useState(false);
  const [adminRole, setAdminRole] = useState(false);

  const getFileNames = async () => {
    const files = await getFilesList();
    const filteredFileData = files.filter(
      (file) =>
        file.original_file_name.includes(".csv") ||
        file.original_file_name.includes(".json")
    );
    setDataSource(filteredFileData.filter((file) => !file.archive_status));
  };

  const getQuestionPrompts = async () => {
    const data = await getQuestionPromptsData();
    setQuestionPrompts(data);
  };

  useEffect(() => {
    getFileNames();
    getQuestionPrompts();
    setHighlightField("DataSource");
    setIsArchivedDataSources(() => {
      const value = path.split("/").includes("archived-data-sources")
        ? true
        : false;
      return value;
    });
    setIsFilesType(() => {
      const value = path.split("/").includes("file-types") ? true : false;
      return value;
    });

    const checkRole = () => {
      const userRole = getCookie("user_role") || "teacher";

      if (adminRoles.includes(userRole?.toLowerCase())) {
        setIsAdminView(true);
        setAdminRole(true);
      } else {
        setIsAdminView(false);
        setAdminRole(false);
      }
    };
    checkRole();
  }, []);

  const tabs = [
    {
      name: "Files Uploaded",
    },
    {
      name: "Question Prompts",
    },
  ];

  const handleToggle = () => {
    setIsTeacherView((prev) => !prev);
    setIsAdminView((prev) => !prev);
  };

  const [activeTab, setActiveTab] = useState(tabs[0].name);
  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };

  const s3KeysData =
    dataSource &&
    dataSource.map((item) => ({
      name: item.s3_key,
      date: item.created_at,
    }));

  const navigate = useNavigate();

  const handleBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    const handlePopState = (event) => {
      if (event.target.location.pathname.includes("archived-data-sources")) {
        setIsArchivedDataSources(true);
        setIsFilesType(false);
      } else if (event.target.location.pathname.includes("file-types")) {
        setIsArchivedDataSources(false);
        setIsFilesType(true);
      } else {
        setIsArchivedDataSources(false);
        setIsFilesType(false);
      }
    };

    window.addEventListener("popstate", handlePopState);

    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, []);

  return (
    <div className="bg-white h-full rounded-lg justify-center">
      <div className="flex flex-col h-full">
        <div className="items-start md:items-center flex shadow-md p-3 !pb-0 md:p-5 relative z-10">
          <div>
            {isArchivedDataSources ? (
              <h1 className="font-normal text-lg md:text-xl text-primary-dark flex gap-3 items-center mb-4">
                <button
                  className="soi-btn soi-btn-fab soi-btn-flat btn-dark size-9"
                  onClick={() => {
                    setIsArchivedDataSources(false);
                    setIsFilesType(false);
                    handleBack();
                  }}
                >
                  <SioIcon name="leftArrow" size={18} />
                </button>{" "}
                Archived Data Source
              </h1>
            ) : (
              !isFilesType && (
                <h1 className="font-normal text-lg md:text-xl text-primary-dark">
                  Data Source
                </h1>
              )
            )}

            {!isAdminView && (
              <p className="text-secondary-dark text-xs mt-1 md:mt-0 md:text-sm py-[1.15rem]">
                You can check/unchek the data sources to get customized
                suggestions.
              </p>
            )}
            {isAdminView && !isArchivedDataSources && !isFilesType && (
              <div className="sio-tabs -mx-3 mt-3">
                {tabs.map((tab, index) => (
                  <button
                    key={index}
                    className={`sio-tab-link ${
                      activeTab === tab.name
                        ? "active relative text-primary-dark before:absolute before:h-[3px] before:rounded-full before:bg-primary before:w-14 before:bottom-0"
                        : "text-muted-dark relative"
                    }`}
                    onClick={() => handleTabClick(tab.name)}
                  >
                    {tab.name}
                    {tab.new === true && (
                      <span className="bg-warning size-[6px] absolute rounded-full top-3"></span>
                    )}
                  </button>
                ))}
              </div>
            )}
          </div>
          <div className="flex ml-auto">
            {isAdminView && (
              <Link
                to="file-types"
                onClick={() => {
                  setIsFilesType(true);
                  setIsArchivedDataSources(false);
                }}
                className="sio-btn-link btn-dark !text-sm lg:text-sm p-2 absolute right-0 bottom-1 lg:bottom-0 lg:relative text-primary-dark lg:me-5"
              >
                  File Types
              </Link>
            )}
            {adminRole && !isFilesType && !isArchivedDataSources && (
              <label class="items-center cursor-pointer ms-auto mb-3 absolute  max-lg:right-2 max-lg:top-2 flex lg:relative">
                <input
                  type="checkbox"
                  value=""
                  class="sr-only peer"
                  checked={!isTeacherView}
                  onChange={handleToggle}
                />
                <span class="me-3 text-xs xl:text-[13px] whitespace-nowrap uppercase font-montserrat font-medium text-primary-dark  peer-checked:text-muted-dark hidden md:inline-flex">
                  Teacher View
                </span>
                <span class="uppercase text-[10px] visible md:hidden me-2 text-muted-dark">
                  {isTeacherView && "Teacher View"}
                  {!isTeacherView && "Admin View"}
                </span>
                <div class="relative w-12 md:w-16 h-6 md:h-9 border rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full after:content-[''] after:absolute after:top-[3px] md:after:top-[5px] after:start-[7px] md:after:start-[7px] after:bg-primary after:border-primary-800 after:border after:rounded-full after:size-4 md:after:size-6 after:transition-all dark:border-gray-600"></div>
                <span class="ms-3 text-xs xl:text-[13px] whitespace-nowrap uppercase font-montserrat font-medium text-muted-dark peer-checked:text-primary-dark hidden md:inline-flex">
                  Admin View
                </span>
              </label>
            )}
          </div>
        </div>
        {<Outlet />}
        {isArchivedDataSources || isFilesType ? null : !isAdminView ? ( // <Outlet />
          <DataSourceFiles dataSource={dataSource} />
        ) : (
          <>
            {activeTab === "Files Uploaded" && (
              <>
                {(!isArchivedDataSources || !isFilesType) && (
                  <FilesUploaded
                    dataSource={dataSource}
                    filesBasedSubTopics={filesBasedSubTopics}
                  />
                )}
              </>
            )}
            {activeTab === "Question Prompts" && (
              <QuestionPrompts
                s3KeysData={s3KeysData}
                questionPrompts={questionPrompts}
                setQuestionPrompts={setQuestionPrompts}
                dataSource={dataSource}
              />
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default DataSource;
