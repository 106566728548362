import { ThemeProvider } from "@mui/material/styles";
import React, { useState } from "react";
import OTPInput from "react-otp-input";
import { darkTheme } from "../../theme";
import Button from "../../components/button";
import signupLogo from "../../assets/logo.svg";
import { loginUser, verifyLogin } from "../../apis/login/login";
import jwt_decode from "jwt-decode";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { addAccount } from "../../apis/organization/index";
import { clearTokens } from "../../utils/authUtils";
import { URL_POSTFIX } from "../../constants/constant";
import SioIcon from "../../components/icons/IconLibrary";
import { setCookie, getCookie } from "../../utils/cookieHelper";
import { getOnboardingStatus } from "../../apis/onboarding/index";
const Otp = (props) => {
  const [otp, setOtp] = useState("");
  const navigate = useNavigate();

  const { email, selectedOrg, selectedOrgUuid } = props;

  const handleLogin = async () => {
    let updatedData = await verifyLogin({
      email: email,
      org_name: selectedOrg,
      org_uuid: selectedOrgUuid,
      otp: otp,
    });
    if (updatedData.status === 200) {
      const decodedToken = jwt_decode(updatedData?.data?.token);
      if (decodedToken !== undefined) {
        const user = decodedToken.data;
        let userDetails = {
          org_uuid: user.org_uuid,
          user_uuid: user.user.user_uuid,
          email: user.user.email,
        };

        const addPayload = {
          email: email,
          org_name: selectedOrg,
          org_uuid: selectedOrgUuid,
          user_uuid: user.user.user_uuid,
        };
        const deviceUuid = getCookie("device_uuid");
        if (deviceUuid) {
          addPayload.device_uuid = deviceUuid;
        }
        const addNewacc = await addAccount(addPayload);
        if (addNewacc.status === 200) {
          if (addNewacc?.data?.device_uuid) {
            setCookie("device_uuid", addNewacc?.data?.device_uuid);
            setCookie("device_id", addNewacc?.data?.id);
          }
        }
        clearTokens();

        setCookie("token", updatedData?.data?.token);
        setCookie("refresh_token", updatedData?.data?.refresh_token);
        setCookie("user_email", user.user.email);
        setCookie("org", selectedOrg);
        setCookie("user", JSON.stringify(userDetails));
        setCookie("userName", user?.userdetails?.user_data?.first_name);
        setCookie("org_uuid", userDetails?.org_uuid);
        const userRole = user?.roles ? user?.roles : user?.userdetails?.roles;
        setCookie("user_role", userRole);

        const onboardingresp = await getOnboardingStatus({
          org_uuid: selectedOrgUuid,
          user_uuid: user.user.user_uuid,
        });

        if (onboardingresp?.status === 200) {
          const onboardingStatus = onboardingresp?.data?.status;
          if (URL_POSTFIX === "localhost") {
            onboardingStatus ? navigate("/") : navigate("/onboarding");
          } else {
            window.location.href = onboardingStatus
              ? `https://${selectedOrg}${URL_POSTFIX}/`
              : `https://${selectedOrg}${URL_POSTFIX}/onboarding`;
          }
        } else {
          if (URL_POSTFIX === "localhost") {
            navigate("/onboarding");
          } else {
            window.location.href = `https://${selectedOrg}${URL_POSTFIX}/onboarding`;
          }
        }
      }
    } else {
      let toastMessage;
      if (updatedData?.data?.message === "Incorrect OTP") {
        toastMessage = "The  6-digit code you entered is incorrect. Please reconfirm the code and try again.";
      } else if (updatedData?.data?.message === "otp got expired") {
        toastMessage =
          "Verification code has expired. Click Resend to generate a new code and try again";
      }
      toast.error(toastMessage, {
        theme: "light",
        icon: <SioIcon name="close" size={16} color="blue" />,
      });
    }
  };

  const handleKeyDown = (e) => {
    console.log(e);
    if (e.key === "Enter") {
      handleLogin();
    }
  };

  const handleEmailContinue = async () => {
    try {
      const loginResp = await loginUser({
        email: email,
        org_name: selectedOrg,
        org_uuid: selectedOrgUuid,
      });
      setOtp("");
      if (loginResp?.status === 200) {
        toast.success("Verification code is resent to your email. Please check your email.", {
          theme: "light",
          icon: <SioIcon name="check" size={16} color="blue" />,
        });
      } else {
        toast.error("Failed to resend the OTP", {
          theme: "light",
          icon: <SioIcon name="close" size={16} color="blue" />,
        });
      }
    } catch (e) {
      toast.error(e?.message, {
        theme: "light",
        icon: <SioIcon name="close" size={16} color="blue" />,
      });
    }
  };

  return (
    <ThemeProvider theme={darkTheme}>
      <div className="bg-dark min-h-screen flex flex-col xs:p-2">
        <div className="sio-col-460 mb-auto">
          <div className="text-center px-12 pb-8 flex flex-col justify-center">
            <img
              src={signupLogo}
              alt="signup-page-logo"
              className="mt-12 mb-3 xs:h-9"
            />
              <p className="text-secondary-light text-xs sm:text-sm">
              Strived combines student data with district goals to deliver classroom insights
              </p>
          </div>
          <div className="sio-card bg-white/5 rounded-xl">
            <div className="text-center">
              <h1>Verify Your Account</h1>
              <p className="text-secondary-light text-xs sm:text-sm">
              We've emailed you a 6-digit code. Please enter it in the box below to verify your account. If you don't see the email, please check your spam or junk folder.
              </p>
            </div>

            <OTPInput
              value={otp}
              onChange={setOtp}
              numInputs={6}
              shouldAutoFocus={true}
              renderInput={(props) => (
                <input
                  {...props}
                  style={{ backgroundColor: "inherit" }}
                  type="number"
                  className="w-full h-14 appearance-none [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none [&::-moz-appearance:textfield] text-2xl text-center border border-gray-500 rounded-md bg-black
                  focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-pink-500 text-white"
                  onKeyDown={handleKeyDown}
                />
              )}
              containerStyle="flex space-x-3"
            />

            <Button
              variant="secondary"
              disabled={otp.length < 6}
              onClick={() => {
                handleLogin();
              }}
            >
              Verify
            </Button>
            <p className="text-secondary-light text-xs sm:text-sm text-center">
              Not received the code?{" "}
              <span
                className="sio-link-secondary hover:cursor-pointer"
                onClick={() => handleEmailContinue()}
              >
                Request to Resend
              </span>
            </p>
          </div>
          <div className="sio-card bg-secondary-gradient xs:-mt-5 sm:-mt-10 xs:pt-10 sm:pt-[70px]">
            <p className="grow basis-0 text-secondary-light font-medium xs:text-xs text-center">
               <Link className="sio-link-secondary" to="mailto:hello@strived.io">Contact us</Link>, If you continue to have issues
            </p>
          </div>
        </div>
        <footer className="flex justify-center p-3">
          <ul className="flex divide-x text-sm divide-light font-medium leading-none flex-wrap justify-center">
            <li className="px-2 xs:my-1 sm:my-0">
              <a
                className="text-muted-light hover:text-[color:#cf037e]"
                href="/"
              >
                Terms of Use
              </a>
            </li>
            <li className="px-2 xs:my-1 sm:my-0">
              <a
                className="text-muted-light hover:text-[color:#cf037e]"
                href="/"
              >
                Privacy Policy
              </a>
            </li>
            <li className="px-2 xs:my-1 sm:my-0 text-muted-light xs:border-0 md:border-1">
              ©{(new Date().getFullYear())} Strived.io, All Rights Reserved
            </li>
          </ul>
        </footer>
      </div>
    </ThemeProvider>
  );
};

export default Otp;
