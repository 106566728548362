import { CircularProgress, Tooltip } from "@mui/material";
import React, { useRef, useState } from "react";

import { toast } from "react-toastify";
import {
  getChatHistory,
  getRecommendationById,
} from "../../../apis/recommendation/recommendation";
import logoicon from "../../../assets/logo-black.png";
import { getUserNames } from "../../../utils";
import Button from "../../button";
import SioIcon from "../../icons/IconLibrary";
import { generatePDF } from "../pdfUtils";
import MarkdownPdfComponent from "../ui/MarkdownPdfComponent";

const PDFGeneratorFetchData = ({
  currentFileName,
  isRenderingDownLoadButton,
  setIsRenderingDownLoadButton,
  recommendationIdSelected,
  setIsLoadButton,
  isLoadingButton,
  initialValue,
}) => {
  const pdfRef = useRef(null);
  const [recData, setRecData] = useState([]);

  const fetchChatHistory = async () => {
    setIsRenderingDownLoadButton(true);
    const nextQueryParams = new URLSearchParams();
    nextQueryParams.append("recommendation_id", recommendationIdSelected);
    nextQueryParams.append("page", 1);
    nextQueryParams.append("per_page", 50);

    const { data } = await getUserNames();
    nextQueryParams.append("user_id", data?.user?.id);

    try {
      const { history } = await getChatHistory(nextQueryParams);
      if (history && history.length > 0) {
        let pushData = [];
        history.forEach((value) => {
          pushData.unshift(
            value.type === "ai"
              ? {
                  response: value?.content,
                  id: value?.id,
                  likes: value?.likes,
                  disLikes: value?.dislikes,
                  created_at: value?.created_at,
                  qualitative_saved: value?.qualitative_saved,
                  quantitative_saved: value?.quantitative_saved,
                }
              : {
                  input: {
                    content: value?.content,
                    id: value?.id,
                    isSaved: false,
                  },
                  id: value?.id,
                  likes: value?.likes,
                  disLikes: value?.dislikes,
                  created_at: value?.created_at,
                  saved_status: value?.saved_status,
                  qualitative_saved: value?.qualitative_saved,
                  quantitative_saved: value?.quantitative_saved,
                }
          );
        });
        setRecData(pushData);

        await new Promise((resolve) => setTimeout(resolve, 1000));

        generatePDF(
          pdfRef,
          currentFileName,

          setIsRenderingDownLoadButton
        );
      } else if (recommendationIdSelected) {
        const recQueryParams = new URLSearchParams();
        recQueryParams.append("recommendation_id", recommendationIdSelected);

        const historyRecommendation = await await getRecommendationById(
          recQueryParams
        );
        if (historyRecommendation && historyRecommendation?.length > 0) {
          let pushData = [];
          historyRecommendation.forEach((value) => {
            pushData.unshift({
              response: value?.content,
              id: value?.id,
              likes: 0,
              disLikes: 0,
              created_at: value?.created_at,
              qualitative_saved: false,
              quantitative_saved: false,
            });
          });
          setRecData(pushData);

          await new Promise((resolve) => setTimeout(resolve, 1000));

          generatePDF(
            pdfRef,
            currentFileName,

            setIsRenderingDownLoadButton
          );
        }
      } else {
        setIsRenderingDownLoadButton(false);

        toast.info("No data to download");
      }
    } catch (err) {
      setIsRenderingDownLoadButton(false);

      toast.error("Error:", err, {
        icon: <SioIcon name="close" size={16} color="blue" />,
      });
    }
  };

  return (
    <div id="my-id">
      <div
        ref={pdfRef}
        style={{
          display: !isRenderingDownLoadButton ? "none" : "block",
          position: "absolute",
          top: "-9999px",
          left: "-9999px",
          width: "794px",
          padding: "40px",
          backgroundColor: "#ffffff",
          fontFamily: "'Roboto', Arial, sans-serif",
          fontSize: "14px",
          lineHeight: "1.8",
          letterSpacing: "0.9px",
          wordSpacing: "0.5px",
          color: "#000000",
        }}
      >
        <div className="flex items-center justify-center">
          <img className="h-12 mb-1" src={logoicon} alt="strived.io-logo" />
        </div>
        {/* 
        <h4
          style={{
            marginBottom: "32px",
            fontWeight: "400",
            color: "#2D3748",
          }}
        >
          {currentFileName}
        </h4> */}

        {recData != null && (
          <MarkdownPdfComponent
            markDownContainerData={recData}
            filename={currentFileName}
          />
        )}
      </div>
      {!isRenderingDownLoadButton ||
      isLoadingButton !== recommendationIdSelected ? (
        <Tooltip title="Download Response">
          <Button
            disabled={isRenderingDownLoadButton}
            fab
            outline
            variant="dark"
            onClick={() => {
              setIsLoadButton(recommendationIdSelected);
              fetchChatHistory();
            }}
          >
            <SioIcon name="download" size={16} />
          </Button>
        </Tooltip>
      ) : (
        <CircularProgress size="32px" color="success" />
      )}
    </div>
  );
};

export default PDFGeneratorFetchData;
