// Loader.js
import React from 'react';
import logoicon from "../assets/strived-logo-icon.svg";

const SioLoader = () => {
  return (
    <div class="showbox">
      <div class="sio-loader">
         <img
            className="size-6 sio-loader-logo"
            src={logoicon}
            alt="strived.io"
          />
        <svg class="sio-loader-circular" viewBox="25 25 50 50">
          <circle class="path" cx="50" cy="50" r="20" fill="none" stroke-width="3" stroke-miterlimit="10"/>
        </svg>
      </div>
    </div>
  );
};

export default SioLoader;
