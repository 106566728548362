/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import Button from "../../components/button";
import MarkdownComponent from "../../components/MarkdownComponent";
import {
  addMessageToSession,
  getSharedQuestionAndAnswer,
  saveAiChildRecommendations,
  startConversation,
  getChatHistory,
} from "../../apis/recommendation/recommendation";
import { toast } from "react-toastify";
import { checkAndRefreshToken } from "../../utils/authUtils";
import { useLocation, useNavigate } from "react-router-dom";
import { lightTheme } from "../../theme";
import { ThemeProvider } from "@emotion/react";
import { Menu, MenuItem } from "@mui/material";
import SioIcon from "../../components/icons/IconLibrary";
import SioTooltipClampedText from "../../components/TooltipClampedText";
// import { getUserNames } from "../../utils";
import { getCookie } from "../../utils/cookieHelper";

const MessagePage = () => {
  const location = useLocation();
  const { sessionId, promptData, fileName, aiChildRecommendation, message_id } =
    location.state || {};

  const [aiChildRec, setAiChildRec] = useState(aiChildRecommendation);
  const [isLoading, setIsLoading] = useState(false);
  const [recData, setRecData] = useState([]);
  const [scrollToTop, setScrollToTop] = useState(false);
  const [savedAiChild, setSavedAiChild] = useState(null);
  const [updateSessionId, setUpdateSessionId] = useState(sessionId);
  const divRef = useRef(null);
  const navigate = useNavigate();
  const [loadingPromptResponse, setLoadingPromptResponse] = useState(false);
  const [filenames, setFilenames] = useState(null);

  const searchParams = new URL(window.location.href).searchParams;
  const messageId = searchParams.get("messageId");
  const newSessionId = searchParams.get("sessionId");
  const userId = searchParams.get("user_id");
  const hostname = window.location.hostname;
  const isLocal = hostname === "localhost";
  const subdomain = isLocal ? null : hostname.split(".")[0].toLowerCase();
  const organizationName = getCookie("org")
    ? getCookie("org").toLowerCase()
    : null;

  const fetchChatHistory = async () => {
    try {
      const token = await checkAndRefreshToken();
      if (!token) navigate("/login");

      if (newSessionId) {
        const nextQueryParams = new URLSearchParams();
        nextQueryParams.append("page", 1);
        nextQueryParams.append("per_page", 25);
        nextQueryParams.append("session_id", newSessionId);

        // const { data } = await getUserNames();
        nextQueryParams.append("user_id", userId);
        const { history } = await getChatHistory(nextQueryParams, token);
        console.log(history);

        if (history && history.length > 0) {
          let pushData = [];
          history.forEach((value) => {
            pushData.unshift(
              value.type === "ai"
                ? {
                    response: value?.content,
                    id: value?.id,
                    likes: value?.likes,
                    disLikes: value?.dislikes,
                    created_at: value?.created_at,
                    qualitative_saved: value?.qualitative_saved,
                    quantitative_saved: value?.quantitative_saved,
                  }
                : {
                    input: { content: value?.content, id: value?.id },
                    id: value?.id,
                    likes: value?.likes,
                    disLikes: value?.dislikes,
                    created_at: value?.created_at,
                    saved_status: value?.saved_status,
                    qualitative_saved: value?.qualitative_saved,
                    quantitative_saved: value?.quantitative_saved,
                  }
            );
          });
          setRecData((prevArray) => [...pushData, ...prevArray]);
          setIsLoading(false);
        } else {
          setRecData([]);
          setIsLoading(false);
        }
      } else {
        const prompt = new URLSearchParams();
        prompt.append("message_id", messageId - 1);
        const { question, answer } = await getSharedQuestionAndAnswer(prompt);
        if (!question || !answer) {
          setRecData([]);
          return;
        }

        let pushData =
          question?.session_id === answer?.session_id
            ? [
                {
                  input: { content: question?.content, id: question?.id },
                  id: question?.id,
                  likes: question?.likes,
                  disLikes: question?.dislikes,
                  created_at: question?.created_at,
                  qualitative_saved: question?.qualitative_saved,
                  quantitative_saved: question?.quantitative_saved,
                },
                {
                  response: answer?.content,
                  id: answer?.id,
                  likes: answer?.likes,
                  disLikes: answer?.dislikes,
                  created_at: answer?.created_at,
                  saved_status: answer?.saved_status,
                  qualitative_saved: answer?.qualitative_saved,
                  quantitative_saved: answer?.quantitative_saved,
                },
              ]
            : [
                {
                  response: answer?.content,
                  id: answer?.id,
                  likes: answer?.likes,
                  disLikes: answer?.dislikes,
                  created_at: answer?.created_at,
                  saved_status: answer?.saved_status,
                  qualitative_saved: answer?.qualitative_saved,
                  quantitative_saved: answer?.quantitative_saved,
                },
              ];
        setRecData(pushData);
      }
    } catch (err) {
      setIsLoading(false);
      setRecData([]);

      toast.error("Error:", err, {
        icon: <SioIcon name="close" size={16} color="blue" />,
      });
    }
    setIsLoading(false);
  };

  useEffect(() => {
    setRecData([
      {
        response: promptData,
        id: message_id,
      },
    ]);
    setFilenames(fileName);
    fetchChatHistory();
  }, []);

  useEffect(() => {
    setSavedAiChild(null);
    const scrollToResponse = () => {
      if (divRef.current && !scrollToTop) {
        const container = divRef.current;
        const lastDiv = container.lastElementChild.lastElementChild;
        if (lastDiv) {
          lastDiv.scrollIntoView({ behavior: "smooth", block: "start" });
          const scrollPosition =
            lastDiv.offsetTop +
            lastDiv.offsetHeight -
            lastDiv.getBoundingClientRect().height -
            container.offsetHeight;

          container.scrollTo({
            top: scrollPosition + 90,
            behavior: "smooth",
          });
        }
      }
    };
    scrollToResponse();
  }, [recData]);

  const getPromptResponse = async (value) => {
    try {
      setLoadingPromptResponse(true);
      const token = await checkAndRefreshToken();
      if (!token) navigate("/login");

      if (!updateSessionId) {
        const data = await startConversation(0, token);
        const data2 = await addMessageToSession(
          data.session_id,
          value,
          token,
          filenames
        );

        setRecData((prevArray) => {
          const updatedArray = [...prevArray];
          updatedArray[updatedArray.length - 1] = {
            response: data2?.answer,
            id: data2.message_id,
          };
          return updatedArray;
        });
        if (
          data2 &&
          data2?.qualitative_question !== null &&
          data2.quantitative_question !== null
        ) {
          const aiRec = [
            data2?.qualitative_question,
            data2?.quantitative_question,
            data2?.qualitative_saved,
            data2?.quantitative_saved,
          ];
          setAiChildRec(aiRec);
        }
        setUpdateSessionId(data.session_id);
      } else {
        const data2 = await addMessageToSession(
          updateSessionId,
          value,
          token,
          filenames
        );

        setRecData((prevArray) => {
          const updatedArray = [...prevArray];
          updatedArray[updatedArray.length - 1] = {
            response: data2?.answer,
            id: data2.message_id,
          };
          return updatedArray;
        });
        if (
          data2 &&
          data2?.qualitative_question !== null &&
          data2.quantitative_question !== null
        ) {
          const aiRec = [
            data2?.qualitative_question,
            data2?.quantitative_question,
            data2?.qualitative_saved,
            data2?.quantitative_saved,
          ];
          setAiChildRec(aiRec);
        }
        const date = new Date();
        const updatedData = { content: value };
        updatedData.created_at = date.toUTCString();
        updatedData.saved_status = false;
        // handlePromptValue(updatedData);
      }
      setLoadingPromptResponse(false);

      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setRecData((prevArray) => prevArray.slice(0, -2));
      toast.error("Error:", error, {
        icon: <SioIcon name="close" size={16} color="blue" />,
      });
      return null;
    }
  };

  const [anchorElUser, setAnchorElUser] = useState(null);
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const settings = [
    {
      name: "Share",
      icon: "share",
    },
    {
      name: "Save",
      icon: "save",
    },
    {
      name: "Download",
      icon: "download",
    },
  ];
  const Mobilesettings = [...settings];

  return (
    <div className="bg-white h-full rounded-lg flex justify-center">
      <div className="max-w-4xl mx-auto w-full flex flex-col px-2 mb-2 md:mb-5 md:px-5 sio-recommendation-contener">
        <div className={`h-full flex flex-col overflow-hidden items-center`}>
          <div className="flex flex-row overflow-hidden h-full">
            <div className={`flex shrink grow basis-0 flex-col`}>
              <div className="flex justify-between items-start pb-4 pt-4 md:pt-9 gap-3 md:gap-4">
                <div className="flex md:hidden">
                  <Button
                    outline
                    fab
                    variant="dark"
                    onClick={handleOpenUserMenu}>
                    <SioIcon name="more" size={16} />
                  </Button>
                  <ThemeProvider theme={lightTheme}>
                    <Menu
                      id="menu-appbar"
                      className=""
                      anchorEl={anchorElUser}
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      keepMounted
                      sx={{
                        mt: 4,
                      }}
                      open={Boolean(anchorElUser)}
                      onClose={handleCloseUserMenu}>
                      {Mobilesettings.map((setting) => (
                        <MenuItem
                          key={setting.name}
                          onClick={() => {
                            handleCloseUserMenu();
                          }}>
                          <i className="soi-icon">
                            <SioIcon name={setting.icon} size={14} />
                          </i>
                          {setting.name}
                        </MenuItem>
                      ))}
                    </Menu>
                  </ThemeProvider>
                </div>
              </div>
              <div
                id="container"
                ref={divRef}
                className={`overflow-y-scroll scrollbar-none py-3 md:py-5`}>
                <div className={`flex flex-col`}>
                  {isLocal || subdomain === organizationName ? (
                    recData && recData.length > 0 ? (
                      <MarkdownComponent
                        markDownContainerData={recData}
                        loadingPromptResponse={loadingPromptResponse}
                        session_id={updateSessionId}
                        filename={filenames}
                        setUpdatedRecData={setRecData}
                        isShared={true}
                      />
                    ) : (
                      <div className="text-gray-500 font-semibold text-center mt-4">
                        No Data available.
                      </div>
                    )
                  ) : (
                    <div className="text-red-500 font-semibold text-center mt-4">
                      🚫 Access Denied: You do not have permission to view this
                      data.
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="flex flex-col rounded-t-lg shadow-inputField pt-1 md:pt-3 mt-auto">
            {aiChildRec && aiChildRec.length > 0 && (
              <div className="flex justify-around text-xs gap-2 md:gap-5 mb-2 md:mb-5 overflow-x-scroll scrollbar-none">
                {!isLoading &&
                  aiChildRec?.map(
                    (value, index) =>
                      index <= 1 && (
                        <div
                          key={index}
                          className="flex relative min-w-60 md:w-1/2">
                          <div className="h-7 w-7 p-1 absolute bg-darkGreen text-white rounded-tl-lg rounded-br-lg left-0">
                            <SioIcon name="suggestionPromt" size={20} />
                          </div>
                          <div
                            onClick={async () => {
                              setIsLoading(true);
                              setRecData((prevArray) => {
                                const updatedArray = [
                                  ...prevArray,
                                  { input: value },
                                  { response: "Loading..." },
                                ];
                                return updatedArray;
                              });
                              setScrollToTop(false);
                              await getPromptResponse(value);
                              setIsLoading(false);
                            }}
                            className="bg-secondary-200 rounded-lg p-1 ps-9 md:p-3 md:ps-9 hover:cursor-pointer flex items-start">
                            {/* <p className="line-clamp-2">{value}</p> */}
                            <SioTooltipClampedText text={value} lines={2} />
                            <button
                              className="ms-2"
                              onClick={async (event) => {
                                event.stopPropagation();
                                try {
                                  const {
                                    quantitative_saved,
                                    qualitative_saved,
                                  } = await saveAiChildRecommendations(
                                    message_id,
                                    index === 0
                                      ? "qualitative_question"
                                      : "quantitative_question"
                                  );
                                  setSavedAiChild([
                                    qualitative_saved,
                                    quantitative_saved,
                                  ]);
                                  // handleSelectQuestion(`${value.id}${saved_status}`);
                                  toast(
                                    `Your question has been successfully.`,
                                    {
                                      icon: (
                                        <SioIcon
                                          name="check"
                                          size={16}
                                          color="blue"
                                        />
                                      ),
                                    }
                                  );
                                } catch (err) {
                                  toast.error("Filed to save the question", {
                                    icon: (
                                      <SioIcon
                                        name="close"
                                        size={16}
                                        color="blue"
                                      />
                                    ),
                                  });
                                }
                              }}>
                              {(recData[recData.length - 1]
                                ?.qualitative_saved &&
                                index === 0) ||
                              (savedAiChild &&
                                savedAiChild[0] &&
                                index === 0) ||
                              (recData[recData.length - 1]?.qualitative_saved &&
                                index === 1) ||
                              (savedAiChild &&
                                savedAiChild[1] &&
                                index === 1) ? (
                                <SioIcon
                                  name="saved"
                                  className="md:-mr-[4px] md:-mt-0.5"
                                  size={18}
                                />
                              ) : (
                                <SioIcon
                                  name="save"
                                  className="md:-mr-[4px] md:-mt-0.5"
                                  size={18}
                                />
                              )}
                            </button>
                          </div>
                        </div>
                      )
                  )}
              </div>
            )}
          </div>
        </div>
        <Button
          className="soi-btn-fab soi-btn-fab soi-btn-flat btn-lg ms-auto absolute top-3 right-2 lg:right-7 lg:top-7 hidden md:block"
          onClick={() => {
            navigate("/");
          }}>
          <SioIcon name="closeLight" size={24} />
        </Button>
      </div>
    </div>
  );
};

export default MessagePage;
