import React from "react";

const PdfTableContainer = ({ children }) => {
  if (!children) return;
  const updatedChildren = Array.isArray(children) ? children : [children];
  const thead = updatedChildren.find((child) => child.key.startsWith("thead"));
  const tbody = updatedChildren.find((child) => child.key.startsWith("tbody"));
  if (!tbody) return null;

  const theadChildren = Array.isArray(thead.props.children)
    ? thead.props.children
    : [thead.props.children];

  const theadList =
    theadChildren &&
    theadChildren.find((row) => row.type === "tr")?.props?.children;

  const thTitles = Array.isArray(theadList)
    ? theadList?.map((th) =>
        Array.isArray(th.props.children)
          ? th.props.children.join(" ")
          : th.props.children
      )
    : [theadList]?.map((th) =>
        Array.isArray(th.props.children)
          ? th.props.children.join(" ")
          : th.props.children
      );

  const rows = Array.isArray(tbody.props.children)
    ? tbody.props.children
    : [tbody.props.children];

  const currentRows = rows;

  const processedRows = currentRows.map((row) => {
    if (row.type === "tr" && Array.isArray(row.props.children)) {
      const updatedCells = row.props.children.map((cell, index) => {
        if (cell.type === "td" && thTitles.length >= 1 && thTitles[index]) {
          return React.cloneElement(cell, {
            "data-title": thTitles.length >= 1 && thTitles[index],
          });
        }
        return cell;
      });
      return React.cloneElement(row, {}, updatedCells);
    }
    return row;
  });

  return (
    <div className="sio-table-card-sm sio-card-shadow sio-card-sm !p-0 gap-0">
      <div className={`overflow-x-auto scrollbar-none`}>
        <table className="sio-table">
          {children.filter((child) => !child.key.startsWith("tbody"))}
          <tbody className="">{processedRows}</tbody>
        </table>
      </div>
    </div>
  );
};

export default PdfTableContainer;
