import React from "react";
import LeftSideShimmer from "./LeftSideShimmer";
import RightSideShimmer from "./RightSideShimmer";

const ChatShimmer = () => {
  return (
    <div className="w-full max-w-2xl space-y-4 animate-pulse">
      {/* Right side messages */}
      <RightSideShimmer />

      {/* Left side messages */}
      <LeftSideShimmer />

      {/* Right side messages */}
      <RightSideShimmer />

      {/* Left side messages */}
      <LeftSideShimmer />

      {/* Right side messages */}
      <RightSideShimmer />

      {/* Left side messages */}
      <LeftSideShimmer />

      <span className="sr-only">Loading chat...</span>
    </div>
  );
};

export default ChatShimmer;
