import { customFetch } from "../../utils/RefreshTokenInterceptor";

const PADLOCK_URL = "https://api.trypadlock.com";

export const getUserProfile = async () => {
  const url = `${PADLOCK_URL}/padlockAdmin/profile`;
  try {
    const res = await customFetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        // Authorization: `Bearer ${accessToken}`,
      },
    });

    const updatedData = await res.json();

    return {
      status: res?.status,
      data: updatedData,
    };
  } catch (error) {
    console.error("Error fetching user profile:", error.message);
  }
};

export const updateUserProfile = async (payload, accessToken) => {
  const url = `${PADLOCK_URL}/padlockAdmin/profile`;
  try {
    const res = await customFetch(url, {
      method: "PUT",
      headers: {
        // Authorization: `Bearer ${accessToken}`,
      },
      body: payload,
    });

    const updatedData = await res.json();

    return {
      status: res?.status,
      data: updatedData,
    };
  } catch (error) {
    console.error("Error updating user profile:", error.message);
  }
};
