import React, { useCallback, useContext, useEffect, useState } from "react";
import SioIcon from "./icons/IconLibrary";
import { Tooltip } from "@mui/material";
import { getUserNames } from "../utils";
import { DataContext } from "./layout/layout";
import {
  deleteConversations,
  feedbackDownvote,
  feedbackUpvote,
  // getDownvotes,
  // getUpvotes,
  regenerateRecommendation,
  regenerateResponse,
  shareMessage,
  shareRecommendations,
} from "../apis/recommendation/recommendation";
import { FiThumbsDown, FiThumbsUp } from "react-icons/fi";
import { customFetch } from "../utils/RefreshTokenInterceptor";
import { devApis } from "../constants/constant";
import { toast } from "react-toastify";
import ResponsePDFGenerator from "./pdf/buttons/ResponseDownloadPdf";
import { HiOutlineRefresh } from "react-icons/hi";

const FeedbackButtons = ({
  item,
  isShared,
  ind,
  session_id,
  setUpdatedRecData,
  hideFeedBackButtons,
  markDownContainerData,
  recData,
  isInitialRec,
  currentFileName,
  isFromAskMePage,
  category,
  setRecData,
  recId,
  setFilteredSuggestions,
  savedQuestion,
  filename,
}) => {
  const {
    setAMARefreshQuestion,
    setSuggestionsRefresh,
    setRefreshSavedQuestion,
  } = useContext(DataContext);

  const [likeMessageIds, setLikeMessageIds] = useState([]);
  const [dislikeMessageIds, setDislikeMessageIds] = useState([]);
  const [isRenderingDownLoadButton, setIsRenderingDownLoadButton] =
    useState(false);
  const [isLoadingButton, setIsLoadButton] = useState(null);

  useEffect(() => {
    if (markDownContainerData) {
      if (
        markDownContainerData[0]?.likes &&
        markDownContainerData[0]?.disLikes
      ) {
        setLikeMessageIds(
          markDownContainerData
            .filter((rec) => rec?.likes && rec?.likes.length > 0)
            .map((likedRec) => likedRec.id)
        );
        setDislikeMessageIds(
          markDownContainerData
            .filter((rec) => rec?.likes && rec?.disLikes.length > 0)
            .map((likedRec) => likedRec.id)
        );
      } else {
        if (!isFromAskMePage) {
          // getBaseRecommendationsFeedbackStatus(markDownContainerData[0]?.id);
        }
      }
    }
  }, [markDownContainerData, isFromAskMePage]);

  // const getBaseRecommendationsFeedbackStatus = async (id) => {
  //   try {
  //     const feedbackUpvote = await getUpvotes(id);
  //     const feedbackDownvote = await getDownvotes(id);

  //     if (feedbackUpvote?.feedback === "upvote") {
  //       setLikeMessageIds((prevIds) => [...prevIds, id]);
  //     }
  //     if (feedbackDownvote?.feedback === "downvote") {
  //       setDislikeMessageIds((prevIds) => [...prevIds, id]);
  //     }
  //   } catch (err) {
  //     console.error(err);
  //   }
  // };

  const handleLike = useCallback((id) => {
    setLikeMessageIds((prevLikeIds) => {
      const isAlreadyLiked = prevLikeIds.includes(id);

      if (isAlreadyLiked) {
        return prevLikeIds.filter((prevId) => prevId !== id);
      } else {
        setDislikeMessageIds((prevDislikeIds) =>
          prevDislikeIds.filter((prevId) => prevId !== id)
        );
        return [...prevLikeIds, id];
      }
    });
  }, []);

  const LikeButton = (props) => {
    return (
      <Tooltip title="Good Response">
        <button
          onClick={async () => {
            handleLike(recData[props.ind]?.id);
            if (props.recommendation?.likes || isFromAskMePage)
              await likeDislikeMessage(recData[props.ind]?.id, "like");
            else await feedbackUpvote(props.recommendation?.id);
          }}
          className={`sio-circle-icon bg-warning/20 size-8 shrink-0 
              hover:bg-warning ${
                likeMessageIds.includes(recData[props.ind]?.id)
                  ? "text-white !bg-black"
                  : "text-secondary-dark"
              }`}
        >
          <FiThumbsUp />
        </button>
      </Tooltip>
    );
  };

  const likeDislikeMessage = async (message_id, action) => {
    try {
      // const token = await checkAndRefreshToken();
      const { data } = await getUserNames();
      const res = await customFetch(
        `${devApis.PYTHON_BASE_URL}/like_dislike_message`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            // Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            message_id: message_id,
            action: action,
            user_id: data.user.id,
          }),
        }
      );

      if (res.status === 200 || res.status === 201) {
        await res.json();
      }
    } catch (error) {
      console.error("Error bookmarking recommendation:", error.message);
      toast.error("Error bookmarking recommendation:", error.message, {
        icon: <SioIcon name="close" size={16} color="blue" />,
      });
    }
  };

  const handleDislike = useCallback((id) => {
    setDislikeMessageIds((prevDislikeIds) => {
      const isAlreadyDisliked = prevDislikeIds.includes(id);

      if (isAlreadyDisliked) {
        return prevDislikeIds.filter((prevId) => prevId !== id);
      } else {
        setLikeMessageIds((prevLikeIds) =>
          prevLikeIds.filter((prevId) => prevId !== id)
        );
        return [...prevDislikeIds, id];
      }
    });
  }, []);

  // console.log(APIData);

  const DislikeButton = (props) => {
    return (
      <Tooltip title="Bad Response">
        <button
          onClick={async () => {
            handleDislike(recData[props.ind]?.id);
            if (props.recommendation?.disLikes || isFromAskMePage)
              await likeDislikeMessage(recData[props.ind]?.id, "dislike");
            else await feedbackDownvote(props.recommendation?.id);
          }}
          className={`sio-circle-icon bg-warning/20 size-8 shrink-0 
             hover:bg-warning ${
               dislikeMessageIds.includes(recData[props.ind]?.id)
                 ? "text-white !bg-black"
                 : "text-secondary-dark"
             }`}
        >
          <FiThumbsDown />
        </button>
      </Tooltip>
    );
  };

  const fullUrl = window.location.href;
  const modifiedURL = fullUrl.split("/").slice(0, 3).join("/");

  const handleShare = async (id) => {
    if (isInitialRec) {
      const { shareable_url } = await shareRecommendations(id, modifiedURL);
      const emailShareUrl = `mailto:?subject=Check out this data!&body=Check out this link: ${shareable_url}`;
      window.location.href = emailShareUrl;
    } else {
      const { shareable_url } = await shareMessage(id, modifiedURL);
      const encodedShareUrl = encodeURIComponent(shareable_url);

      const emailShareUrl = `mailto:?subject=Check out this data!&body=Check out this link: ${encodedShareUrl}`;
      window.location.href = emailShareUrl;
    }
  };

  return (
    <div>
      {!isShared &&
        item?.response &&
        item?.response !== "Loading..." &&
        !hideFeedBackButtons && (
          <div className="flex gap-3 mb-10 mt-2 items-center" id="list-buTtons">
            <LikeButton
              ind={ind}
              recommendation={item}
              handleLikeClick={handleLike}
            />
            <DislikeButton
              ind={ind}
              recommendation={item}
              handleDislikeClick={handleDislike}
            />
            <Tooltip title="Share via Email">
              <div className="text-gray-500 hover:text-gray-700 hover:cursor-pointer">
                <button
                  className="sio-circle-icon bg-warning/20 size-8 shrink-0 text-secondary-dark
                      hover:bg-warning"
                  onClick={() => handleShare(item?.id, ind)}
                >
                  <SioIcon name="share" size={14} />
                </button>
              </div>
            </Tooltip>
            {/* Download Pdf Button */}
            <ResponsePDFGenerator
              item={item}
              currentFileName={currentFileName}
              isRenderingDownLoadButton={isRenderingDownLoadButton}
              setIsRenderingDownLoadButton={setIsRenderingDownLoadButton}
              setIsLoadButton={setIsLoadButton}
              isLoadingButton={isLoadingButton}
              previousData={markDownContainerData[ind - 1]}
              isFromAskMePage={isFromAskMePage}
              markDownData={markDownContainerData}
              category={category}
            />
            <Tooltip title="Regenerate">
              <button
                className="sio-circle-icon bg-warning/20 size-8 shrink-0 text-secondary-dark
                      hover:bg-warning"
                onClick={async () => {
                  let currentItem;
                  if (item && item.id) {
                    currentItem = item?.id;
                  }
                  try {
                    setRecData((prevArray) => {
                      const updatedArray = [
                        ...prevArray.slice(0, ind),
                        { response: "Loading..." },
                      ];
                      return updatedArray;
                    });
                    setUpdatedRecData((prevArray) => {
                      const updatedArray = [
                        ...prevArray.slice(0, ind),
                        { response: "Loading..." },
                      ];
                      return updatedArray;
                    });

                    if (!session_id && ind === 0) {
                      await deleteConversations(recId?.current);

                      setRefreshSavedQuestion((prev) => {
                        return [
                          {
                            ...prev,
                            apiCallname: "Refresh",
                            payload: {
                              apiSessionId: session_id?.current
                                ? session_id?.current
                                : session_id,
                              apiRecId: recData[0]?.id,
                            },
                            status: true,
                          },
                        ];
                      });

                      const { new_recommendation } =
                        await regenerateRecommendation(
                          !item?.session_id ? item?.id : item.rec_id
                        );

                      setRecData((prevArray) => {
                        const updatedArray = [...prevArray];
                        updatedArray[updatedArray.length - 1] = {
                          response: new_recommendation,
                          id: !item?.session_id ? item?.id : item.rec_id,
                        };
                        return updatedArray;
                      });

                      setFilteredSuggestions((prevData) => {
                        return prevData.map((rec) =>
                          rec.id === item?.id
                            ? { ...rec, content: new_recommendation }
                            : rec
                        );
                      });

                      setUpdatedRecData((prevArray) => {
                        const updatedArray = [...prevArray];
                        updatedArray[updatedArray.length - 1] = {
                          response: new_recommendation,
                          id: !item?.session_id ? item?.id : item.rec_id,
                        };
                        return updatedArray;
                      });
                    } else {
                      const getPromtIndex =
                        recData.findIndex((item) => item.id === currentItem) -
                        1;

                      let input;
                      if (savedQuestion) {
                        input = savedQuestion;
                      } else {
                        input = recData[getPromtIndex]?.input;
                      }

                      setRefreshSavedQuestion((prev) => {
                        return [
                          {
                            ...prev,
                            apiCallname: "Refresh",
                            payload: {
                              apiSessionId: session_id.current
                                ? session_id.current
                                : session_id,
                              apiRecId: recData[0]?.id,
                            },
                            status: true,
                            index: ind,
                          },
                        ];
                      });

                      setAMARefreshQuestion((prev) => {
                        return [
                          {
                            ...prev,
                            apiCallname: "Refresh",
                            payload: {
                              apiSessionId: session_id.current
                                ? session_id.current
                                : session_id,
                              apiRecId: recData[0]?.id,
                            },
                            index: ind,
                            status: true,
                          },
                        ];
                      });

                      setSuggestionsRefresh((prev) => {
                        return [
                          {
                            ...prev,
                            apiCallname: "Refresh",
                            payload: {
                              apiSessionId: session_id.current
                                ? session_id.current
                                : session_id,
                              apiRecId: recData[0]?.id,
                            },
                            index: ind,
                            status: true,
                          },
                        ];
                      });

                      const response = await regenerateResponse(
                        item.id,
                        input,
                        session_id.current ? session_id.current : session_id,
                        filename
                      );

                      setRecData((prevArray) => {
                        const updatedArray = [...prevArray];
                        updatedArray[updatedArray.length - 1] = {
                          response: response.answer,
                          id: response.message_id,
                        };
                        return updatedArray;
                      });
                      setUpdatedRecData((prevArray) => {
                        const updatedArray = [...prevArray];
                        updatedArray[updatedArray.length - 1] = {
                          response: response.answer,
                        };
                        return updatedArray;
                      });
                      setAMARefreshQuestion((prev) => {
                        const data = prev.filter((item) => {
                          return (
                            item.payload.apiSessionId !== response.session_id
                          );
                        });
                        return data;
                      });

                      setSuggestionsRefresh((prev) => {
                        const data = prev.filter((item) => {
                          return (
                            item.payload.apiSessionId !== response.session_id
                          );
                        });
                        return data;
                      });
                      setRefreshSavedQuestion((prev) => {
                        const data = prev.filter((item) => {
                          return (
                            item.payload.apiSessionId !== response.session_id
                          );
                        });
                        return data;
                      });
                    }
                  } catch (error) {
                    setRecData((prevArray) => prevArray.slice(0, -2));
                    console.error("Error regenerating prompt:", error);
                  }
                }}
              >
                <HiOutlineRefresh />
              </button>
            </Tooltip>
          </div>
        )}
    </div>
  );
};

export default FeedbackButtons;
