const PADLOCK_URL = "https://api.trypadlock.com";

export const getOnboardingStatus = async (payload) => {
  const url = `${PADLOCK_URL}/padlockAdmin/onboarding?user_uuid=${payload.user_uuid}&org_uuid=${payload.org_uuid}`;
  try {
    const res = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    const updatedData = await res.json();

    return {
      status: res?.status,
      data: updatedData,
    };
  } catch (error) {
    console.error("Error fetching onboarding status:", error.message);
  }
};

export const postOnboardingStatus = async (payload) => {
  const url = `${PADLOCK_URL}/padlockAdmin/onboarding`;
  try {
    const res = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    });
    const updatedData = await res.json();
    return {
      status: res?.status,
      data: updatedData,
    };
  } catch (error) {
    console.error("Error updating onboarding status:", error.message);
  }
};
