import { customFetch } from "../../utils/RefreshTokenInterceptor";

const PADLOCK_URL = "https://api.trypadlock.com";
const { getCookie } = require("../../utils/cookieHelper");

export const getAccountsList = async () => {
  const device_uuid = getCookie("device_uuid");
  if (!device_uuid) {
    console.error("No device UUID found.");
    return {
      status: 200,
      data: { accounts: [] },
    };
  }
  const url = `${PADLOCK_URL}/userAccount`;
  try {
    const res = await customFetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({ device_uuid: device_uuid }),
    });

    const updatedData = await res.json();
    return {
      status: res?.status,
      data: updatedData,
    };
  } catch (error) {
    console.error("Error updating data:", error.message);
  }
};

export const addAccount = async (payload) => {
  const url = `${PADLOCK_URL}/userAccount/add`;
  try {
    const res = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    });
    const updatedData = await res.json();
    return {
      status: res?.status,
      data: updatedData,
    };
  } catch (error) {
    console.error("Error adding data:", error.message);
  }
};

export const removeAccount = async (payload) => {
  const url = `${PADLOCK_URL}/userAccount/delete`;
  try {
    const res = await customFetch(url, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        // Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify(payload),
    });
    const updatedData = await res.json();
    return {
      status: res?.status,
      data: updatedData,
    };
  } catch (error) {
    console.error("Error removing data:", error.message);
  }
};

export const removeAllAccounts = async (payload) => {
  const url = `${PADLOCK_URL}/userAccount/deleteall`;
  try {
    const res = await customFetch(url, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        // Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify(payload),
    });
    const updatedData = await res.json();
    return {
      status: res?.status,
      data: updatedData,
    };
  } catch (error) {
    console.error("Error removing all data:", error.message);
  }
};

export const switchAccount = async (payload) => {
  const url = `${PADLOCK_URL}/userAccount/switch`;
  try {
    const res = await customFetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify(payload),
    });
    const updatedData = await res.json();
    return {
      status: res?.status,
      data: updatedData,
    };
  } catch (error) {
    console.error("Error switching data:", error.message);
  }
};

export const getFeatureStatus = async (payload) => {
  const url = `${PADLOCK_URL}/padlockAdmin/get_feature_status`;
  try {
    const res = await customFetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify(payload),
    });

    const updatedData = await res.json();
    return {
      status: res?.status,
      data: updatedData,
    };
  } catch (error) {
    console.error("Error updating data:", error.message);
  }
};

export const getUserRoleStatus = async () => {
  const url = `${PADLOCK_URL}/padlockAdmin/is-superuser`;
  try {
    const res = await customFetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        // Authorization: `Bearer ${accessToken}`,
      },
    });

    const updatedData = await res.json();

    return {
      status: res?.status,
      data: updatedData,
    };
  } catch (error) {
    console.error("Error updating data:", error.message);
  }
};

export const postContactRequest = async (payload) => {
  const url = `${PADLOCK_URL}/padlockAdmin/contact`;
  try {
    const res = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    });
    const updatedData = await res.json();
    return {
      status: res?.status,
      data: updatedData,
    };
  } catch (error) {
    console.error("Error while sending contact request:", error.message);
  }
};

export const getOranizationIcon = async () => {
  const url = `${PADLOCK_URL}/padlockAdmin/orgIcon`;
  try {
    const res = await customFetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        // Authorization: `Bearer ${accessToken}`,
      },
    });

    const updatedData = await res.json();

    return {
      status: res?.status,
      data: updatedData,
    };
  } catch (error) {
    console.error("Error getting org data:", error.message);
  }
};

export const validateOrganisation = async (payload) => {
  const url = `${PADLOCK_URL}/padlockAdmin/org`;
  try {
    const res = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    });
    const updatedData = await res.json();
    return {
      status: res?.status,
      data: updatedData,
    };
  } catch (error) {
    console.error("Error validating org data:", error.message);
  }
};
